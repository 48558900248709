import { useState, useEffect, useMemo, useContext } from "react"
import useSettings from "../../hooks/Settings"
import QuestionaireField from '../../components/open/QuestionaireField'
import useTranslations from "../../hooks/useTranslations";
import { db, timestamp } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import { Responses } from "../../StateManagment/Responses";
import { useFirestoreGeneralOrderBy, useFirestoreGeneral, useFirestoreID } from "../../firebase/useFirestore";
import Location from "../../hooks/Location";
import { client } from "../../hooks/Client";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const QuestionnaireOpen = () => {
    // Context
    const [responses, setResponses] = useContext(Responses);

    // State
    const [fieldIndex, setFieldIndex] = useState(0)
    const [saved, setSaved] = useState(false);

    // Get url params
    const questionnaireId = Location()[3]
    const moment = Location()[4];
    const research = Location()[5];
    const persona = Location()[6];
    const test = Location()[7];
    const languageCode = Location()[8]

    // Firestore
    const questionaires = useFirestoreID('Questionnaires', questionnaireId ? questionnaireId : '0')
    const researches = useFirestoreGeneral('Research', 'ID', research ? research : '');
    const fields = useFirestoreGeneralOrderBy(
      'QuestionnaireFields',
      'QuestionnaireID',
      questionnaireId,
      'Position',
      'asc'
  );

    // Hooks
    const logo = useSettings().logo
    const companyName = useSettings().compagnyName

    // Translations
    const stringsToTranslate = useMemo(() => {
        if (!questionaires) return {};
    
        return {
          vragenlijst: "Vragenlijst",
          "Einde van de vragenlijst": "Dit is het einde van de vragenlijst",
          "Previewversie": "Dit is een previewversie van de vragenlijst. De antwoorden kunnen niet worden opgeslagen.",
          "Klik om op te slaan": "Klik op verzenden om de vragenlijst op te slaan",
          "Terug": "Terug",
          "Verzenden": "Verzenden",
          "Antwoorden opgeslagen": "Uw antwoorden zijn opgeslagen",
          "Opnieuw": "Opnieuw",
          "Kopie resultaten": "Kopie van resultaten downloaden",
          "Downloaden": "Downloaden",
          ...Object.fromEntries(
            questionaires.map((q) => [
              q.ID,
              { title: q.Title, description: q.Description },
            ])
          ),
        };
      }, [questionaires]);

      const { translations, loading } = useTranslations(stringsToTranslate, languageCode ? languageCode : 'nl');

    // SendCopyOption 
  const sendCopyOption = researches && researches.map(research => research.SendCopyOption);

   // Set total top-level questions
   const topLevelFields = fields && fields.filter(field => field.SectionID === '');

    // Save responses
    const saveResponses = () => {
      const formID = uuid();

      responses &&
          responses.map(response => {
              db.collection('QuestionnairesResponses')
                  .doc()
                  .set({
                      FieldID: response.FieldID,
                      FieldTitle: fields.find(field => field.ID === response.FieldID).Question,
                      Input: response.Input,
                      Timestamp: timestamp,
                      CompagnyID: client,
                      QuestionannaireID: questionnaireId,
                      MomentID: moment,
                      ResearchID: research,
                      FormID: formID,
                      ID: uuid(),
                      OptionType: response.OptionType ? response.OptionType : '',
                      Persona: persona ? persona : '',
                      RowTitle: response.RowTitle ? response.RowTitle : '',
                      FieldType: response.FieldType ? response.FieldType : '',
                      ColumnID: response.ColumnID ? response.ColumnID : '',
                  })
                  .then(() => {
                      setSaved(true);
                  });
          });
  };

    // Progress bar
    const progressBar = () => {

        const percentage = ((fieldIndex + 1) / topLevelFields.length) * 100

        return(
            <div id='progress-bar-container'>
                <p>{((fieldIndex + 1) <= topLevelFields.length) ? (fieldIndex + 1) : (fieldIndex)}/{topLevelFields.length}</p>
                <div id='progress-bar' style={{width: ((fieldIndex + 1) < topLevelFields.length) ? `${percentage}%` : '100%', height: '10px'}}></div>   
            </div>
        )
    }

    // Helper function to get response title
    const getResponseTitle = (id) => {
      const matchingField = fields && fields.find(field => field.ID === id);
      return matchingField ? (translations?.[matchingField.Question] || matchingField.Question) : null;
  };

    // Helper function to create responses string
    const createResponsesString = () => {
      const array = responses?.map(response => ({
          Vraag: getResponseTitle(response.FieldID),
          Antwoord: response.Input,
      })) || [];

      return array.map(item => `Vraag: ${item.Vraag}\nAntwoord: ${item.Antwoord}`).join("\n\n");
  };

  // Download results handler
    const downloadResultsHandler = (e) => {
      e.target.style.color = "lightgray";
      e.target.innerHTML = 'Downloaden...';

      const filename = `responses-${questionnaireId}-${new Date().toISOString()}.txt`;

      const blob = new Blob([createResponsesString()], { type: 'text/plain' });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(link.href);

      e.target.innerHTML = 'Gedownload';
  };

  return (
    <div className='page-container'>
        <div id='questionniare-container'>
            <img id='compagny-logo' src={logo} alt="logo" />
            <p><b>{companyName}</b></p>
            {questionaires && questionaires.map(questionaire => (
                <div key={questionaire.ID} id='questionnaire-field-outer-container'>
                    <div id='page-header-open-questionnaire'>

                        <p>{translations.vragenlijst || 'Vragenlijst'}</p>
                        <h1>{translations[questionaire.ID]?.title || questionaire.Title}</h1>
                        <p>
                        {translations[questionaire.ID]?.description || questionaire.Description}
                        </p>
                        {progressBar()}
                    </div>
                    
                    {fieldIndex + 1 > topLevelFields.length ? 
                      <div>
                        <div style={{ display: saved ? 'none' : 'block' }}>
                            <h2>{translations?.["Einde van de vragenlijst"] || "Dit is het einde van de vragenlijst"}</h2>
                            {test === 'test' ? (
                                <p>{translations?.["Previewversie"] || "Dit is een previewversie van de vragenlijst. De antwoorden kunnen niet worden opgeslagen."}</p>
                            ) : (
                                <p>{translations?.["Klik om op te slaan"] || "Klik op verzenden om de vragenlijst op te slaan"}</p>
                            )}
                            <div className="questionnaire-button-container">
                                <button onClick={() => setFieldIndex(fieldIndex - 1)}>{translations?.["Terug"] || "Terug"}</button>
                                <button
                                    onClick={saveResponses}
                                    id='questionniare-send-button'
                                    style={{ display: test === 'test' ? 'none' : 'block' }}
                                >
                                    <div id='send-button-inner-container'>
                                        <SendOutlinedIcon />
                                        <p>{translations?.["Verzenden"] || "Verzenden"}</p>
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div id='responses-send-container' style={{ display: saved ? 'flex' : 'none' }}>
                            <h2>{translations?.["Antwoorden opgeslagen"] || "Uw antwoorden zijn opgeslagen"}</h2>
                            <button onClick={() => window.location.reload()}>{translations?.["Opnieuw"] || "Opnieuw"}</button>
                            <div id='send-copy-container' style={{ display: sendCopyOption[0] === 'on' ? 'block' : 'none' }}>
                                <p>
                                    <b>{translations?.["Kopie resultaten"] || "Kopie van resultaten downloaden"}</b>
                                </p>
                                <button className="button-simple" onClick={downloadResultsHandler}>
                                    {translations?.["Downloaden"] || "Downloaden"}
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                      <QuestionaireField 
                      questionaire={questionaire} 
                      topLevelFields={topLevelFields}
                      setFieldIndex={setFieldIndex} 
                      fieldIndex={fieldIndex}
                      compagnyId={client}
                      translations={translations}
                      languageCode={languageCode}
                      />
                    } 
                </div>
            ))}
        </div>
    </div>
  )
}

export default QuestionnaireOpen