import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"
import { useFirestoreOrderBy} from "../../firebase/useFirestore";
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import Tooltip from "../../components/common/Tooltip";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect, useState } from "react";
import { PersonasSystemMessage } from "../../hooks/impactAI/Prompts"
import deleteIcon from '../../images/icons/delete-icon.png'
import DummyPhoto from '../../images/dummy-profile-photo.jpeg'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { db, timestamp } from "../../firebase/config.js"
import uuid from "react-uuid";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import DeleteModal from "../../components/common/DeleteModal";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import RouteMeta from "../../components/personas/RouteMeta";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Personas = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        startMessage: [startMessage, setStartMessage],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen]
    } = useContext(ImpactAI);

    // State
    const [dragArray, setDragArray] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [selectedType, setSelectedType] = useState('active')

    // Hooks
    const history = useHistory()
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const id = uuid()

    // Firestore
    const personas = useFirestoreOrderBy('Personas', 'Position', 'asc')
    const selectedPersonas = selectedType === 'all' ? personas : personas && personas.filter(persona => persona.Status === selectedType)

    //ImpactAI
    const pageSystemMessage = PersonasSystemMessage()
    const pageStartMessage = `Welkom bij de deelnemers, ${auth?.ForName}. Hier kun je deelnemers beheren.
    Kan ik je ergens mee helpen?`
    const pageExampleMessages = ['Wat zijn personas?']
    const pageType = 'personas'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setExampleMessages(pageExampleMessages)
    }, [auth])

     // Delete modal 
     const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename

        setDeleteDocid(docid)
        setDeleteName(deleteName)
        setDeleteModal(!deleteModal)
    }

    // Options
    const options = [
        { key: 'active', label: 'Actief' },
        { key: 'inactive', label: 'Inactief' },
        { key: 'outflow', label: 'Uitgestroomd' },
        { key: 'pre-registration', label: 'Vooraanmelding' },
        { key: 'all', label: 'Alle' }
    ];

    // Whenever Firestore data changes, update dragArray state
    useEffect(() => {
        setDragArray(selectedPersonas);
    }, [personas]);

    // DRAG & DROP: reorder items and update Firestore
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(dragArray);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);

        // Update local state to reflect the reorder immediately
        setDragArray(items);

        // Now update Firestore with new positions
        items.forEach((item, idx) => {
        db.collection('Personas')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });

        // Show "saved" icon
        setSaved('flex');
    };

    // Add Persona
    const addPersona = () => {

        db.collection('Personas')
        .doc()
        .set({
            Name: '',
            ID: id,
            Photo: DummyPhoto,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Activities: [],
            Effects: [],
            Research: [],
            Position: personas.length + 1,
            Status: 'active',
        })
    }

    // Edit Persona
    const nameHandler = (e) => {

        const docid = e.target.dataset.docid
        const value = e.target.value

        db.collection('Personas')
        .doc(docid)
        .update({
            Name: value
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Status Handler
    const statusHandler = (e) => {

        const docid = e.target.dataset.docid
        const value = e.target.options[e.target.selectedIndex].value

        db.collection('Personas')
        .doc(docid)
        .update({
            Status: value
        })
        .then(() => {
            setSaved('flex')
         }
        )
    }

    // Delete Persona
    const deletePersona = () => {

        db.collection('Personas')
        .doc(deleteDocid)
        .delete()
    }

    // Months Left
    const monthsLeft = (endDate) => {

        const date = new Date(endDate)
        const currentDate = new Date()
        const months = Math.floor((date - currentDate) / 1000 / 60 / 60 / 24 / 30)

        if (months < 0) {
            return '0'
        }
        if (isNaN(months)) {
            return 'Geen einddatum ingevuld'
        }
        return months
    }

  return (
    <div className="main">
        <div className="main-container" >
        <div className='page-header'>
            <GroupsOutlinedIcon/>
            <h1>Deelnemers</h1>
        </div>
        <div className="select-activity-container select-activity-container-wizard">
            <div className="select-activity-inner-container">
                {options.map(option => (
                    <div 
                        key={option.key} 
                        className='activity-select-item-container' 
                        onClick={() => setSelectedType(option.key)} 
                        style={{ backgroundColor: selectedType === option.key ? secundairyColor : 'white' }}
                    >
                        <p 
                            onClick={() => setSelectedType(option.key)} 
                            style={{ color: selectedType === option.key ? 'white' : '#616161' }}
                        >
                            {option.label}
                        </p>
                    </div>
                ))}
            </div>
        </div>
        <div className='table-container dashboard-container'>
            <table>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: secundairyColor }}></th>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        <th style={{backgroundColor: secundairyColor}}>
                            <div className="th-title-container">
                                <p>STATUS</p>
                                <UnfoldMoreOutlinedIcon/>
                            </div>
                        </th>
                        <th style={{backgroundColor: secundairyColor}}>TRAJECT</th>
                        <th style={{backgroundColor: secundairyColor}}>
                            <div className="th-title-container">
                                <p>MAANDEN TOT EIND</p>
                                <UnfoldMoreOutlinedIcon/>
                            </div>
                        </th>
                        <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                </thead>
                <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="personas" direction="vertical">
                    {(provided, snapshot) => (
                    <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                {selectedPersonas && selectedPersonas.map((item, index) => (
                     <Draggable
                     key={item.ID}
                     draggableId={item.ID}
                     index={index}
                   >
                     {(provided, snapshot) => (
                       <tr
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                       >
                         <td>
                             <DragIndicatorOutlinedIcon />
                         </td>
                            <td>
                            <Tooltip content={item.Name} width='100%' top='-60px'>
                                <input type="text" defaultValue={item.Name} data-docid={item.docid} placeholder='Noteer hier de naam van de persona' onChange={nameHandler}/>
                            </Tooltip>
                            </td>
                            <td>
                                <select name="" id="" data-docid={item.docid} value={item.Status} onChange={statusHandler}>
                                    <option value="">-- Selecteer een status --</option>
                                    <option value="pre-registration">Vooraanmelding</option>
                                    <option value="active">Actief</option>
                                    <option value="inactive">Inactief</option>
                                    <option value="outflow">Uitgestroomd</option>
                                </select>
                            </td>
                            <td><RouteMeta routeId={item.RouteID}/></td>
                            <td>
                                {monthsLeft(item.EndDate)}
                            </td>
                            <td>
                                <EditOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/personabuilder/${item.ID}`)}/>
                            </td>
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid} data-deletename={item.Name} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                            </td>
                        </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
            <AddItemTableRow toolTipContent={'Persona toevoegen'} onClick={addPersona}/>
            <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deletePersona} deleteName={deleteName} />
        </div>
    </div>
</div>
  )
}

export default Personas