import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import firebase from 'firebase/app';
import MatrixDevelopmentBarChart from '../Visualisations/development/MatrixDevelopmentBarChart';
import MatrixDevelopmentLineChart from '../Visualisations/development/MatrixDevelopmentLineChart';
import MatrixDevelopmentPieChart from '../Visualisations/development/MatrixDevelopmentPieChart';
import MatrixMomentBarChart from '../Visualisations/moment/MatrixMomentBarChart';
import MatrixMomentLineChart from '../Visualisations/moment/MatrixMomentLineChart';
import MatrixMomentPieChart from '../Visualisations/moment/MatrixMomentPieChart';
import useSettings from '../../hooks/Settings';

const MatrixData = ({effectId, field, researchId, startDate, endDate, graphType, personaId, matrixQuestionRows, projectId}) => {

  // State
    const [data, setData] = useState([]);
    const [lowerScale, setLowerScale] = useState(0);
    const [upperScale, setUpperScale] = useState(5);
    const [totalResponses, setTotalResponses] = useState(0);

    // Hooks
  const portfolio = useSettings().portfolio

  // Default start and end date if dates are undefined
  const today = new Date();
  today.setHours(23, 59, 59, 999)
  const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2000, 1, 1));
  const defaultEndDate = firebase.firestore.Timestamp.fromDate(today);

  // Function to fetch portfolio responses from Firestore
  const getPortfolioResponses = async () => {

    if(portfolio !== 'block') {
      return [];
    }

    const portfolioResponses = [];

     // 1. Get the synchronisations docs
     const syncsQuerySnapshot = await db
     .collection("Synchronisations")
     .where("Effect", "==", effectId)
     .where("Type", "==", "Effect")
     .where("Status", "==", "paired")
     .get();
 
   // If there are no syncs, do nothing
   if (syncsQuerySnapshot.empty) {
     // Do nothing
   }

    // 2. Loop through the synchronisations
    for (const syncDoc of syncsQuerySnapshot.docs) {
      const syncData = syncDoc.data();
      const project = syncData.Project;
      const projectEffect = syncData.ProjectEffect;
      const effect = syncData.Effect;

      // 3. Get the project effects
      const projectEffectsQuerySnapshot = await db
        .collection("OutputEffects")
        .where('ID', '==', projectEffect)
        .get();

        for (const projectEffectDoc of projectEffectsQuerySnapshot.docs) {
          const projectEffectData = projectEffectDoc.data();
          const projectEffectId = projectEffectData.ID;

           // 4. Get the indicators for the project effects
            const indicatorQuerySnapshot = await db
            .collection("QuestionnaireFields")
            .where("EffectId", "array-contains", projectEffectId)
            .get();

          // 4. Loop through the indicators
          for (const indicatorDoc of indicatorQuerySnapshot.docs) {
            const indicatorData = indicatorDoc.data();
            const indicatorId = indicatorData.ID;

            // 5. Get the responses for the indicator
            const responsesQuerySnapshot = await db
              .collection("QuestionnairesResponses")
              .where("FieldID", "==", indicatorId)
              .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
              .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)
              .get();

            // 6. Loop through the responses
            for (const responseDoc of responsesQuerySnapshot.docs) {
              const response = responseDoc.data();

              const responseObject = {
                ...response,
                MomentPosition: null
              }

              // Get the positions of the measuremoments
              const measureMomentsQuerySnapshot = await db.collection('MeasureMoments')
                .where('ID', '==', response.MomentID)
                .get()

              for (const measureMomentDoc of measureMomentsQuerySnapshot.docs) {
                const measureMomentData = measureMomentDoc.data();
                const measureMomentPosition = measureMomentData.Position;

                responseObject.MomentPosition = measureMomentPosition;

              }

              portfolioResponses.push(responseObject);
            };
          }
        }
     
    }

    return portfolioResponses;
  }


  // Function to fetch scale range from Firestore
  const getScale = async () => {
     const query = db.collection('QuestionnaireFields')
      .where('ID', '==', field);

    const fieldData = await query.get();

    fieldData.docs.forEach(doc => {
      setLowerScale(doc.data().ReachStart);
      setUpperScale(doc.data().ReachEnd);
    });
  };

  // Function to fetch data from Firestore
  const getData = async () => {
    const dataArray = [];
    const rowTitlesSet = new Set(); // To store unique row titles
     
  // Start the Firestore query by selecting the 'QuestionnairesResponses' collection and adding the 'FieldID' condition.
  let querySnapshot = db.collection('QuestionnairesResponses')
  .where('FieldID', '==', field)
  .where('Timestamp', '>=', startDate ? startDate : defaultStartDate)
  .where('Timestamp', '<=', endDate ? endDate : defaultEndDate)

  // Conditionally add the 'PersonaID' filter if personaId is defined.
  if (personaId) {
    querySnapshot = querySnapshot.where('Persona', '==', personaId);
  }

  // Fetch row titles from Firebase and wait for the promise to resolve
  const rowTitlesSnapshot = await db.collection('MatrixQuestionRows')
  .where('Field', '==', field)
  .get();

  const rowTitles = rowTitlesSnapshot.docs.map((doc) => doc.data());

  // Execute the query to get the local responses.
  const localSnapshot = await querySnapshot.get();
  const localResponses = localSnapshot.docs.map(doc => doc.data());

  console.log(localResponses)

  // Get the portfolio responses if company is portfolio
    const portfolioResponses = await getPortfolioResponses()

      // 3. Combine both sets of responses
  const allResponses = [...localResponses, ...portfolioResponses];

  // 4. Extract unique row titles from all responses
  allResponses.forEach(response => {
    rowTitlesSet.add(response.RowTitle);
  });

  console.log(allResponses)

  // 5. Set the total number of responses based on the combined array
  setTotalResponses(allResponses.length);

  // Fetch the measure moments from Firebase
    const measureMoments = await db.collection('MeasureMoments')
      .where('ResearchID', '==', researchId)
      .orderBy('Position', 'asc')
      .get();

    measureMoments.docs.forEach((moment) => {
      const momentID = moment.data().ID;
      const momentTitle = moment.data().Title;
      const momentPosiiton = moment.data().Position;

      const momentResponses = allResponses.filter(response => response.MomentID === momentID);

      // Skip processing this measure moment if there are no responses
      if (momentResponses.length === 0) {
        return; // Continue to the next moment
      }

      const columnData = {};

      momentResponses.forEach(response => {
        const rowTitle = response.RowTitle; // Group by row title instead of columnID
        const input = parseInt(response.Input, 10); // Parse the input as an integer

        // Await rowTitleData to access its contents
        const rowTitleData = rowTitles.filter(
          row => row.Title === rowTitle
        );

        // Query the MSI API to find out whether the rowTitle is positive of negative
        const isNegative = rowTitleData && rowTitleData.length > 0 && rowTitleData[0].posNeg === 'negative';
      
        // Initialize if this is the first time encountering this rowTitle
        if (!columnData[rowTitle]) {
          columnData[rowTitle] = {
            sum: 0,
            count: 0,
            rowTitle: rowTitle,
            color: rowTitleData ? rowTitleData[0].Color : '#206c7f', // Add the color from rowTitleData
            negative: isNegative
          };
        }
      
        // Add the input to the sum and increment the count
        columnData[rowTitle].sum += input;
        columnData[rowTitle].count += 1;
      });

      const momentData = {
        name: momentTitle
      };

      // Ensure all row titles from rowTitlesSet are present in momentData
     // Loop through each row title in the set of unique row titles
    rowTitlesSet.forEach(rowTitle => {

      // Find the corresponding column entry for this row title in columnData
      const columnEntry = Object.values(columnData).find(entry => entry.rowTitle === rowTitle);

      // If an entry for this row title exists, calculate the average and add the color
      if (columnEntry) {
        const average = columnEntry.sum / columnEntry.count;
        momentData[rowTitle] = {
          value: average,
          color: columnEntry.color,
          negative: columnEntry.negative
        };
      } else {
        momentData[rowTitle] = {
          value: null,
          color: null, // Default to null if no data
          negative: null
        };
      }
    });

    // After processing all row titles, push the momentData object into the dataArray
    dataArray.push(momentData);
    });

    setData(dataArray);
  };

  // useEffect to trigger getData when field or researchID changes
  useEffect(() => {
    getData();
    getScale();
  }, [field, researchId, startDate, endDate, graphType, personaId, matrixQuestionRows]);

  console.log(data)

 // Render the correct graph based on the data
 const graphComponents = {
  single: {
    line: (
      <MatrixMomentLineChart
        data={data}
        lowerScale={lowerScale}
        upperScale={upperScale}
        totalResponses={totalResponses}
        projectId={projectId}
      />
    ),
    bar: (
      <MatrixMomentBarChart
        data={data}
        lowerScale={lowerScale}
        upperScale={upperScale}
        totalResponses={totalResponses}
      />
    ),
    pie: <MatrixMomentPieChart data={data} />,
  },
  multiple: {
    line: (
      <MatrixDevelopmentLineChart
        data={data}
        lowerScale={lowerScale}
        upperScale={upperScale}
        totalResponses={totalResponses}
      />
    ),
    bar: (
      <MatrixDevelopmentBarChart
        data={data}
        lowerScale={lowerScale}
        upperScale={upperScale}
        totalResponses={totalResponses}
      />
    ),
    pie: <MatrixDevelopmentPieChart 
      data={data}
      lowerScale={lowerScale}
      upperScale={upperScale} 
      totalResponses={totalResponses}
      />,
  },
};
  
  const isSingleData = data.length === 1;
  const typeOfGraph = isSingleData ? 'single' : 'multiple';

return (
    <>
        {graphComponents[typeOfGraph][graphType ? graphType : 'bar'] || null}
    </>
);
}

export default MatrixData