import Tooltip from "../../components/common/Tooltip";
import deleteIcon from '../../images/icons/delete-icon.png'
import MeetstandardIcon from '../../images/meetstandaard-icon.png'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import { db } from "../../firebase/config";
import { useState, useContext, useEffect } from "react";
import firebase from 'firebase/app'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { use } from "react";

const EffectDeleteContainer = ({effect}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [paired, setPaired] = useState(false)

    // Delete the effect
    const deleteEffect = (e) => {

        const docid = e.target.dataset.docid
        const id = e.target.dataset.id

        db.collection('OutputEffects')
        .doc(docid)
        .delete()
        .then(() => {
            removeIndicators(id)
        })
    }

     // Remove the indicators
     const removeIndicators = (id) => {
        db.collection('QuestionnaireFields')
        .where('EffectId', 'array-contains', id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                db.collection('QuestionnaireFields')
                .doc(doc.id)
                .update({
                    EffectId: firebase.firestore.FieldValue.arrayRemove(id)
                })
            })
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Check if effect is paired with a portfolio account
    const effectIsPaired = async (id) => {
            
        const querySnapshot = await db.collection('Synchronisations')
        .where('ProjectEffect', '==', id)
        .get()


        querySnapshot.size > 0 ? setPaired(true) : setPaired(false)
    }

    useEffect(() => {
        effectIsPaired(effect.ID)
    }, [effect])

  return (
    <div className="table-delete-container">
        {/* Show MSI icon if effect is standardised */}
        {effect.Standard === 'true' ? (
            <Tooltip content={'Let op: dit is een gestandaardiseerd effect'} top='-80px' left='-50px'>
                <img 
                    className='table-delete-icon' 
                    data-id={effect.ID} 
                    data-docid={effect.docid} 
                    src={MeetstandardIcon} 
                    onClick={deleteEffect} 
                    alt=""
                />
            </Tooltip>
        ) : null}
    
        {/* Show paired icon only if effect is paired AND NOT an MSI effect */}
        {paired === true && effect.Standard === undefined ? (
            <Tooltip content={'Dit effect is gekoppeld aan een portfolio account'} top='-100px' left='-50px'>
                <LinkOutlinedIcon 
                className='table-delete-icon' 
                data-id={effect.ID} 
                data-docid={effect.docid} 
                onClick={deleteEffect} 
                src={deleteIcon} 
                alt=""
                />
            </Tooltip>
        ) : null}

        {/* Show paired icon only if effect is paired AND a MSI effect */}
        {paired === true && effect.Standard === 'true' ? (
            <Tooltip content={'Dit effect is gekoppeld aan een portfolio account'} top='-100px' left='-50px'>
                <LinkOutlinedIcon 
                className='table-delete-icon' 
                data-id={effect.ID} 
                data-docid={effect.docid} 
                onClick={deleteEffect} 
                src={deleteIcon} 
                alt=""
                />
            </Tooltip>
        ) : null}
    
        {/* Show delete icon only if effect is NOT paired and NOT standardised */}
        {paired === false && effect.Standard === undefined ? (
            <Tooltip content={'Effect verwijderen'} top='-80px' left='-50px'>
                <img 
                    className='table-delete-icon' 
                    data-id={effect.ID} 
                    data-docid={effect.docid} 
                    onClick={deleteEffect} 
                    src={deleteIcon} 
                    alt=""
                />
            </Tooltip>
        ) : null}
    </div>
  )
}

export default EffectDeleteContainer