import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import useSettings from "../../hooks/Settings";
import { useFirestoreGeneral, useFirestoreOrderBy, useFirestore } from '../../firebase/useFirestore';
import sentimentAnalyse from '../../components/AI/SentimentAnalyse';
import { db, timestamp } from '../../firebase/config';
import { client } from "../../hooks/Client";
import uuid from 'react-uuid';
import Select from 'react-select';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import AIProgressAnalysis from '../../components/insights/AIProgressAnalysis';
import AIOpenQuestionAnalysis from '../../components/insights/AIOpenQuestionAnalysis';
import AIResearchAnalysis from '../../components/insights/AIResearchAnalysis';
import FilterResults from '../../components/insights/FilterResults';

const AddInsight = () => {
    // State
    const [navigation, setNavigation] = useState('sentiment');
    const [questionnaireId, setQuestionnaireId] = useState('');
    const [questionFieldId, setQuestionFieldId] = useState('');
    const [suggestion, setSuggestion] = useState([]);
    const [loading, setLoading] = useState('none');
    const [selectedResearch, setSelectedResearch] = useState([]);
    const [commonQuestions, setCommonQuestions] = useState([]);
    const [crossResearchResults, setCrossResearchResults] = useState([]);
    const [averageInput, setAverageInput] = useState(null);
    const [researchAnalysisLoading, setResearchAnalysisLoading] = useState('none');

    // Hooks
    const history = useHistory();
    const secundairyColor = useSettings().SecundairyColor;

    // Firestore
    const allResearch = useFirestoreOrderBy('Research', 'Position', 'asc');
    const insights = useFirestore('Insights')

    // Update the selectedResearch state to use the selected options in react-select
    const handleResearchSelectChange = (selectedOptions) => {
        setSelectedResearch(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

     // Format the data for react-select
     const researchOptions = allResearch && allResearch.map((research) => ({
        value: research.ID,
        label: research.Title,
    }));

    // Function to save analysis
    const saveAnalysis = (e) => {
        const type = e.target.dataset.type;


        db.collection('Insights')
            .add({
                Content: suggestion.content ? suggestion.content : '',
                AverageInput: averageInput, 
                CrossResearchResults: crossResearchResults,
                Type: type,
                Timestamp: timestamp,
                CompagnyID: client,
                QuestionnaireID: questionnaireId,
                QuestionFieldID: questionFieldId,
                ID: uuid(),
                Title: 'Inzicht ' + (insights.length + 1),
                Position: insights.length + 1,
            })
            .then(() => {
                history.push(`/${client}/insights`);
            })
            .catch(error => {
                console.error("Error adding document: ", error);
            });
    };

    // UseEffect to calculate average input when crossResearchResults change
    useEffect(() => {
        if (crossResearchResults.length > 0) {
            const firstResultType = crossResearchResults[0].type;

            if (firstResultType === 'scale') {
                const totalSum = crossResearchResults.reduce((sum, item) => sum + Number(item.input), 0);
                const average = totalSum / crossResearchResults.length;
                setAverageInput(average);
            }
        }
    }, [crossResearchResults]);

    // Function to find comparable questions in selected research
    const findComparableQuestionsInSelectedResearch = async () => {
        const questionaireId = allResearch && allResearch
            .filter(item => selectedResearch.includes(item.ID))
            .map(item => item.QuestionnaireID);

        let allQuestionsPerResearch = [];

        if (questionaireId.length > 0) {
            for (const item of questionaireId) {
                const querySnapshot = await db.collection('QuestionnaireFields')
                    .where('QuestionnaireID', '==', item)
                    .get();

                let questionsInCurrentResearch = [];
                querySnapshot.forEach(doc => {
                    const question = doc.data().Question;
                    const type = doc.data().Type;
                    const id = doc.data().ID;

                    // Collect questions with their type and id
                    questionsInCurrentResearch.push({ question, type, id });
                });

                // Add this research's questions to the array of all researches
                allQuestionsPerResearch.push(questionsInCurrentResearch);
            }
        }

        // Find common questions across all selected researches
        const commonQuestions = allQuestionsPerResearch.reduce((acc, currentQuestions) => {
            return acc.filter(q =>
                currentQuestions.some(cur =>
                    cur.question === q.question && cur.type === q.type
                )
            );
        }, allQuestionsPerResearch[0]);

        setCommonQuestions(commonQuestions);
    };

    // Function to get all responses for the selected question
    const selectedQuestionForcroosResearchComparison = (e) => {
        const questionId = e.target.options[e.target.selectedIndex].value;
        const type = e.target.options[e.target.selectedIndex].dataset.type;

        db.collection('QuestionnairesResponses')
            .where('FieldID', '==', questionId)
            .get()
            .then((querySnapshot) => {
                const newInputs = []; // Create an array to store the new inputs
                querySnapshot.forEach((doc) => {
                    newInputs.push({ input: doc.data().Input, type: type }); // Add each input to the array
                });

                // Update the state by appending new inputs to the existing array
                setCrossResearchResults(prevState => [
                    ...prevState,
                    ...newInputs
                ]);
            });
    };

    // Function to compare research results and display the average
    const crossResearchResultsAnanlyses = () => {
        if (crossResearchResults.length > 0) {
            if (crossResearchResults[0].type === 'scale') {
                return (
                    <p>Gemiddelde: {averageInput && averageInput.toFixed(1)}</p>
                );
            } else {
                return <p>No 'scale' type found.</p>;
            }
        }
        return null;
    };

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <LightbulbOutlinedIcon />
                    <h1>Inzicht toevoegen</h1>
                </div>
                <div className="select-activity-container select-activity-container-personas">
                    <div className="select-activity-inner-container">
                        <div className='activity-select-item-container' onClick={() => setNavigation('sentiment')} style={{ backgroundColor: navigation === 'sentiment' ? secundairyColor : '#83edff14' }}>
                            <p onClick={() => setNavigation('sentiment')} style={{ color: navigation === 'sentiment' ? 'white' : '#616161' }}>AI analyse</p>
                        </div>
                        <div className='activity-select-item-container' onClick={() => setNavigation('compareResearch')} style={{ backgroundColor: navigation === 'compareResearch' ? secundairyColor : '#83edff14' }}>
                            <p onClick={() => setNavigation('compareResearch')} style={{ color: navigation === 'compareResearch' ? 'white' : '#616161' }}>Vergelijk onderzoeken</p>
                        </div>
                        <div className='activity-select-item-container' onClick={() => setNavigation('specifyResults')} style={{ backgroundColor: navigation === 'specifyResults' ? secundairyColor : '#83edff14' }}>
                            <p onClick={() => setNavigation('specifyResults')} style={{ color: navigation === 'specifyResults' ? 'white' : '#616161' }}>Resultaten specificeren</p>
                        </div>
                    </div>
                </div>

                 {/*AI Progress analysis */}
                 <AIProgressAnalysis navigation={navigation}/>
                

                {/* Sentiment analysis */}
                <div className='insights-main-type-container' style={{ display: navigation === 'sentiment' ? 'block' : 'none' }}>
                    <h2>AI analyse</h2>

                     {/* Sentiment analysis open question */}
                    <AIOpenQuestionAnalysis/>

                    {/* Sentiment analysis research */}
                    <AIResearchAnalysis/>

                </div>

                {/* Compare research */}
                <div className='insights-main-type-container' style={{ display: navigation === 'compareResearch' ? 'block' : 'none' }}>
                    <h2>Onderzoeken vergelijken</h2>
                    <div className="table-container dashboard-container">
                        <p>Vergelijk scores op gedeelte vragen tussen meerdere onderzoeken.</p>
                        <p><b>Selecteer onderzoeken</b></p>
                        <Select
                            isMulti
                            options={researchOptions}
                            onChange={handleResearchSelectChange}
                            value={researchOptions && researchOptions.filter(option => selectedResearch.includes(option.value))}
                        />
                         <div className='start-insights-button-container' onClick={findComparableQuestionsInSelectedResearch}>
                            <KeyboardDoubleArrowRightOutlinedIcon style={{fill: secundairyColor ? secundairyColor : 'black'}}/>
                            <p style={{color: secundairyColor ? secundairyColor : 'black'}}>Vind vergelijkbare vragen</p>
                        </div>
                        <div>
                            <h3>Vergelijkbare vragen</h3>
                            <select name="" id="" onChange={selectedQuestionForcroosResearchComparison}>
                                <option value="">-- Selecteer vraag --</option>
                                {commonQuestions && commonQuestions.map((item, index) => (
                                    <option key={index} data-type={item.type} value={item.id}>{item.question}</option>
                                ))}
                            </select>
                        </div>
                        <h3>Resultaten</h3>
                        <div className='insights-analysis-content-container'>
                            <p>{crossResearchResultsAnanlyses()}</p>
                        </div>
                        <div>
                            <button data-type='cross-research-question-comparison' onClick={saveAnalysis}>Sla op</button>
                        </div>
                    </div>
                </div>

                {/* Specify results */}
                <div className='insights-main-type-container' style={{ display: navigation === 'specifyResults' ? 'block' : 'none' }}>
                    <h2>Resultaten specificeren</h2>
                      <FilterResults/>
                </div>

            </div>
        </div>
    );
};

export default AddInsight;