import Navigation from './navigation/Navigation';
import { BrowserRouter as Router } from "react-router-dom";
import {AuthProvider} from './StateManagment/Auth';
import { ActiveMenuProvider } from './StateManagment/ActiveMenuItem';
import { SavedProvider } from './StateManagment/SavedIcon';
import { ResponsProvider } from './StateManagment/Responses';
import { ImpactAIProvider } from './StateManagment/ImpactAI';
import { ImpactAIDataProvider } from './StateManagment/ImpactAIData';
import { OnboardingProvider } from './StateManagment/Onboarding';
import { PersonaNavigationProvider } from './StateManagment/PersonaNavigation';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {

  return (
    <div className="App">
      <Router>
        <GoogleOAuthProvider clientId="53449363588-mi9bh0o4keudidor34k8epl2pe8glaj7.apps.googleusercontent.com">
          <AuthProvider>
            <ActiveMenuProvider>
              <SavedProvider>
                <ResponsProvider>
                  <ImpactAIProvider>
                    <ImpactAIDataProvider>
                      <OnboardingProvider>
                        <PersonaNavigationProvider>
                          <Navigation/>
                        </PersonaNavigationProvider>
                      </OnboardingProvider>
                    </ImpactAIDataProvider>
                  </ImpactAIProvider>
                </ResponsProvider>
              </SavedProvider>
            </ActiveMenuProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </Router>
    </div>
  );
}

export default App;