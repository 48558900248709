import Modal from 'react-modal';
import { useFirestore} from '../../firebase/useFirestore';

const AddEffectModal = ({ setAddEffectsModal, addEffectsModal, addEffects, selectedEffects, setSelectedEffects }) => {
    // Firestore
    const effects = useFirestore('OutputEffects');
  
    // Modal settings
    Modal.setAppElement('#root');
  
    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };
  
    // Handle selection and deselection of effects
    const handleSelectChange = (e) => {
      const id = e.target.dataset.id;
      const standard = e.target.dataset.standard === 'true'; // Convert to boolean
      const effect = effects.find((effect) => effect.ID === id);
  
      if (!effect) return;
  
      const updatedEffect = { ...effect, standard };
  
      if (e.target.checked) {
        // Add effect to the selected list if not already present
        setSelectedEffects((prev) => {
          if (prev.some((item) => item.ID === id)) {
            return prev; // Avoid duplicates
          }
          return [...prev, updatedEffect];
        });
      } else {
        // Remove effect from the selected list
        setSelectedEffects((prev) => prev.filter((item) => item.ID !== id));
      }
    };
  
    return (
      <Modal
        isOpen={addEffectsModal}
        onRequestClose={() => setAddEffectsModal(false)}
        style={modalStyles}
        contentLabel="Add effects"
      >
        <div>
          <div id="delete-modal-title-container">
            <h1>Effecten synchroniseren</h1>
          </div>
          <div>
            <h2>Selecteer de effecten die u wilt synchroniseren</h2>
            {effects &&
              effects.map((effect) => (
                <div key={effect.ID} id="portfolio-syncs-add-effect-modal-effect-input-container">
                  <input
                    type="checkbox"
                    data-id={effect.ID}
                    data-standard={effect.standard}
                    onChange={handleSelectChange}
                    checked={selectedEffects.some((selected) => selected.ID === effect.ID)}
                  />
                  <label>{effect.Effect}</label>
                </div>
              ))}
          </div>
          <div id="delete-modal-button-container">
            <button id="delete-modal-delete-button" onClick={() => setAddEffectsModal(false)}>Annuleren</button>
            <button onClick={addEffects}>
              Opslaan
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  
  export default AddEffectModal;
  