import HighlightAltOutlinedIcon from '@mui/icons-material/HighlightAltOutlined';
import EffectMeta from '../../components/effects/EffectMeta';
import Location from "../../hooks/Location"
import { useFirestoreGeneralArrayContains, useFirestoreID } from '../../firebase/useFirestore';
import { useEffect, useState, useContext } from 'react';
import plusButton from '../../images/icons/plus-icon.png'
import Tooltip from '../../components/common/Tooltip';
import deleteIcon from '../../images/icons/delete-icon.png'
import useSettings from "../../hooks/Settings";
import { db } from '../../firebase/config';
import { timestamp } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import DraggableTableItems from '../../components/common/DraggableTableItems';
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import firebase from 'firebase'
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from "../../StateManagment/Auth";
import { IndicatorsSystemMessage, IndicatorsToolCallContent,IndicatorsSaveMessage } from "../../hooks/impactAI/Prompts"
import MeetstandardIcon from '../../images/meetstandaard-icon.png'
import MeetstandardEffectMeta from '../../components/meetstandaard/MeetstandaardEffectMeta';
import AddItemTableRow from '../../components/common/AddItemTableRow';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import SavedNotice from "../../components/common/SavedNotice"

const AddIndicators = () => {
    // Context
    const [auth] = useContext(Auth)
    const [saved, setSaved] = useContext(SavedIcon)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // States
    const [dragArray, setDragArray] = useState([]);
    const [showAddContainer, setShowAddContainer] = useState(false)
    const [effectTitle, setEffectTitle] = useState('')
    const [effectDocid, setEffectDocid] = useState('')

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    
    // Hooks
    const effectId = Location()[3]
    const history = useHistory()

    // Firestore
    const fields = useFirestoreGeneralArrayContains('QuestionnaireFields', 'EffectId', effectId ? effectId : '');
    const effects = useFirestoreID('OutputEffects', effectId ? effectId : '')

    console.log(fields)

    //ImpactAI
    const pageSystemMessage = IndicatorsSystemMessage(effectTitle)
    const pageToolCallContent = IndicatorsToolCallContent()
    const pageSaveMessage = IndicatorsSaveMessage()
    const pageStartMessage = `Welkom bij de indicatoren, ${auth?.ForName}. 
    Hier gaan we indicatoren toevoegen ter onderbouwing van het effect: ${effectTitle}.
    Mag ik een voorstel doen?`
    const pageCollection = 'QuestionnaireFields'
    const pageField = 'EffectId'
    const pageParentId = effectId
    const pageType = 'indicator'
    const pageDocid = effectDocid

   useEffect(() => {
       setType(pageType)
       setSystemMessage(pageSystemMessage)
       setStartMessage(pageStartMessage)
       setToolCallContent(pageToolCallContent)
       setSaveMessage(pageSaveMessage)
       setCollection(pageCollection)
       setField(pageField)
       setParentId(pageParentId)
       setExampleMessages([])
       setDocid(pageDocid)
   }, [auth])

   // Whenever Firestore data changes, update dragArray state
   useEffect(() => {
    setDragArray(fields);
    }, [fields]);

    // DRAG & DROP: reorder items and update Firestore
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(dragArray);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);

        // Update local state to reflect the reorder immediately
        setDragArray(items);

        // Now update Firestore with new positions
        items.forEach((dragArray, idx) => {
        db.collection('QuestionnaireFields')
            .doc(dragArray.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });

        // Show "saved" icon
        setSaved('flex');
    };

   // Get title and docid of effect
   useEffect(() => {
        effects && effects.map(effect => {
            setEffectTitle(effect.Effect)
            setEffectDocid(effect.docid)
        })
    }, [effects])

    // Add field to database
    const addQuestion= (e) => {

        db.collection('QuestionnaireFields')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Timestamp: timestamp,
            QuestionnaireID: '',
            Type: 'scale',
            Question: 'Vraag',
            Explainer: '',
            ReachStart: 0,
            ReachStartLable: 'Helemaal niet',
            ReachEnd: 5,
            ReachEndLabel: 'Helemaal wel',
            Multiple: [],
            SectionID: '',
            EffectId:  firebase.firestore.FieldValue.arrayUnion(effectId),
            Indicator: 'true',
            LiveReportHidden: false,
            Standard: 'false',
            Position: fields.length + 1
        })
    }

    const titleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            Question: e.target.value
        })
    }

    const deleteQuestion = (e) => {

        const docid = e.target.dataset.docid

        // Remove effectId from field
        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            EffectId: firebase.firestore.FieldValue.arrayRemove(effectId)
        })
        // If the field has no more effects, set indicator to false
        .then(() => {
            db.collection('QuestionnaireFields')
            .doc(docid)
            .get()
            .then(doc => {
                if(doc.data().EffectId.length === 0) {
                    db.collection('QuestionnaireFields')
                    .doc(docid)
                    .update({
                        Indicator: 'false'
                    })
                }
            })
        })
    }

  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <HighlightAltOutlinedIcon/>
                <h1>Indicatoren toevoegen</h1>
                <EffectMeta item={effectId}/>
            </div>
            <div className="table-container dashboard-container">
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}></th>
                            <th style={{backgroundColor: secundairyColor}}>VRAAG</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="stakeholders" direction="vertical">
                        {(provided, snapshot) => (
                        <tbody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {fields && fields.map((field, index) => (
                                <Draggable
                                key={field.ID}
                                draggableId={field.ID}
                                index={index}
                            >
                                {(provided, snapshot) => (
                                <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                <td>
                                    <DragIndicatorOutlinedIcon />
                                </td>
                                <td>
                                    {
                                        field.Standard === 'true' ?
                                            <Tooltip content={'Gestandaardiseerde indicatoren kunnen niet worden aangepast'} width='80%' right='-120px' top='-15px'>
                                                <p className='table-p tooltip-p-normal'  id='add-inicator-meetstandaard-effect-title-container'>Gestandaardiseerd effect: {<MeetstandardEffectMeta effectId={field.MSIId}/>}</p>
                                            </Tooltip>
                                        :
                                            <Tooltip content={field.Question} width='100%' top='-40px'>
                                                <input placeholder='Schrijf hier de vraag' defaultValue={field.Question} data-docid={field.docid} onChange={titleHandler}/>
                                            </Tooltip>
                                        }
                                    </td>
                                
                                    <td>
                                    {field.Standard === 'true' ? 
                                        <Tooltip content={'Let op! Gestandaardiseerde indicatoren kunnen niet worden verwijderd'} top='-100px'>
                                            <img className='table-delete-icon' src={MeetstandardIcon}/>
                                        </Tooltip>
                                        :
                                        <Tooltip content={'Indicator verwijderen'} width='80%' top='-60px'>
                                            <img className='table-delete-icon' data-docid={field.docid} src={deleteIcon} alt="eye icon" onClick={deleteQuestion} />
                                        </Tooltip>
                                    }
                                        
                                    </td>
                                    </tr>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                    </DragDropContext>
                </table>
                <AddItemTableRow toolTipContent={'Vraag toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
               
                <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
                    <div className="add-options-container-item-container" onClick={addQuestion}>
                        <img src={plusButton} alt="" />
                        <p>Nieuwe vraag toevoegen</p>
                    </div>
                    <div className="add-options-container-item-container" onClick={() => history.push(`/${client}/SelectKPIs/${effectId}`)}>
                        <img src={plusButton} alt="" />
                        <p>Bestaande vragen toevoegen</p>
                    </div>
                </div>
                <div className="impactstrategy-saved-and-nextstep-container">
                    <SavedNotice />
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddIndicators

