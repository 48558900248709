import Breadcrumb from "../../components/common/Breadcrumb";
import { useFirestoreID, useFirestoreGeneralOrderBy, useFirestoreGeneralTwoOrderBy } from "../../firebase/useFirestore"
import { useState, useEffect, useContext } from "react";
import { client } from '../../hooks/Client';
import { db, timestamp } from "../../firebase/config.js"
import deleteIcon from '../../images/icons/delete-icon.png'
import useSettings from "../../hooks/Settings";
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import plusButton from '../../images/icons/plus-icon.png'
import uuid from 'react-uuid'
import Tooltip from "../../components/common/Tooltip";
import DeleteModal from "../../components/common/DeleteModal";

const ResearchResponses = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteName, setDeleteName] = useState('')
    const [question, setQuestion] = useState('')
    const [momentID, setMoment] = useState('')
    const [questionaireId, setQuestionaireId] = useState('')
    const [allDocids, setAllDocids] = useState([])

    // Hooks
    ScrollToTop()
    const fieldID = Location()[3]
    const researchId = Location()[4]
    const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const results = useFirestoreGeneralTwoOrderBy('QuestionnairesResponses', 'FieldID', fieldID ? fieldID : '', 'MomentID', momentID ? momentID : '', 'Timestamp', 'asc' )
    const moments = useFirestoreGeneralOrderBy('MeasureMoments', 'ResearchID', researchId ? researchId : '', 'Position', 'asc')
    const fields = useFirestoreID('QuestionnaireFields', fieldID ? fieldID : '')

    // Set all docids in state
    useEffect(() => {
        results && results.forEach(result => {
            setAllDocids(prev => [...prev, result.docid])
        })
    },[results])

    // Set question in state
    useEffect(() => {
        fields && fields.forEach(field => {
            setQuestion(field.Question)
        })
    },[fields])

    // Set questionnaire id in state
    useEffect(() => {
        results && results.forEach(result => {
            setQuestionaireId(result.QuestionannaireID)
        })
    },[results])

    // Set default moment in state
    useEffect(() => {
        setMoment(moments[0]?.ID)
    },[moments])

    // Handle input from response
    const inputHandler = (e) => {
        const input = e.target.value
        const docid = e.target.dataset.docid

        db.collection('QuestionnairesResponses')
        .doc(docid)
        .update({
            Input: input
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Delete response
    const deleteResult = (e) => {
        const docid = e.target.dataset.docid

        db.collection('QuestionnairesResponses')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
            })
    }

    // Add response
    const addResult = () => {

        const docid = uuid()

        db.collection('QuestionnairesResponses')
        .doc(docid)
        .set({
        FieldID: fieldID,
        Input: '',
        Timestamp: timestamp,
        CompagnyID: client,
        QuestionannaireID: questionaireId,
        MomentID: momentID,
        ResearchID: researchId,
        ID: uuid(),
        OptionType: '',
        Position: results.length + 1,
    })
    .then(() => {
        setSaved('flex')
        })
    }

    // Delete all responses
    const deleteAllResults = () => {

        setDeleteModal(true)
        setDeleteName('alle responses op deze vraag')
        allDocids && allDocids.forEach(docid => {
            db.collection('QuestionnairesResponses')
            .doc(docid)
            .delete()
            .then(() => {
                setSaved('flex')
                })
        })
    }

  return (
    <div className="main">
        <div className="main-container">
            <div className='page-header'>
                <h1>Reponses</h1>
                <p>{question}</p>
            </div>
            <div className="select-activity-container select-activity-container-personas">
                <div className="select-activity-inner-container">
                    {moments && moments.map(moment => (
                        <div className='activity-select-item-container' onClick={() => setMoment(moment.ID)} style={{backgroundColor: momentID === moment.ID ? secundairyColor : '#83edff14'}}>
                            <p onClick={() => setMoment(moment.ID)} style={{color: momentID === moment.ID ? 'white' : '#616161'}}>{moment.Title}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="table-container dashboard-container">
                <div className='list-top-row-container'>
                    <Tooltip content='Respons toevoegen' top='30px' width={'30px'}>
                        <img src={plusButton} onClick={addResult} alt="" />
                    </Tooltip>
                    {/* <Tooltip content='Verwijder alle responses' top='30px' width={'30px'}>
                        <img src={deleteIcon} onClick={deleteAllResults} alt="" />
                    </Tooltip> */}
                </div>
                {results && results.map(result => (
                    <div key={result.ID}>
                        <p>{result.Timestamp.toDate().toLocaleDateString("nl-NL", options)}</p>

                        {/* UI depends on FieldType */}
                        {result.FieldType === 'matrix-one'?
                            <div>
                                <p>{result.RowTitle}</p>
                                <div className="results-detail-input-container">
                                    <input defaultValue={result.Input} data-docid={result.docid} onChange={inputHandler}></input>
                                    <Tooltip content='Verwijder deze response' top='30px' width={'30px'}>
                                        <img src={deleteIcon } alt=""  data-docid={result.docid} onClick={deleteResult} />
                                    </Tooltip>
                                </div>
                            </div>
                        :
                            <div className="results-detail-input-container">
                                <textarea name="" id="" cols="20" rows="0" defaultValue={result.Input} data-docid={result.docid} onChange={inputHandler}></textarea>
                                <Tooltip content='Verwijder deze response' top='30px' width={'30px'}>
                                    <img src={deleteIcon} alt=""  data-docid={result.docid} onClick={deleteResult} />
                                </Tooltip>
                            </div>
                         }
                    </div>
                ))}
            </div>
            <Breadcrumb
            section={'Meten'}
            previousPage={'Onderzoeksresultaten'}
            previousPageUrl={`ResearchResults/${researchId}`}
            nextPage={''}
            nextPageUrl={''}
            counter={2}
            totalSteps={5}
            />
            <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteAllResults} deleteName={deleteName} />
        </div>
    </div>
  )
}

export default ResearchResponses