import { useFirestoreGeneralThree } from "../../firebase/useFirestore";

const FieldResponsesCountPersonas = ({fieldID, researchID, personaID}) => {

    const results = useFirestoreGeneralThree('QuestionnairesResponses', 'FieldID', fieldID ? fieldID : '', 'ResearchID', researchID ? researchID : '', 'Persona', personaID ? personaID : '')

    console.log(results)

  return (
    <p id='field-responses-count'>({results.length})</p>
  )
}

export default FieldResponsesCountPersonas