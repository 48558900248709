import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import useSettings from "../../hooks/Settings";
import { useHistory } from "react-router-dom";
import { useFirestoreGeneral, useFirestoreOrderBy, useFirestore } from '../../firebase/useFirestore';
import { db, timestamp } from '../../firebase/config';
import { useEffect, useState } from 'react';

const FilterResults = ({saveAnalysis}) => {
    // State
    const [filteredResults, setFilteredResults] = useState([]);
    const [questionFieldId, setQuestionFieldId] = useState('');
    const [question, setQuestion] = useState('');
    const [researchId, setResearchId] = useState('');
    const [researchSuggestion, setResearchSuggestion] = useState([]);
    const [questionnaireId, setQuestionnaireId] = useState('');

    // Hooks
    const history = useHistory();
    const secundairyColor = useSettings().SecundairyColor;

    // Firestore
    const allResearch = useFirestoreOrderBy('Research', 'Position', 'asc');
    const researchQuestions = useFirestoreGeneral('QuestionnaireFields', 'QuestionnaireID', questionnaireId ? questionnaireId : '');
    const responses = useFirestoreGeneral('QuestionnairesResponses', 'FieldID', questionFieldId ? questionFieldId : '');
    const questionaireFields = useFirestoreGeneral('QuestionnaireFields', 'ID', questionFieldId ? questionFieldId : '');

    // Function to select research
    const selectResearch = (e) => {
        setQuestionnaireId(e.target.options[e.target.selectedIndex].dataset.questionnaireid);
        setResearchId(e.target.options[e.target.selectedIndex].value);
    };

    // Function to select question
    const selectQuestion = (e) => {
        const fieldId = e.target.options[e.target.selectedIndex].value;
        const question = e.target.options[e.target.selectedIndex].innerText;

        setQuestionFieldId(fieldId);
        setQuestion(question);
    };

     // Function to create filter
     const createFilter = async () => {
        const resultsArray = [];
        
        if (responses) {
            const promises = responses.map(async (response) => {
                const input = response.Input;
                const timestamp = response.Timestamp;
        
                const querySnapshot = await db.collection('QuestionnairesResponses')
                    .where('Timestamp', '==', timestamp)
                    .get();
        
                const matchingDocs = [];
        
                // Loop through the querySnapshot
                for (const doc of querySnapshot.docs) { 

                    // Check if the input is not the same as the input in the current doc
                    if (input !== doc.data().Input) {
                        const type = await getFieldTypes(doc.data().FieldID); // Await the async function
                        matchingDocs.push({ 
                            input: input, 
                            results: {
                                input: doc.data().Input,
                                resultType: type
                            },
                            inputType: questionaireFields[0].Type
                        });
                    }
                }
        
                resultsArray.push(...matchingDocs); // Flatten matchingDocs into resultsArray
            });
        
            await Promise.all(promises); // Wait for all async operations to complete
        }
    

        // Get the input and result types
        const inputType = resultsArray.length > 0 ? resultsArray[0].inputType : '';
        const resultType = resultsArray.length > 0 ? resultsArray[0].results.resultType : '';

        // Process the data based on type combination
        const processedResults = processResultsByType(resultsArray, inputType, resultType);
        setFilteredResults(processedResults);

    };

    // Data processing router - selects appropriate processor based on types
    const processResultsByType = (resultsArray, inputType, resultType) => {
        // Create a key to look up the right processor
        const processorKey = `${inputType}-${resultType}`;

        console.log('processorKey', processorKey);
        
        // Define processors for different type combinations
        const processors = {
            // Multiple choice input with scale result (existing logic)
            'multiple-one-scale': processMultipleChoiceAndScaleResults,
            'multiple-multiple-scale': processMultipleChoiceAndScaleResults,

            'scale-multiple-one': processScaleWithMultipleChoice,
            'scale-multiple-multiple': processScaleWithMultipleChoice,
            
        };
        
        // Get the appropriate processor or use default
        const processor = processors[processorKey] 
        
        return processor(resultsArray);
    };

    // Helper function to process multiple-choice and scale results
    const processMultipleChoiceAndScaleResults = (resultsArray) => {
        const inputGroups = {};
        
        resultsArray.forEach(({ input, results, inputType }) => {
            const valueToAverage = results.input || 0; 
        
            if (!inputGroups[input]) {
                inputGroups[input] = { total: 0, count: 0 };
            }
        
            inputGroups[input].total += valueToAverage;
            inputGroups[input].count += 1;
        });
        
        // Transform grouped data into the desired output format
        const filteredResults = Object.entries(inputGroups).map(([input, { total, count }]) => ({
            input,
            averageInput: count > 0 ? parseFloat((total / count).toFixed(1)) : 0,
        }));
        
        return filteredResults;
    };

    // Helper function to process scale with multiple choice results
    const processScaleWithMultipleChoice = (resultsArray) => {
        const inputGroups = {};
        
        resultsArray.forEach(({ input, results, inputType }) => {
            const valueToAverage = input || 0; 
        
            if (!inputGroups[results.input]) {
                inputGroups[results.input] = { total: 0, count: 0 };
            }
        
            inputGroups[results.input].total += valueToAverage;
            inputGroups[results.input].count += 1;
        });
        
        // Transform grouped data into the desired output format
        const filteredResults = Object.entries(inputGroups).map(([input, { total, count }]) => ({
            input,
            averageInput: count > 0 ? parseFloat((total / count).toFixed(1)) : 0,
        }));
        
        return filteredResults;
    };

     // Helper function to get field types
    const getFieldTypes = async (fieldId) => {

        const querySnapshot = await db.collection('QuestionnaireFields')
            .where('ID', '==', fieldId)
            .get();

        let type = '';

        querySnapshot.forEach((doc) => {
            type = doc.data().Type;
        });

        return type;
    };

    console.log('filteredResults', filteredResults);

  return (
    <div class="table-container dashboard-container">
        <p>Specificeer resultaten van een specifieke vraag uit een specifiek onderzoek.</p>
        <p><b>Selecteer onderzoek</b></p>
        <select name="" id="" onChange={selectResearch}>
            <option value="" >-- Selecteer onderzoek --</option>
            {allResearch && allResearch.map(research => (
                <option value={research.ID} data-questionnaireid={research.QuestionnaireID}>{research.Title}</option>
            ))}
        </select>
        <p>Selecteer vraag</p>
        <select name="" id="" onChange={selectQuestion}>
            <option value="">-- Selecteer vraag --</option>
            {researchQuestions && researchQuestions.map(q => (
                <option value={q.ID}>{q.Question}</option>
            ))}
        </select>
        <div className='start-insights-button-container' onClick={createFilter}>
            <KeyboardDoubleArrowRightOutlinedIcon style={{fill: secundairyColor ? secundairyColor : 'black'}}/>
            <p style={{color: secundairyColor ? secundairyColor : 'black'}}>Filter</p>
        </div>
        <div>
            <h3>Resultaten</h3>
            <div className='insights-analysis-content-container'>
                {filteredResults && filteredResults.map((results, index) => (
                    <div key={index} className='filtered-results-results-container'>
                            <>
                            <div>
                                <h3>{questionaireFields[0].Question}</h3>
                                <p>{results.input}</p>
                            </div>
                            <div>
                                {/* <FieldMeta item={results[0].results.FieldID}/> */}
                                <p>{results.averageInput}</p>
                            </div>
                            
                            </>
                        

                    </div>
                ))}
            </div>
        </div>
        <div>
            <button data-type='cross-research-question-comparison' onClick={saveAnalysis}>Sla op</button>
        </div>
    </div>
  )
}

export default FilterResults