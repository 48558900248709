import Breadcrumb from "../../components/common/Breadcrumb";
import { useFirestoreID, useFirestoreGeneralOrderBy, useFirestoreGeneralThreeOrderBy } from "../../firebase/useFirestore"
import { useState, useEffect, useContext } from "react";
import { client } from '../../hooks/Client';
import { db, timestamp } from "../../firebase/config.js"
import deleteIcon from '../../images/icons/delete-icon.png'
import useSettings from "../../hooks/Settings";
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import plusButton from '../../images/icons/plus-icon.png'
import uuid from 'react-uuid'
import Tooltip from "../../components/common/Tooltip";
import DeleteModal from "../../components/common/DeleteModal";

const ResearchResponsesPersonas = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [question, setQuestion] = useState('')
    const [momentID, setMoment] = useState('')
    const [questionaireId, setQuestionaireId] = useState('')
    const [allDocids, setAllDocids] = useState([])
    const [personaTitle, setPersonaTitle] = useState('')

    // Hooks
    ScrollToTop()
    const fieldID = Location()[5]
    const researchId = Location()[4]
    const personaId = Location()[3]
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const results = useFirestoreGeneralThreeOrderBy(
        'QuestionnairesResponses',
        'FieldID', fieldID ? fieldID : '',
        'MomentID', momentID ? momentID : '',
        'Persona', personaId ? personaId : '',
        'Timestamp', 'asc')
    const moments = useFirestoreGeneralOrderBy('MeasureMoments', 'ResearchID', researchId ? researchId : '', 'Position', 'asc')
    const fields = useFirestoreID('QuestionnaireFields', fieldID ? fieldID : '')
    const personas = useFirestoreID('Personas', personaId ? personaId : '')

    // Set all docids in state
    useEffect(() => {
        results && results.forEach(result => {
            setAllDocids(prev => [...prev, result.docid])
        })
    }, [results])

    // Set persona title in state
    useEffect(() => {
        personas && personas.forEach(persona => {
            setPersonaTitle(persona.Name)
        })
    }, [personas])

    // Set question in state
    useEffect(() => {
        fields && fields.forEach(field => {
            setQuestion(field.Question)
        })
    }, [fields])

    // Set questionnaire id in state
    useEffect(() => {
        results && results.forEach(result => {
            setQuestionaireId(result.QuestionannaireID)
        })
    }, [results])

    // Set default moment in state
    useEffect(() => {
        setMoment(moments[0]?.ID)
    }, [moments])

    // Handle input from response
    const inputHandler = (e) => {
        const input = e.target.value
        const docid = e.target.dataset.docid

        db.collection('QuestionnairesResponses')
            .doc(docid)
            .update({
                Input: input
            })
            .then(() => {
                setSaved('flex')
            })
    }

    // Delete response
    const deleteResult = (e) => {
        const docid = e.target.dataset.docid

        db.collection('QuestionnairesResponses')
            .doc(docid)
            .delete()
            .then(() => {
                setSaved('flex')
            })
    }

    // Add response
    const addResult = () => {

        const docid = uuid()

        db.collection('QuestionnairesResponses')
            .doc(docid)
            .set({
                FieldID: fieldID,
                Input: '',
                Timestamp: timestamp,
                CompagnyID: client,
                QuestionannaireID: questionaireId,
                MomentID: momentID,
                ResearchID: researchId,
                ID: uuid(),
                OptionType: '',
                Position: results.length + 1,
            })
            .then(() => {
                setSaved('flex')
            })
    }

    // Delete all responses
    const deleteAllResults = () => {

        setDeleteModal(true)
        setDeleteName('alle responses op deze vraag')
        allDocids && allDocids.forEach(docid => {
            db.collection('QuestionnairesResponses')
                .doc(docid)
                .delete()
                .then(() => {
                    setSaved('flex')
                })
        })
    }

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>Reponses</h1>
                    <h2>{personaTitle}</h2>
                    <p>{question}</p>
                </div>
                <div className="select-activity-container select-activity-container-personas">
                    <div className="select-activity-inner-container">
                        {moments && moments.map(moment => (
                            <div className='activity-select-item-container' onClick={() => setMoment(moment.ID)} style={{ backgroundColor: momentID === moment.ID ? secundairyColor : '#83edff14' }}>
                                <p onClick={() => setMoment(moment.ID)} style={{ color: momentID === moment.ID ? 'white' : '#616161' }}>{moment.Title}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="table-container dashboard-container">
                    <div className='list-top-row-container'>
                        <Tooltip content='Respons toevoegen' top='30px'>
                            <img src={plusButton} onClick={addResult} alt="" />
                        </Tooltip>
                        <Tooltip content='Verwijder alle responses' top='30px'>
                            <img src={deleteIcon} onClick={deleteAllResults} alt="" />
                        </Tooltip>
                    </div>
                    {results && results.map(result => (
                        <div key={result.ID}>
                            <p>{result.Timestamp.toDate().toLocaleDateString("nl-NL", options)}</p>
                            <div className="results-detail-input-container">
                                <textarea name="" id="" cols="20" rows="0" defaultValue={result.Input} data-docid={result.docid} onChange={inputHandler}></textarea>
                                {/* <input type="text" defaultValue={result.Input} data-docid={result.docid} onChange={inputHandler}/> */}
                                <img src={deleteIcon} alt="" data-docid={result.docid} onClick={deleteResult} />
                            </div>
                        </div>
                    ))}
                </div>
                <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteAllResults} deleteName={deleteName} />
            </div>
        </div>
    )
}

export default ResearchResponsesPersonas