import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { CommunicationSystemMessage } from "../../hooks/impactAI/Prompts"
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"
import { ActiveMenu } from "../../StateManagment/ActiveMenuItem";

const Financing = () => {
  // Context
  const [auth] = useContext(Auth)
  const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu);
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen]
  } = useContext(ImpactAI);

  // Hooks
  const history = useHistory()

  //ImpactAI
  const pageSystemMessage = CommunicationSystemMessage()
  const pageStartMessage = `Welkom bij Communiceren, ${auth?.ForName}. Hier kun je gerealiseerde en onderbouwde impact communiceren via live-rapportages.
  Kan ik je ergens mee helpen?`
  const pageExampleMessages = ['Wat zijn live-rapportages?']
  const pageType = 'communication'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])

 const segmentSteps = [
  {
    name: 'Mijn financiers',
    url: 'financiers',
  },
  {
    name: 'Fondsenwerving',
    url: 'funds',
  }
]


  return (
    <div className="main">
      <div className="main-container">
          <div className="page-header">
              <HandshakeOutlinedIcon/>
              <h1>Financiering</h1>
          </div>
          <div className='section-explainer-text-container'>
            
            <div>
              <p>Welkom bij Financiering, {auth?.ForName}. Hier ga je je impact vertalen naar financiering.</p>
            </div>
            <div id='segment-overview-steps-container'>
              {segmentSteps.map((step, index) => (
                <div 
                key={index} 
                className="segment-overview-step-container"
                onClick={() => {
                  history.push(`/${client}/${step.url}`);
                  setActive('financing');
                  setActiveSubItem(step.url)
                }}
                >
                  <div className="segment-overview-step-inner-container">
                    <div className="segment-overview-checked-name-container">
                      <div>
                        <p><b>{step.name}</b></p>
                      </div>
                    </div>
                    <p id='segment-overview-step-button' onClick={() => history.push(`/${client}/${step.url}`)}>Bekijk/aanpassen</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Breadcrumb
          section={'Financieren'}
          previousPage={'Home'}
          previousPageUrl={'home'}
          nextPage={'Fondsen'}
          nextPageUrl={'livereports'}
          counter={0}
          totalSteps={1}
          />
        </div>
  </div>
  )
}

export default Financing