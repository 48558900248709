import { useState, useContext, useEffect } from "react"
import ResponsesCount from "../../components/Research/ResponsesCount";
import { db } from "../../firebase/config.js"
import deleteIcon from '../../images/icons/delete-icon.png'
import sendIcon from '../../images/icons/send-icon.png'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client"
import uploadIcon from '../../images/icons/upload-icon.png'
import Tooltip from "../../components/common/Tooltip";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import useSettings from "../../hooks/Settings";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

const MeasureMoment = ({research, measureMoments}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [dragArray, setDragArray] = useState([]);

    // Hooks
    const history = useHistory()
    const secundairyColor = useSettings().SecundairyColor

    // Whenever Firestore data changes, update dragArray state
    useEffect(() => {
        setDragArray(measureMoments);
    }, [measureMoments]);

    // DRAG & DROP: reorder items and update Firestore
    const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArray);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArray(items);

    // Now update Firestore with new positions
    items.forEach((dragArray, idx) => {
        db.collection('MeasureMoments')
        .doc(dragArray.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

    // Show "saved" icon
        setSaved('flex');
    };

    // Edit title
    const titleHandler = (e) => {

        const docid = e.target.dataset.docid
        const title = e.target.value

        db.collection('MeasureMoments')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Change moment date
    const changeMomentDateHandler = (e) => {
        const date = e.target.value
        const docid = e.target.dataset.docid

        db.collection('MeasureMoments')
        .doc(docid)
        .update({
            Moment: date
        })
        .then(() => {
            setSaved('flex')
         })

    }

    // Delete moment
    const deleteMoment = (e) => {
        const docid = e.target.dataset.docid

        db.collection('MeasureMoments')
        .doc(docid)
        .delete()

    }

    return(
        <div className='table-container'>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}></th>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        {/* <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                        <th style={{backgroundColor: secundairyColor}}>AANTAL REACTIES</th> */}
                        {/* <th style={{backgroundColor: secundairyColor}}>REACTIES UPLOADEN</th> */}
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                </thead>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="moments" direction="vertical">
                        {(provided, snapshot) => (
                        <tbody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        > 
                {measureMoments && measureMoments.map((moment, index) => (
                   <Draggable
                   key={moment.ID}
                   draggableId={moment.ID}
                   index={index}
               >
                   {(provided, snapshot) => (
                   <tr
                       ref={provided.innerRef}
                       {...provided.draggableProps}
                       {...provided.dragHandleProps}
                   >
                        <td>
                            <DragIndicatorOutlinedIcon />
                        </td>
                        <td>
                            <Tooltip content={moment.Title} width='80%' top='-35px'>
                                <input type="text" placeholder="Schrijf hier de titel" data-docid={moment.docid} defaultValue={moment.Title} onChange={titleHandler}/>
                            </Tooltip>
                        </td>
                            {/* <td>
                                <input type="date" defaultValue={moment.Moment} data-docid={moment.docid} onChange={changeMomentDateHandler} />
                            </td>
                            <td>
                                <ResponsesCount moment={moment.ID}/>
                            </td> */}
                            {/* <td>
                                <img className='table-delete-icon' src={uploadIcon} alt="" onClick={() => history.push(`/${client}/uploadresponses/${moment.QuestionnaireID}/${moment.ID}/${research.ID}`)}/>
                            </td> */}
                            <td>
                                <img className='table-delete-icon' data-docid={moment.docid} onClick={deleteMoment} src={deleteIcon} alt="" />
                            </td>
                            </tr>
                            )}
                        </Draggable>
                        ))}
                        {provided.placeholder}
                    </tbody>
                )}
            </Droppable>
            </DragDropContext>
        </table>
        </div>
    )
}

export default MeasureMoment