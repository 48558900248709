import { useState } from 'react'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Tooltip from './Tooltip';

const CopyLink = ({link}) => {
    const [copied, setCopied] = useState(false)

    const host = window.location.hostname

    const copyToClipboard = () => {

        if(host === 'localhost'){
            navigator.clipboard.writeText(`${host}:3000${link}`)
        } else {
            navigator.clipboard.writeText(`https://${host}/${link}`)
        }

        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }  

  return (
    <div id='copy-link-container'>
        <Tooltip content='Kopieer link' top='-60px'>
            <ContentCopyOutlinedIcon className='icon-margin-left' onClick={copyToClipboard} />
        </Tooltip>
        {copied && <p className='copied'>Gekopieerd!</p>}
    </div>
  )
}

export default CopyLink