import { useState, useEffect } from 'react';
import ImageIcon from '@mui/icons-material/InsertPhotoOutlined';
import ParagraphIcon from '@mui/icons-material/NotesOutlined';
import TitleIcon from '@mui/icons-material/TitleOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db, timestamp } from "../../firebase/config";
import uuid from 'react-uuid';
import { client } from "../../hooks/Client";
import { useFirestoreGeneralOrderBy, useFirestoreOrderBy } from '../../firebase/useFirestore';
import Location from "../../hooks/Location";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ReportLayoutElements from '../../components/liveReport/reportBuilder2/ReportLayoutElements';
import ColorPickerHousestyle from '../../components/common/ColorPickerHousestyle';
import DeleteIcon from '../../images/icons/delete-icon.png';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import AirlineStopsOutlinedIcon from '@mui/icons-material/AirlineStopsOutlined';
import PlusOneOutlinedIcon from '@mui/icons-material/PlusOneOutlined';
import RowingOutlinedIcon from '@mui/icons-material/RowingOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

const LiverReports2 = () => {
  // State
  const [sidebarMenuItem, setSidebarMenuItem] = useState('report');
  const [layoutDraggable, setLayoutDraggable] = useState(true);
  const [selectedLayoutId, setSelectedLayoutId] = useState('');
  const [selectedLayoutDocid, setSelectedLayoutDocid] = useState('');
  const [elementDocid, setElementDocid] = useState('');
  const [elementId, setElementId] = useState('');
  const [selectedElementId, setSelectedElementId] = useState('');
  const [itemDocid, setItemDocid] = useState('');
  const [itemId, setItemId] = useState('');
  const [dragArrayLayouts, setDragArrayLayouts] = useState([]);
  const [dragArrayElements, setDragArrayElements] = useState([]);
  const [dragArrayItems, setDragArrayItems] = useState([]);
  

  // Hooks
  const reportId = Location()[3];

  // Firestore
  const reportLayouts = useFirestoreGeneralOrderBy('ReportLayouts', 'ReportID', reportId ? reportId : '', 'Position', 'asc');
  const selectedItem = useFirestoreGeneralOrderBy('ReportLayoutItems', 'ID', itemId, 'Position', 'asc');
  const selectedLayout = useFirestoreGeneralOrderBy('ReportLayouts', 'ID', selectedLayoutId, 'Position', 'asc');
  const selectedElement = useFirestoreGeneralOrderBy('ReportLayoutElements', 'ID', selectedElementId, 'Position', 'asc');
  const media = useFirestoreOrderBy('LiveReportMedia', 'Position', 'asc')
  const elements = useFirestoreGeneralOrderBy('ReportLayoutElements', 'LayoutID', selectedLayoutId, 'Position', 'asc');
  const elementItems = useFirestoreGeneralOrderBy('ReportLayoutItems', 'ElementID', selectedElementId, 'Position', 'asc');
  const qoutes = useFirestoreOrderBy('Quotes', 'Position', 'asc');
  const anecdotes = useFirestoreOrderBy('Anecdotes', 'Position', 'asc');
  const activities = useFirestoreOrderBy('Activities', 'Position', 'asc');
  const outputs = useFirestoreOrderBy('Outputs', 'Position', 'asc');
  const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc');
  const indicators = useFirestoreOrderBy('QuestionnaireFields', 'Position', 'asc');
  const research = useFirestoreOrderBy('Research', 'Position', 'asc');

  const items = [
    {
      id: '1',
      name: 'Titel',
      type: 'title',
      icon: <TitleIcon />,
      description: 'Voeg een titel toe aan je rapport',
    },
    {
      id: '2',
      name: 'Paragraaf',
      type: 'paragraph',
      icon: <ParagraphIcon />,
      description: 'Voeg een paragraaf toe aan je rapport',
    },
    {
      id: '3',
      name: 'Plaatje',
      type: 'image',
      icon: <ImageIcon />,
      description: 'Voeg een plaatje toe aan je rapport',
    },
    {
      id: '4',
      name: 'Video',
      type: 'image',
      icon: <OndemandVideoOutlinedIcon />,
      description: 'Voeg een plaatje toe aan je rapport',
    },
    {
      id: '5',
      name: 'Qoute',
      type: 'qoute',
      icon: <ChatOutlinedIcon />,
      description: 'Voeg een plaatje toe aan je rapport',
    },
    {
      id: '6',
      name: 'Anekdote',
      type: 'anekdote',
      icon: <AutoStoriesOutlinedIcon />,
      description: 'Voeg een plaatje toe aan je rapport',
    },
    {
      id: '7',
      name: 'Activiteit',
      type: 'activity',
      icon: <RowingOutlinedIcon />,
      description: 'Voeg een activiteit toe aan je rapport',
    },
    {
      id: '8',
      name: 'Output',
      type: 'output',
      icon: <PlusOneOutlinedIcon />,
      description: 'Voeg een output toe aan je rapport',
    },
    {
      id: '9',
      name: 'Effect',
      type: 'effect',
      icon: <AirlineStopsOutlinedIcon />,
      description: 'Voeg een effect toe aan je rapport',
    },
    {
      id: '10',
      name: 'Indicator',
      type: 'indicator',
      icon: <QuestionMarkOutlinedIcon />,
      description: 'Voeg een vraag toe aan je rapport',
    },
    {
      id: '11',
      name: 'Onderzoek',
      type: 'research',
      icon: <BiotechOutlinedIcon />,
      description: 'Voeg een onderzoek toe aan je rapport',
    },
  ];

  const layouts = [
    {
      id: '1',
      name: 'Layout 1',
      description: 'Layout 1',
    },
    {
      id: '2',
      name: 'Layout 2',
      description: 'Layout 2',
    },
    {
      id: '3',
      name: 'Layout 3',
      description: 'Layout 3',
    }
  ];

  useEffect(() => {
    setDragArrayLayouts(reportLayouts);
    setDragArrayElements(elements);
    setDragArrayItems(elementItems);
}, [reportLayouts, elements, elementItems]);

// DRAG & DROP: layouts
const handleDragEndLayouts = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArrayLayouts);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArrayLayouts(items);

    // Now update Firestore with new positions
    items.forEach((item, idx) => {
      db.collection('ReportLayouts')
        .doc(item.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

  };

  // DRAG & DROP: elements
  const handleDragEndElements = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArrayElements);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArrayElements(items);

    // Now update Firestore with new positions
    items.forEach((item, idx) => {
      db.collection('ReportLayoutElements')
        .doc(item.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });
  };

  // DRAG & DROP: items
  const handleDragEndItems = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArrayItems);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArrayItems(items);

    // Now update Firestore with new positions
    items.forEach((item, idx) => {
      db.collection('ReportLayoutItems')
        .doc(item.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

  };

  // Item type
  const itemType = (type) => {
    switch (type) {
      case 'title':
        return 'Titel'
      case 'paragraph':
        return 'Paragraaf'
      case 'image':
        return 'Plaatje'
      case 'video':
        return 'Video'
      case 'qoute':
        return 'Qoute'
      case 'anekdote':
        return 'Anekdote'
      case 'activity':
        return 'Activiteit'
      case 'output':
        return 'Output'
      case 'effect':
        return 'Effect'
      case 'indicator':
        return 'Vraag'
      case 'research':
        return 'Onderzoek'
      default:
        return 'Element'
    }
  }

  // Add layout to report
  const addLayoutHandler = async (e) => {
    const layoutId = e.target.dataset.layoutid;
    const ID = uuid();

    await db.collection('ReportLayouts')
    .add({
      Compagny: client,
      CompagnyID: client,
      Timestamp: timestamp,
      ReportID: reportId,
      Position: reportLayouts.length + 1,
      Layout: layoutId,
      ID: ID,
      Color: '#fff'
    })
    
    // Create new layoutItems based on the layout type
    for (let i = 0; i < layoutId; i++) {
      await db.collection('ReportLayoutElements')
      .add({
        Compagny: client,
        CompagnyID: client,
        Timestamp: timestamp,
        ReportID: reportId,
        LayoutID: ID,
        Position: i + 1,
        ID: uuid(),
        Type: null,
        Color: '#fff'
      })
    }

    setSidebarMenuItem('report')
  }


  const handleDragEnd = async (result, sourceList) => {
    const { destination, source } = result;

    // If dropped outside the list, do nothing
    if (!destination) return;

    // Handle dragging from layout to report
    if (destination.droppableId === 'report' && source.droppableId === 'layouts') {
    }
    
  };

  // Layout Color Handler
  const layoutColorHandler = async (e) => {
    const docid = e.target.dataset.docid;
    const color = e.target.value;

    await db.collection('ReportLayouts')
    .doc(docid)
    .update({
      Color: color
    })
  };

  // Delete layout
  const deleteLayoutHandler = async (e) => {
    const docid = e.target.dataset.docid;
    await db.collection('ReportLayouts').doc(docid).delete()
    setSidebarMenuItem('report')

  };

  // Add element to layout
  const addElementToLayoutHandler = async (e) => {

    const layoutId = e.target.dataset.id;
    await db.collection('ReportLayoutElements')
    .add({
      Compagny: client,
      CompagnyID: client,
      Timestamp: timestamp,
      ReportID: reportId,
      LayoutID: layoutId,
      Position: selectedLayout.length + 1,
      ID: uuid(),
      Type: null
    })
  };

  // Add item to element
  const addItemToElement = async () => {
    await db.collection('ReportLayoutItems')
    .add({
      Compagny: client,
      CompagnyID: client,
      Timestamp: timestamp,
      ReportID: reportId,
      LayoutID: selectedLayoutId,
      ElementID: selectedElementId,
      Position: selectedItem.length + 1,
      ID: uuid(),
      Type: '',
      Color: '#000000'
    })
  }

  // Add element to layout
  const addItemHandler = async (e) => {
    const type = e.target.dataset.type;

    console.log(selectedElementId)

    await db.collection('ReportLayoutItems')
    .add({
      Compagny: client,
      CompagnyID: client,
      Timestamp: timestamp,
      ReportID: reportId,
      LayoutID: selectedLayoutId,
      ElementID: selectedElementId,
      Position: selectedItem.length + 1,
      ID: uuid(),
      Type: type,
      Color: '#000000',
      BorderRadius: 0,
      Size: 100,
      QouteSize: 'normal',
      GraphType: 'line'
    })
  };

  // Element Color Handler
  const elementColorHandler = async (e) => {
    await db.collection('ReportLayoutElements')
    .doc(elementDocid)
    .update({
      Color: e.target.value
    })
  }

  // Title Handler
  const titleHandler = async (e) => {
    await db.collection('ReportLayoutItems')
    .doc(itemDocid)
    .update({
      Type: 'title',
      Title: e.target.value,
      Size: 'normal',
      Position: 'center'
    })
  }

  // Title Color Handler
  const titleColorHandler = async (e) => {
    await db.collection('ReportLayoutItems')
    .doc(itemDocid)
    .update({
      Color: e.target.value
    })
  }

  // Title Size Handler
  const titleSizeHandler = async (e) => {
    await db.collection('ReportLayoutItems')
    .doc(itemDocid)
    .update({
      Type: 'title',
      Size: e.target.value
    })
  }

  // Paragraph Handler
  const paragraphHandler = async (e) => {
    await db.collection('ReportLayoutItems')
    .doc(itemDocid)
    .update({
      Type: 'paragraph',
      Paragraph: e.target.value
    })
  }

  // Delete Element
  const deleteItemHandler = async () => {

    console.log(itemDocid)
    await db.collection('ReportLayoutItems')
    .doc(itemDocid)
    .delete()

    setSidebarMenuItem('report')
  }

  // Select Image Handler
  const selectImageHandler = async (e) => {
    const media = e.target.dataset.media;

    await db.collection('ReportLayoutItems')
    .doc(itemDocid)
    .update({
      Media: media
    })
  }

  // Image Border Radius Handler
  const imageBorderradiusHandler = async (e) => {
    const borderRadius = e.target.value;
    const docid = e.target.dataset.docid;

    await db.collection('ReportLayoutItems')
    .doc(docid)
    .update({
      BorderRadius: borderRadius
    })
  }

  // Image Size Handler
  const imageSizeHandler = async (e) => {
    const size = e.target.value;
    const docid = e.target.dataset.docid;

    await db.collection('ReportLayoutItems')
    .doc(docid)
    .update({
      Size: size
    })
  }

  // title position handler
  const titlePositionHandler = async (e) => {
    await db.collection('ReportLayoutItems')
    .doc(itemDocid)
    .update({
      Position: e.target.value
    })
  }

  // Paragraph Color Handler
  const paragraphColorHandler = async (e) => {
    await db.collection('ReportLayoutItems')
    .doc(itemDocid)
    .update({
      Color: e.target.value
    })
  }

  // Delete Element
  const deleteElementHandler = async (e) => {
    const docid = e.target.dataset.docid;

    await db.collection('ReportLayoutElements')
    .doc(docid)
    .delete()

    setSidebarMenuItem('report')
  }

  // Qoute Handler
  const qouteHandler = async (e) => {
    const docid = e.target.dataset.docid;
    const qoute = e.target.dataset.qoute;
    const name = e.target.dataset.name;
    await db.collection('ReportLayoutItems')
    .doc(docid)
    .update({
      Qoute: qoute,
      Name: name
    })
  }

  // Qoute Color Handler
  const qouteColorHandler = async (e) => {
    const docid = e.target.dataset.docid;
    await db.collection('ReportLayoutItems')
    .doc(docid)
    .update({
      Color: e.target.value
    })
  }

  // Anecdote Handler
  const anecdoteHandler = async (e) => {
    const docid = e.target.dataset.docid;
    const anecdote = e.target.options[e.target.selectedIndex].value;
    await db.collection('ReportLayoutItems')
    .doc(docid)
    .update({
      Anecdote: anecdote
    })
  }

  // Activity Handler
  const activityHandler = async (e) => {
    const docid = e.target.dataset.docid;
    const activity = e.target.value;
    await db.collection('ReportLayoutItems')
    .doc(docid)
    .update({
      Activity: activity
    })
  }

  // Activity Graph Type Handler
  const activityGraphTypeHandler = async (e) => {
    const docid = e.target.dataset.docid;
    await db.collection('ReportLayoutItems')
    .doc(docid)
    .update({
      GraphType: e.target.value
    })
  }

  // Effect Handler
  const effectHandler = async (e) => {
    const docid = e.target.dataset.docid;
    const effect = e.target.value;
    await db.collection('ReportLayoutItems')
    .doc(docid)
    .update({
      Effect: effect
    })
  }

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <h1>Live rapportage bouwer</h1>
        </div>
        <div id="report-builder-main-container">

            <div id="report-side-bar-container" >
              <div id='report-side-sticky-container'>
              {sidebarMenuItem === 'report' && (
                <div>
                  <h2>Rapportage</h2>
                  <h3>Elementen ordenen</h3>
                  <DragDropContext onDragEnd={handleDragEndLayouts}>
                            <Droppable droppableId="layouts" direction="vertical">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} >
                                      {reportLayouts && reportLayouts.map((layout, index) => (
                                         <Draggable
                                         key={layout.ID} // Ensure consistency in key naming
                                         draggableId={layout.ID} // Must be a string
                                         index={index}
                                     >
                                         {(provided, snapshot) => (
                                             <div
                                                 ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}
                                             >
                                              <div 
                                              className="sidebar-report-element-container"
                                              style={{backgroundColor: layout.Color}} 
                                              >
                                                <DragIndicatorOutlinedIcon />
                                                <div className="element-name" >{layout.Layout}</div>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                       ))}
                                       {provided.placeholder} {/* Required for spacing */}
                                   </div>
                               )}
                           </Droppable>
                       </DragDropContext>
                       <h3>Voeg element toe</h3>
                        <AddCircleOutlineOutlinedIcon onClick={() => setSidebarMenuItem('layout')}/>
                </div>
              )}

              {sidebarMenuItem === 'items' && (
                <div id="elements-side-bar-container">
                  <div>
                    <h2>Element aanpassen</h2>
                   <h3>Items sorteren</h3>
                   <DragDropContext onDragEnd={handleDragEndItems}>
                            <Droppable droppableId="items" direction="vertical">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} >
                                      {elementItems && elementItems.map((item, index) => (
                                        <Draggable
                                        key={item.ID} // Ensure consistency in key naming
                                        draggableId={item.ID} // Must be a string
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                              <div className='sidebar-element-items-overview-container'>
                                                <DragIndicatorOutlinedIcon />
                                                <p>{itemType(item.Type)}</p>
                                              </div>
                                            </div>
                                        )}
                                        </Draggable>
                                        ))}
                                        {provided.placeholder} {/* Required for spacing */}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                  </div>
                  <div>
                    {selectedElement && selectedElement.map((element, index) => (
                      <div key={element.id}>
                        <h3>Kleur</h3>
                        <p>Huisstijl</p>
                        <ColorPickerHousestyle item={element} data-docid={element.docid} onChange={elementColorHandler} />
                        <p>Colorpicker</p>
                        <input type="color" id='live-reports-color-picker' data-docid={element.docid} onChange={elementColorHandler}/>
                        <h3>Voeg item toe</h3>
                        <div onClick={() => setSidebarMenuItem('addItems')}>
                          <AddCircleOutlineOutlinedIcon/>
                        </div>
                        <h3>Layout verwijderen</h3>
                        <div className='sidebar-delete-container' data-docid={element.docid} onClick={deleteElementHandler}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      </div>
                    ))}
                    
                  </div>
                </div>
              )}

                {sidebarMenuItem === 'addItems' && (
                  <div id="elements-side-bar-container">
                    <h2>Selecteer item</h2>
                    <div id='sidebar-items-container'>
                      {items.map((item, index) => (
                        <div key={item.id} className="element-container" data-type={item.type} onClick={addItemHandler}>
                          <div className="element-icon" data-type={item.type}>{item.icon}</div>
                          <div className="element-name" data-type={item.type}>{item.name}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {sidebarMenuItem === 'layout' && (
                  <div id="elements-side-bar-container">
                    <h2>Selecteer layout</h2>
                    {layouts && layouts.map((layout, index) =>
                      <div key={layout.id} className="element-container" data-layoutid={layout.id} onClick={addLayoutHandler} >
                        <div className="element-name" data-layoutid={layout.id}>{layout.name}</div>
                      </div>
                    )}
                  </div>
                )}

                {sidebarMenuItem === 'layoutItem' && (
                  <div>
                    <div id="elements-side-bar-container">
                      <h2>Layout aanpassen</h2>
                      <div>
                        <h3>Elementen</h3>
                        <DragDropContext onDragEnd={handleDragEndElements}>
                            <Droppable droppableId="elements" direction="horizontal">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} className="sidebar-layout-elements-overview-container">
                                        {elements && elements.map((element, index) => (
                                            <Draggable
                                                key={element.ID} // Ensure consistency in key naming
                                                draggableId={element.ID} // Must be a string
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div 
                                                            className="sidebar-layout-elements-overview-item-container"
                                                            data-elementid={element.ID} 
                                                            onClick={() => setSelectedElementId(element.ID)}
                                                            style={{backgroundColor: element.Color}}
                                                        >
                                                            <div className="element-name" data-elementid={element.ID}>
                                                              <DragIndicatorOutlinedIcon />
                                                              {element.Position}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder} {/* Required for spacing */}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                      </div>
                      {selectedLayout && selectedLayout.map((layout, index) =>
                        <div key={layout.id} >
                          <h3>Achtergrondkleur</h3>
                          <p>Huisstijl</p>
                          <ColorPickerHousestyle item={layout} data-docid={layout.docid} onChange={layoutColorHandler} />
                          <p>Colorpicker</p>
                          <input type="color" id='live-reports-color-picker' data-docid={layout.docid} onChange={layoutColorHandler}/>
                          <h3>Element toevoegen</h3>
                          <div data-id={layout.ID} onClick={addElementToLayoutHandler}>
                            <AddCircleOutlineOutlinedIcon/>
                          </div>
                          <h3>Layout verwijderen</h3>
                          <div className='sidebar-delete-container' data-docid={layout.docid} onClick={deleteLayoutHandler}>
                            <img src={DeleteIcon} alt="" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {sidebarMenuItem === 'title' && (
                  <div id="elements-side-bar-container">
                    {selectedItem && selectedItem.map((item) => (
                      <div>
                        <h2>Pas titel aan</h2>
                        <h3>Titel</h3>
                        <input type="text" placeholder='Schrijf hier de titel' value={item.Title} onChange={titleHandler} />
                        <h3>Kleur</h3>
                        <p>Huisstijl</p>
                        <ColorPickerHousestyle item={item} data-docid={item.docid} onChange={titleColorHandler} />
                        <p>Colorpicker</p>
                        <input type="color" id='live-reports-color-picker' data-docid={item.docid} onChange={titleColorHandler}/>
                        <h3>Grootte</h3>
                        <select value={item.Size} onChange={titleSizeHandler}>
                          <option value="small" >Klein</option>
                          <option value='normal'>Normaal</option>
                          <option value='big'>Groot</option>
                        </select>
                        <h3>Positie</h3>
                        <select value={item.Position} onChange={titlePositionHandler}>
                          <option value="left">Links</option>
                          <option value='center'>Midden</option>
                          <option value='right'>Rechts</option>
                        </select>
                        <h3>Titel verwijderen</h3>
                        <div className='sidebar-delete-container' data-docid={item.docid} onClick={deleteItemHandler}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {sidebarMenuItem === 'paragraph' && (
                  <div id="elements-side-bar-container">
                    {selectedItem && selectedItem.map((item) => (
                      <div id='elements-side-bar-paragraph-container'>
                        <h2>Pas paragraaf aan</h2>
                        <h3>Text</h3>
                        <textarea type="text" placeholder='Schrijf hier de paragraaf' value={item.Paragraph} onChange={paragraphHandler} />
                        <h3>Kleur</h3>
                        <p>Huisstijl</p>
                        <ColorPickerHousestyle item={item} data-docid={item.docid} onChange={paragraphColorHandler} />
                        <p>Colorpicker</p>
                        <input type="color" id='live-reports-color-picker' data-docid={item.docid} onChange={paragraphColorHandler}/>
                        <h3>Paragraaf verwijderen</h3>
                        <div className='sidebar-delete-container' data-docid={item.docid} onClick={deleteItemHandler}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      </div>
                    ))}
                  
                  </div>
                )}

                {sidebarMenuItem === 'image' && (
                  <div id="elements-side-bar-container">
                    {selectedItem && selectedItem.map((item) => (
                      <div id='elements-side-bar-paragraph-container'>
                        <h2>Pas plaatje aan</h2>
                        <h3>Plaatje</h3>
                        <div>
                          {media && media.map((m) => (
                            <div key={m.ID} className='live-report-side-bar-image-container' data-media={m.Media} onClick={selectImageHandler}>
                              <img data-media={m.Media} src={m.Media} alt="" style={{border: m.Media === item.Media ? '6px solid green' : 'none'}} />
                            </div>
                          ))}
                           <h3>Afgeronde hoeken</h3>
                           <input type="number" data-docid={item.docid} value={item.BorderRadius}  onChange={imageBorderradiusHandler}/>
                           <h3>Grootte</h3>
                           <input type="number" data-docid={item.docid} value={item.Size} onChange={imageSizeHandler} />
                        </div>
                        <h3>Plaatje verwijderen</h3>
                        <div className='sidebar-delete-container' data-docid={item.docid} onClick={deleteItemHandler}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {sidebarMenuItem === 'qoute' && (
                  <div id="elements-side-bar-container">
                    {selectedItem && selectedItem.map((item) => (
                      <div id='elements-side-bar-paragraph-container'>
                        <h2>Pas qoute aan</h2>
                        <h3>Selecteer qoute</h3>
                        {qoutes && qoutes.map((qoute) => (
                          <div 
                          key={qoute.ID} 
                          className='live-report-side-bar-qoute-container' 
                          data-qoute={qoute.Quote}
                         
                          data-docid={item.docid} 
                          onClick={qouteHandler}>
                            <p 
                            data-qoute={qoute.Quote} 
                            data-name={qoute.Name} 
                            data-docid={item.docid}
                            style={{color: item.Qoute === qoute.Quote ? 'green' : '#000000'}}  
                            >{qoute.Quote} - {qoute.Name}
                            </p>
                          </div>
                        ))}
                        <h3>Huisstijl</h3>
                        <ColorPickerHousestyle item={item} data-docid={item.docid} onChange={qouteColorHandler} />
                        <h3>Colorpicker</h3>
                        <input type="color" id='live-reports-color-picker' data-docid={item.docid} onChange={qouteColorHandler}/>
                        <h3>Qoute verwijderen</h3>
                        <div className='sidebar-delete-container' data-docid={item.docid} onClick={deleteItemHandler}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {sidebarMenuItem === 'anekdote' && (
                  <div id="elements-side-bar-container">
                    {selectedItem && selectedItem.map((item) => (
                      <div id='elements-side-bar-paragraph-container'>
                        <h2>Pas anekdote aan</h2>
                        <h3>Selecteer anekdote</h3>
                        <select name="" id="" value={item.Anecdote} onChange={anecdoteHandler} data-docid={item.docid}>
                          <option value=""> -- Selecteer anekdote --</option>
                          {anecdotes && anecdotes.map((anecdote) => (
                            <option key={anecdote.ID} value={anecdote.Anecdote}>{anecdote.Anecdote}</option>
                          ))}
                        </select>
                        <h3>Huisstijl</h3>
                        <ColorPickerHousestyle item={item} data-docid={item.docid} onChange={qouteColorHandler} />
                        <h3>Colorpicker</h3>
                        <input type="color" id='live-reports-color-picker' data-docid={item.docid} onChange={qouteColorHandler}/>
                        <h3>Qoute verwijderen</h3>
                        <div className='sidebar-delete-container' data-docid={item.docid} onClick={deleteItemHandler}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {sidebarMenuItem === 'activity' && (
                  <div id="elements-side-bar-container">
                    {selectedItem && selectedItem.map((item) => (
                      <div id='elements-side-bar-paragraph-container'>
                        <h2>Pas activiteit aan</h2>
                        <h3>Selecteer activiteit</h3>
                        <select name="" id="" value={item.Activity} data-docid={item.docid} onChange={activityHandler}>
                          <option value="">-- Selecteer activiteit --</option>
                          {activities && activities.map((activity) => (
                            <option value={activity.ID}>{activity.Activity}</option>
                          ))}
                        </select>
                        <h3>Grafiektype</h3>
                        <select name="" id="" value={item.GraphType} data-docid={item.docid} onChange={activityGraphTypeHandler}>
                          <option value="line">Lijn</option>
                          <option value="bar">Bar</option>
                        </select>
                        <h3>Activiteit verwijderen</h3>
                        <div className='sidebar-delete-container' data-docid={item.docid} onClick={deleteItemHandler}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {sidebarMenuItem === 'effect' && (
                  <div id="elements-side-bar-container">
                    {selectedItem && selectedItem.map((item) => (
                      <div id='elements-side-bar-paragraph-container'>
                        <h2>Pas effect aan</h2>
                        <h3>Selecteer effect</h3>
                        <select name="" id="" value={item.Effect} data-docid={item.docid} onChange={effectHandler}>
                          <option value="">-- Selecteer effect --</option>
                          {effects && effects.map((effect) => (
                            <option value={effect.ID}>{effect.Effect}</option>
                          ))}
                        </select>
                        <h3>Grafiektype</h3>
                        <select name="" id="" value={item.GraphType} data-docid={item.docid} onChange={activityGraphTypeHandler}>
                          <option value="line">Lijn</option>
                          <option value="bar">Bar</option>
                        </select>
                        <h3>Effect verwijderen</h3>
                        <div className='sidebar-delete-container' data-docid={item.docid} onClick={deleteItemHandler}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

          <div id="report-builder-container">
            {reportLayouts &&
              reportLayouts.map((item, index) => (
                <div id='report-layout-item-container'
                key={item.ID}
                style={{backgroundColor: item.Color}}
                >
                    <ReportLayoutElements 
                    layoutId={item.ID}
                    setSidebarMenuItem={setSidebarMenuItem} 
                    setElementDocid={setElementDocid}
                    elementDocid={elementDocid}
                    elementId={elementId}
                    setElementId={setElementId}
                    setSelectedElementId={setSelectedElementId}
                    setItemDocid={setItemDocid}
                    setItemId={setItemId}
                    itemDocid={itemDocid}
                    reportId={reportId}
                    />
                  <div id='report-layout-options-container'>
                    <div onClick={() => {setSelectedLayoutId(item.ID); setSidebarMenuItem('layoutItem'); setSelectedLayoutDocid(item.docid)}} className="edit-layout-button">
                      <EditOutlinedIcon />
                    </div>
                  </div>
                </div>     
              ))}
               <EditOutlinedIcon onClick={() => setSidebarMenuItem('report')}/>
          </div>
        </div>
      
      </div>
      
    </div>
  );
};

export default LiverReports2;

