import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import useSettings from "../../../hooks/Settings";

const ProgressChart = ({ percentage }) => {
  // Get your secondary color from settings
  const secundairyColor = useSettings().SecundairyColor;

  // Data for the chart
  const data = [
    { name: 'Afgerond', value: percentage, color: 'green' },
    { name: 'Af te ronden', value: 100 - percentage, color: 'white' },
  ];

  return (
    // ResponsiveContainer makes the chart adapt to the parent container’s size.
    // Make sure the parent has a defined height!
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          startAngle={180}
          endAngle={0}
          // Use percentages for a more responsive layout
          cx="50%"
          cy="100%" // Place the center at the bottom center
          innerRadius="30%"
          outerRadius="50%"
          fill={secundairyColor}
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {/* Legend appears at the bottom and is centered */}
        <Legend verticalAlign="bottom" align="center" iconType="circle" />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ProgressChart;
