import FieldType from './FieldType';
import useTranslations from "../../hooks/useTranslations";
import { useMemo } from "react";

const Fields = ({field, compagnyId, questionaire, languageCode}) => {

    // Translations
    const stringsToTranslate = useMemo(() => {
        if (!field) return {};
        return {
            [field.ID]: {
                Question: field.Question,
                Explainer: field.Explainer
            }
        };
    }, [field]);

    const { translations, loading } = useTranslations(stringsToTranslate, languageCode ? languageCode : 'nl');

    return (
        <div className="question-field-outer-container" key={field.ID}>
            <div className='question-container'>
                <div className='field-title-container'>
                    <h2>{translations[field.ID]?.Question || field.Question}</h2>
                </div>
                <div className='explainer-container'>
                    <p>{translations[field.ID]?.Explainer || field.Explainer}</p> 
                </div>
                <FieldType 
                field={field} 
                compagnyId={compagnyId} 
                questionaire={questionaire}
                languageCode={languageCode}
                />
            </div>
        </div>
    )
}

export default Fields