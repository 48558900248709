import { useFirestoreAllArrayContains } from '../../firebase/useFirestore';
import { client } from '../../hooks/Client';
import PageHeader from '../../components/layout/PageHeader';
import DraggableTableItems from '../../components/common/DraggableTableItems';
import { useState } from 'react';
import useSettings from '../../hooks/Settings';
import { db } from '../../firebase/config';
import TOCProgress from '../../hooks/TOCProgress';
import ProjectOutputProgress from '../../components/portfolio/ProjectOutputProgress';
import ProjectResearchProgress from '../../components/portfolio/ProjectResearchProgress';
import ProjectReportsProgress from '../../components/portfolio/ProjectReportsProgress';

const Progress = () => {
    // State
    const [selectedStatus, setSelectedStatus] = useState('all')

     // Firestore
     const projects = useFirestoreAllArrayContains('CompagnyMeta', 'Parent', client)
     const selectedProjects = projects && projects.filter(project => selectedStatus === 'all' ? project : selectedStatus === 'active' ? project.Status === 'active' : project.Status === 'inactive')

     // Hooks
     const secundairyColor = useSettings().SecundairyColor

     // Options
    const options = [
        { key: 'all', label: 'Alle' },
        { key: 'active', label: 'Actief' },
        { key: 'inactive', label: 'Inactief' },
    ];

     // Status handler
    const statusHandler = (e) => {
        const docid = e.target.getAttribute('data-docid')
        const value = e.target.options[e.target.selectedIndex].value
        db.collection('CompagnyMeta').doc(docid).update({
            Status: value
        })
    }

  return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Voortgang'}
                description={`Hier vind je de voortgang van de projecten uit jouw portfolio.`}
                AI={false}
            />
            <div className="select-activity-container select-activity-container-wizard">
                <div className="select-activity-inner-container">
                    {options.map(option => (
                        <div 
                            key={option.key} 
                            className='activity-select-item-container' 
                            onClick={() => setSelectedStatus(option.key)} 
                            style={{ backgroundColor: selectedStatus === option.key ? secundairyColor : 'white' }}
                        >
                            <p 
                                onClick={() => setSelectedStatus(option.key)} 
                                style={{ color: selectedStatus === option.key ? 'white' : '#616161' }}
                            >
                                {option.label}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        <div className="table-container dashboard-container">
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                        <th style={{backgroundColor: secundairyColor}}>STATUS</th>
                        <th style={{backgroundColor: secundairyColor}}>TOC</th>
                        <th style={{backgroundColor: secundairyColor}}>OUTPUTS</th>
                        <th style={{backgroundColor: secundairyColor}}>ONDERZOEK</th>
                        <th style={{backgroundColor: secundairyColor}}>RAPPORTAGES</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedProjects && selectedProjects.map((project, index) => (
                        <tr key={project.ID}>
                            <td>
                                <p>{project.CommunityName}</p>
                            </td>
                            <td>
                                <select name="" id="" data-docid={project.docid} value={project.Status} onChange={statusHandler}>
                                    <option value="">-- Selecteer status --</option>
                                    <option value="active">Actief</option>
                                    <option value="inactive">Inactief</option>
                                </select>
                            </td>  
                            <td>
                                {/* <p>{TOCProgress(project.CompagnyID)}</p> */}
                            </td> 
                            <td>
                                <ProjectOutputProgress projectId={project.CompagnyID} />
                            </td>
                            <td>
                                <ProjectResearchProgress projectId={project.CompagnyID} />
                            </td>
                            <td>
                                <ProjectReportsProgress projectId={project.CompagnyID} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
               
            </div>
        </div>
       
    </div>
  )
}

export default Progress