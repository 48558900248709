import React, { useEffect } from 'react'
import Tooltip from '../../components/common/Tooltip'
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { useFirestore, useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import firebase from 'firebase'
import { useContext, useRef, useState } from "react";
import useSettings from "../../hooks/Settings";
import saveFile from '../../hooks/SaveFile'
import DownloadIcon from '../../images/icons/download-icon.png'
import AddItemTableRow from '../../components/common/AddItemTableRow'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

const Data = ({personaNavigation, item, personaDocid}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [file, setFile] = useState(null);
    const [fileMeta, setFileMeta] = useState(null);
    const [showAddContainer, setShowAddContainer] = useState(false);
    const [dragArrayData, setDragArrayData] = useState([]);
    const [dragArrayNetwork, setDragArrayNetwork] = useState([]);
    const [dragArrayRisks, setDragArrayRisks] = useState([]);
    const [dragArrayDocuments, setDragArrayDocuments] = useState([]);
    const [dragArraySpecifics, setDragArraySpecifics] = useState([]);

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const personaId = Location()[3]
    const fileInputRef = useRef(null);

    // Firestore
    const routes = useFirestore('PersonaRoutes')
    const risks = useFirestoreGeneralOrderBy('PersonaRisks', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')
    const networks = useFirestoreGeneralOrderBy('PersonaNetwork', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')
    const data = useFirestoreGeneralOrderBy('PersonaData', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')
    const documents = useFirestoreGeneralOrderBy('PersonaDocuments', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')
    const specifics = useFirestoreGeneralOrderBy('PersonaSpecifics', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')

    console.log(specifics)

    // Whenever Firestore data changes, update dragArray state
    useEffect(() => {
        setDragArrayData(data);
        setDragArrayNetwork(networks);
        setDragArrayRisks(risks);
        setDragArrayDocuments(documents);
        setDragArraySpecifics(specifics);
    }, [data, networks, risks, documents, specifics]);

    // DRAG & DROP: data
  const handleDragEndData = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArrayData);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArrayData(items);

    // Now update Firestore with new positions
    items.forEach((item, idx) => {
      db.collection('PersonaData')
        .doc(item.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

    // Show "saved" icon
    setSaved('flex');
  };

  // DRAG & DROP: network
  const handleDragEndNetwork = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArrayNetwork);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArrayNetwork(items);

    // Now update Firestore with new positions
    items.forEach((item, idx) => {
      db.collection('PersonaNetwork')
        .doc(item.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

    // Show "saved" icon
    setSaved('flex');
  };

    // DRAG & DROP: risks
    const handleDragEndRisks = (result) => {

        if (!result.destination) return;

        const items = Array.from(dragArrayRisks);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);

        // Update local state to reflect the reorder immediately
        setDragArrayRisks(items);

        // Now update Firestore with new positions
        items.forEach((item, idx) => {
        db.collection('PersonaRisks')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });

        // Show "saved" icon
        setSaved('flex');
    };

    // DRAG & DROP: specifics
    const handleDragEndSpecifics = (result) => {

        if (!result.destination) return;

        const items = Array.from(dragArraySpecifics);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);

        // Update local state to reflect the reorder immediately
        setDragArraySpecifics(items);

        // Now update Firestore with new positions
        items.forEach((item, idx) => {
        db.collection('PersonaSpecifics')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });

        // Show "saved" icon
        setSaved('flex');
    };

    // DRAG & DROP: documents
    const handleDragEndDocuments = (result) => {

        if (!result.destination) return;

        const items = Array.from(dragArrayDocuments);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);

        // Update local state to reflect the reorder immediately
        setDragArrayDocuments(items);

        // Now update Firestore with new positions
        items.forEach((item, idx) => {
        db.collection('PersonaDocuments')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });

        // Show "saved" icon
        setSaved('flex');
    };

    // Functions
    const nawOptions = [
        'Voornaam',
        'Achternaam',
        'Straatnaam',
        'Huisnummer',
        'Postcode',
        'Woonplaats',
        'Telefoonnummer',
        'Email',
        'BSN',
        'Geboortedatum',
        'Geslacht',
        'Land van herkomst',
    ]

    const startDateHandler = (e) => {

        const value = e.target.value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            StartDate: value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const endDateHandler = (e) => {

        const value = e.target.value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            EndDate: value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Function to handle the hours per week
    const HoursPerWeekHandler = (e) => {

        const value = e.target.value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            HoursPerWeek: value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const addNAW = () => {

        db.collection('PersonaData')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            Title: '',
            Value: '',
            CompagnyID: client,
            Compagny: client,
            Position: data.length + 1,
            Timestamp: timestamp
        })

    }

    const addNetwork = () => {

        db.collection('PersonaNetwork')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            Name: '',
            PhoneNumber: '',
            CompagnyID: client,
            Compagny: client,
            Position: networks.length + 1,
            Timestamp: timestamp
        })

    }

    const addRisk = () => {

        db.collection('PersonaRisks')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            Risk: '',
            Description: '',
            CompagnyID: client,
            Compagny: client,
            Position: risks.length + 1,
            Timestamp: timestamp,
            Position: risks.length + 1
        })

    }

    const titleHandler = (e) => {

        const docid = e.target.dataset.docid
        const title = e.target.options[e.target.selectedIndex].value

        db.collection('PersonaData')
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const valueHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaData')
        .doc(docid)
        .update({
            Value: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteNAW = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaData')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })
    }

    const routeHandler = (e) => {

        const value = e.target.options[e.target.selectedIndex].value

        db.collection('Personas')
        .doc(personaDocid)
        .update({
            RouteID: value
        })

    }

    const networkNameHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
           Title: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const networkRelationHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
            Relation: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const networkPhoneHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
            PhoneNumber: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const networkEmailHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .update({
            Email: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const riskTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .update({
            Risk: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const riskValueHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .update({
            Description: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteNetwork = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaNetwork')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteRisk = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRisks')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    
    // Function to handle file selection
    const handleFileChange = (e) => {
        const document = e.target.files[0];
        if (document) {
            // Save file to Firebase Storage
            saveFile(e, setFile, setFileMeta)
        }
    };

    // Function to handle file save
    const handleSaveDocument = () => {
        db.collection('PersonaDocuments').add({
            ID: uuid(),
            PersonaID: personaId,
            Title: fileMeta.name,
            Type: '',
            URL: file,
            CompagnyID: client,
            Compagny: client,
            Position: documents.length + 1,
            Timestamp: timestamp
        })
        .then(() => {
            setSaved('flex')
            setShowAddContainer(false)
        })
    };

    const documentTypeHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaDocuments')
        .doc(docid)
        .update({
            Type: e.target.options[e.target.selectedIndex].value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const deleteDocument = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaDocuments')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    const documentTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaDocuments')
        .doc(docid)
        .update({
            Title: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    };

    const addData = () => {
            
            db.collection('PersonaData')
            .add({
                ID: uuid(),
                PersonaID: personaId,
                Title: '',
                Value: '',
                CompagnyID: client,
                Compagny: client,
                Position: data.length + 1,
                Timestamp: timestamp
            })
            .then(() => {
                setSaved('flex')
            })
    }

    // Function to add a specific
    const addSpecific = () => {

        db.collection('PersonaSpecifics')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            Specific: '',
            Description: '',
            CompagnyID: client,
            Compagny: client,
            Position: specifics.length + 1,
            Timestamp: timestamp
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Function to handle the specific title
    const specificTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaSpecifics')
        .doc(docid)
        .update({
            Specific: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Function to handle the specific value
    const specificValueHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaSpecifics')
        .doc(docid)
        .update({
            Description: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Function to delete a specific
    const deleteSpecific = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaSpecifics')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }


  return (
    <div style={{display: personaNavigation === 'data' ? 'block' : 'none'}}>
        {/* <h2>Gegevens</h2> */}
        <div>
            <div class="table-container dashboard-container">
                <h3>NAW</h3>
                <div className='table-container'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}></th>
                                <th style={{backgroundColor: secundairyColor}}>TITLE</th>
                                <th style={{backgroundColor: secundairyColor}}>GEGEVEN</th>
                                <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                            </tr>
                        </thead>
                        <DragDropContext onDragEnd={handleDragEndData}>
                        <Droppable droppableId="data" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            > 
                            {data && data.map((item, index) => (
                               <Draggable
                               key={item.ID}
                               draggableId={item.ID}
                               index={index}
                           >
                               {(provided, snapshot) => (
                               <tr
                                   ref={provided.innerRef}
                                   {...provided.draggableProps}
                                   {...provided.dragHandleProps}
                               >
                                     <td>
                                            <DragIndicatorOutlinedIcon />
                                        </td>
                                        <td>
                                            <select name="" id="" defaultValue={item.Title} data-docid={item.docid} onChange={titleHandler}>
                                                <option value="">-- Selecteer --</option>
                                                {nawOptions && nawOptions.map((option, i) => (
                                                    <option key={i} value={option}>{option}</option> 
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" data-docid={item.docid} defaultValue={item.Value} onChange={valueHandler} />
                                        </td>
                                        <td>
                                            <img className='table-delete-icon' data-docid={item.docid} onClick={deleteNAW} src={deleteIcon} alt="" />
                                        </td>
                                    </tr>
                                    )}
                                    </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </table>
                    <AddItemTableRow toolTipContent={'Gegeven toevoegen'} onClick={addData}/>
                </div>
            </div>

            <div class="table-container dashboard-container">
                <h3>Traject</h3>
                <p>Trajectnaam</p>
                <select name="" id="" onChange={routeHandler} value={item.RouteID}>
                    <option value="">-- Selecteer een traject --</option>
                    {routes && routes.map(route => (
                        <option key={route.ID} value={route.ID}>{route.Title}</option>
                    ))}
                </select>
                <p>Startdatum</p>
                <input type="date" defaultValue={item.StartDate} onChange={startDateHandler} />
                <p>Einddatum</p>
                <input type="date" defaultValue={item.EndDate} onChange={endDateHandler}/>
                <p>Uren per week</p>
                <input type="number" defaultValue={item.HoursPerWeek} onChange={HoursPerWeekHandler}/>
            </div>

            <div class="table-container dashboard-container">
                <h3>Netwerk</h3>
                <div className='table-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}></th>
                            <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                            <th style={{backgroundColor: secundairyColor}}>RELATIE</th>
                            <th style={{backgroundColor: secundairyColor}}>TELEFOONNUMMER</th>
                            <th style={{backgroundColor: secundairyColor}}>EMAIL</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                        <DragDropContext onDragEnd={handleDragEndNetwork}>
                        <Droppable droppableId="network" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            > 
                        {networks && networks.map((item, index) => (
                             <Draggable
                             key={item.ID}
                             draggableId={item.ID}
                             index={index}
                         >
                             {(provided, snapshot) => (
                             <tr
                                 ref={provided.innerRef}
                                 {...provided.draggableProps}
                                 {...provided.dragHandleProps}
                             >
                                <td>
                                        <DragIndicatorOutlinedIcon />
                                    </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Title} onChange={networkNameHandler}/>
                                </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Relation} onChange={networkRelationHandler}/>
                                </td>
                                <td>
                                    <input type="phone" data-docid={item.docid} defaultValue={item.PhoneNumber} onChange={networkPhoneHandler}/>
                                </td>
                                <td>
                                    <input type="email" data-docid={item.docid} defaultValue={item.Email} onChange={networkEmailHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' data-docid={item.docid} onClick={deleteNetwork} src={deleteIcon} alt="" />
                                </td>
                                </tr>
                                    )}
                                    </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </table>
                    <AddItemTableRow toolTipContent={'Netwerk toevoegen'} onClick={addNetwork}/>
                </div>
            </div>

            <div class="table-container dashboard-container">
                <h3>Bijzonderheden</h3>

                <div className='table-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}></th>
                            <th style={{backgroundColor: secundairyColor}}>BIJZONDERHEID</th>
                            <th style={{backgroundColor: secundairyColor}}>OMSCHRIJVING</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                        <DragDropContext onDragEnd={handleDragEndSpecifics}>
                        <Droppable droppableId="specific" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            > 
                        {specifics && specifics.map((item, index) => (
                            <Draggable
                            key={item.ID}
                            draggableId={item.ID}
                            index={index}
                        >
                            {(provided, snapshot) => (
                            <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                               <td>
                                    <DragIndicatorOutlinedIcon />
                                </td>
                               <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Specific} onChange={specificTitleHandler}/>
                                </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Description} onChange={specificValueHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' data-docid={item.docid} onClick={deleteSpecific} src={deleteIcon} alt="" />
                                </td>
                                </tr>
                                    )}
                                    </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </table>
                    <AddItemTableRow toolTipContent={'BIjzonderheid toevoegen'} onClick={addSpecific}/>
                </div>
            </div>

            <div class="table-container dashboard-container">
                <h3>Risico's</h3>

                <div className='table-container'>
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}></th>
                            <th style={{backgroundColor: secundairyColor}}>RISICO</th>
                            <th style={{backgroundColor: secundairyColor}}>OMSCHRIJVING</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                        <DragDropContext onDragEnd={handleDragEndRisks}>
                        <Droppable droppableId="risks" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            > 
                        {risks && risks.map((item, index) => (
                           <Draggable
                           key={item.ID}
                           draggableId={item.ID}
                           index={index}
                       >
                           {(provided, snapshot) => (
                           <tr
                               ref={provided.innerRef}
                               {...provided.draggableProps}
                               {...provided.dragHandleProps}
                           >
                              <td>
                                   <DragIndicatorOutlinedIcon />
                               </td>
                              <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Risk} onChange={riskTitleHandler}/>
                                </td>
                                <td>
                                    <input type="text" data-docid={item.docid} defaultValue={item.Description} onChange={riskValueHandler}/>
                                </td>
                                <td>
                                    <img className='table-delete-icon' data-docid={item.docid} onClick={deleteRisk} src={deleteIcon} alt="" />
                                </td>
                                </tr>
                                    )}
                                    </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </table>
                    <AddItemTableRow toolTipContent={'Risico toevoegen'} onClick={addRisk}/>
                </div>
            </div>

            <div class="table-container dashboard-container">
            <h3>Documenten</h3>
            <div className='table-container'>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}></th>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                        <th style={{backgroundColor: secundairyColor}}>DOWNLOADEN</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                    </tr>
                    <DragDropContext onDragEnd={handleDragEndDocuments}>
                        <Droppable droppableId="documents" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            > 
                    {documents && documents.map((item, index) => (
                         <Draggable
                         key={item.ID}
                         draggableId={item.ID}
                         index={index}
                     >
                         {(provided, snapshot) => (
                         <tr
                             ref={provided.innerRef}
                             {...provided.draggableProps}
                             {...provided.dragHandleProps}
                         >
                            <td>
                                 <DragIndicatorOutlinedIcon />
                             </td>
                            <td>
                                <input type="text" defaultValue={item.Title} data-docid={item.docid} onChange={documentTitleHandler}/>
                            </td>   
                            <td>
                                <select name="" id="" defaultValue={item.Type} data-docid={item.docid} onChange={documentTypeHandler}>
                                    <option value="">-- Selecteer --</option>
                                    <option value="care-agreement">Zorgovereenkomst</option>
                                    <option value="id">Identiteitsdocument</option>
                                    <option value="contract">Contract</option>
                                    <option value="vog">VOG</option>
                                    <option value="avg">AVG</option>
                                    <option value="safety">Veiligheidsplan</option>
                                </select>
                            </td>
                            <td>
                                <a href={item.URL} target='_blank' download>
                                    <img className='table-delete-icon' src={DownloadIcon} alt="Download" />
                                </a>
                            </td>
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid} onClick={deleteDocument} src={deleteIcon} alt="" />
                            </td>
                            </tr>
                                    )}
                                    </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </table>
                <AddItemTableRow toolTipContent={'Document toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
                <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}}>
                    <input
                    id='persona-document-upload-input'
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    />
                    <div className='add-options-upload-buttons-container'>
                        <button className='button-simple' onClick={() => setShowAddContainer(!showAddContainer)}>Annuleren</button>
                        <button className='button-simple' onClick={handleSaveDocument}>Uploaden</button>
                    </div>
                </div>
            </div>
        </div>


        </div>
    </div>
  )
}

export default Data