import PageHeader from "../../components/layout/PageHeader.jsx";
import { useState } from 'react';
import { useFirestoreGeneralOrderBy, useFirestore } from '../../firebase/useFirestore.js';
import Location from "../../hooks/Location"
import { db } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import firebase from 'firebase/app';
import useSettings from "../../hooks/Settings";

const AddEvent = () => {
    // State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [outputsArray, setOutputsArray] = useState([]);
    const [personasArray, setPersonasArray] = useState([]);

    // Hooks
    const activityId = Location()[3]
    const history = useHistory()
    const persona = useSettings().personasOn

    // Firestore 
    const outputs = useFirestoreGeneralOrderBy('Outputs', 'ActivityID', activityId ? activityId : '', 'Position',  'asc');
    const personas = useFirestore('Personas')

    // Number handler
    const numberHandler = (e) => {
        const object = {
            id: e.target.dataset.id,
            value: e.target.value
        };
    
        setOutputsArray(prevOutputs => {
            const exists = prevOutputs.some(output => output.id === object.id);
            if (exists) {
                return prevOutputs.map(output =>
                    output.id === object.id ? object : output
                );
            } else {
                return [...prevOutputs, object];
            }
        });
    };

    // Persona handler
    const personaHandler = (e) => {
        const value = e.target.value;
    
        if (e.target.checked) {
            // Add the value to the array if checked
            setPersonasArray([...personasArray, value]);
        } else {
            // Remove the value from the array if unchecked
            setPersonasArray(personasArray.filter(persona => persona !== value));
        }
    };

    // Save event
    const saveEvent = async () => {
        const eventId = uuid();
    
        try {
             // Save the event to the database
            const saveEventPromise = db.collection('Events').add({
                Title: title,
                Description: description,
                Date: firebase.firestore.Timestamp.fromDate(new Date(date)),
                Outputs: outputsArray,
                Personas: personasArray,
                ActivityID: activityId,
                ID: eventId,
                Compagny: client,
                CompagnyID: client,
            });
    
             // Save the results to the database
            const saveResultsPromises = outputsArray 
                ? outputsArray.map(output => {
                    return db.collection('Results').doc().set({
                        Compagny: client,
                        CompagnyID: client,
                        ID: uuid(),
                        Result: Number(output.value),
                        Timestamp: firebase.firestore.Timestamp.fromDate(new Date(date)),
                        OutputID: output.id,
                        ActivityID: activityId,
                        Personas: personasArray,
                        EventID: eventId,
                    });
                })
                : [];
    
             // Wait for all saves to complete
            await Promise.all([saveEventPromise, ...saveResultsPromises]);
    
            // Redirect to the events page
            history.push(`/${client}/outputresults`);
        } catch (error) {
            console.error('Error saving event or results:', error);
        }
    };
    
    

    return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Gebeurtenis toevoegen'}
                description={`Voeg hier een nieuwe gebeurtenis toe.`}
                AI={false}
            />
            <div className="table-container dashboard-container">
                <h2>Titel</h2>
                <input type="text" placeholder='Shrijf hier de titel van de gebeurtenis' onChange={(e) => setTitle(e.target.value)} />
            </div>
            <div className="table-container dashboard-container">
                <h2>Datum</h2>
                <input 
                type="date" 
                onChange={(e) => setDate(e.target.value)} 
                />
            </div>
            <div className="table-container dashboard-container">
                <h2>Beschrijving</h2>
                <textarea placeholder='Schrijf hier de beschrijving van de gebeurtenis' onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="table-container dashboard-container">
                <h2>Outputs</h2>
                {outputs && outputs.map(output => (
                        <div key={output.ID} id='add-event-output-item-container'>
                            <p>{output.Title}</p>
                            <input type="number" placeholder={0} data-id={output.ID} onChange={numberHandler}/>
                        </div>
                ))}
            </div>
            <div className="table-container dashboard-container add-event-connect-persona-container" style={{display: persona}}>
                <h2>Deelnemers</h2>
                {personas && personas.map(persona => (
                    <div key={persona.ID} id='add-event-persona-item-container'>
                        <input type="checkbox" value={persona.ID} onChange={personaHandler}/>
                        <label>{persona.Name}</label>
                    </div>
                ))}
            </div>
            <div className="button-container">
                <button className="button" onClick={saveEvent}>Opslaan</button>
            </div>
        </div>
    </div>
  )
}

export default AddEvent