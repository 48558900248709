import { useState, useContext, useEffect } from "react";
import AddItemTableRow from "../../../components/common/AddItemTableRow.jsx";
import Tooltip from "../../../components/common/Tooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { v4 as uuid } from "uuid";
import { db, timestamp } from "../../../firebase/config";
import { useHistory } from "react-router-dom";
import useSettings from "../../../hooks/Settings";
import deleteIcon from "../../../images/icons/delete-icon.png";
import { client } from "../../../hooks/Client";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import Modal from 'react-modal';
import { useFirestoreGeneralOrderBy } from '../../../firebase/useFirestore';

const PresenceTable = ({item, length, getStartTimeFromSchedule, getEndTimeFromSchedule, auth, personaId }) => {
  // State
  const [dragArray, setDragArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
    const [updatedDocid, setUpdatedDocid] = useState(null);
    const [updatedStart, setUpdatedStart] = useState(null);
    const [updatedEnd, setUpdatedEnd] = useState(null);

  // Firestore
    const presenceReports = useFirestoreGeneralOrderBy(
        'PersonaPresenceReports',
        'PersonaID',
        item.ID ? item.ID : '',
        'Position',
        'asc',
      );
  
  // Hooks
  const history = useHistory();
  const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  const secundairyColor = useSettings().SecundairyColor;

  // Modal settings
      Modal.setAppElement('#root');
    
      const modalStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      };

  // Update dragArray when Firestore data changes
  useEffect(() => {
    setDragArray(presenceReports);
  }, [presenceReports]);

  // DRAG & DROP: reorder items and update Firestore positions
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArray);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state immediately
    setDragArray(items);

    // Update Firestore with new positions
    items.forEach((item, idx) => {
      db.collection("PersonaPresenceReports")
        .doc(item.docid)
        .update({ Position: idx + 1 });
    });
  };

  // Helper to format a Dutch date string to "YYYY-MM-DD" for input fields
  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    // If date is already in the correct format, return it
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) return dateString;

    try {
      const dutchMonths = {
        januari: "01",
        februari: "02",
        maart: "03",
        april: "04",
        mei: "05",
        juni: "06",
        juli: "07",
        augustus: "08",
        september: "09",
        oktober: "10",
        november: "11",
        december: "12",
      };

      const parts = dateString.split(" ");
      if (parts.length >= 3) {
        const day = parts[1].padStart(2, "0");
        const month = dutchMonths[parts[2].toLowerCase()];
        const year = parts[3];
        if (day && month && year) return `${year}-${month}-${day}`;
      }
      console.warn("Could not parse date:", dateString);
      return "";
    } catch (error) {
      console.error("Error parsing date:", error);
      return "";
    }
  };

  // Helper to get the current weekday info and formatted date
  const getCurrentWeekdayInfo = () => {
    const currentDate = new Date();
    const currentWeekday = dayNames[currentDate.getDay()];
    const formattedDate = currentDate.toLocaleDateString("nl-NL", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return {
      date: formattedDate,
      type: "correct",
      start: getStartTimeFromSchedule(currentWeekday),
      end: getEndTimeFromSchedule(currentWeekday),
    };
  };

  // Add a new presence report document to Firestore
  const addReport = () => {
    const { date, type, start, end } = getCurrentWeekdayInfo();

    db.collection("PersonaPresenceReports")
      .doc()
      .set({
        Date: date,
        Type: type,
        Start: start || 9,
        End: end || 17,
        Schedule: 0,
        CorrectedStart: "",
        CorrectedEnd: "",
        Absent: "",
        Reason: "",
        PresenceID: "",
        PersonaID: personaId,
        CompagnyID: client,
        Timestamp: timestamp,
        Author: auth.ID,
        ID: uuid(),
        Position: length + 1,
      });
  };

  // Update the date of a presence report document
  const updatePresenceDate = (e) => {
    const newDate = e.target.value;
    const formattedDate = new Date(newDate).toLocaleDateString("nl-NL", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const docid = e.target.dataset.docid;

    db.collection("PersonaPresenceReports")
      .doc(docid)
      .update({
        Date: formattedDate,
      })
      .then(() => console.log(`Date updated for report ${docid}`))
      .catch((error) => console.error("Error updating date:", error));
  };

  // Delete a report document by its document ID
  const deleteReport = (docid) => {
    db.collection("PersonaPresenceReports")
      .doc(docid)
      .delete()
      .then(() => console.log(`Report with ID ${docid} deleted`))
      .catch((error) => console.error("Error deleting report:", error));
  };

  // Inline update function to update the "present" value for a document
  const handleSheduleUpdate = (e) => {
    
    const newValue = e.target.value;
    const docid = e.target.dataset.docid;

    db.collection("PersonaPresenceReports")
      .doc(docid)
      .update({ 
        Schedule: newValue 
    })
      .then(() => {
        console.log(`Document ${docid} updated with new present value: ${newValue}`);
      })
      .catch((error) => {
        console.error(`Error updating document ${docid}:`, error);
      });
  };

  // Helper function to convert a "HH:MM" string to minutes since midnight
  const convertTimeToMinutes = (timeStr) => {
    if (typeof timeStr !== 'string' || !timeStr.includes(':')) {
      console.warn('Invalid time format:', timeStr);
      return 0; // or throw an error or return null, depending on your use case
    }
  
    const [hoursStr, minutesStr] = timeStr.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
  
    if (isNaN(hours) || isNaN(minutes)) {
      console.warn('Invalid time numbers:', timeStr);
      return 0;
    }
  
    return hours * 60 + minutes;
  };
  

  // Update the attendance of a presence report document
    const saveUpdatedAttendance = () => {

        console.log(updatedDocid, updatedStart, updatedEnd);
        
        db.collection("PersonaPresenceReports")
            .doc(updatedDocid)
            .update({
                Start: String(updatedStart),
                End: String(updatedEnd)
            })            
            .then(() => {
                console.log(`Document ${updatedDocid} updated with new attendance values`);
                setOpenModal(false);
            })
            .catch((error) => {
                console.error(`Error updating document ${updatedDocid}:`, error);
            });
    }

    // Update the reason for absence of a presence report document
    const updateAbsentReasonHandler = (e) => {
        const newValue = e.target.value;
        const docid = e.target.dataset.docid;
    
        db.collection("PersonaPresenceReports")
          .doc(docid)
          .update({ 
            Reason: newValue 
        })
          .then(() => {
            console.log(`Document ${docid} updated with new absent reason: ${newValue}`);
          })
          .catch((error) => {
            console.error(`Error updating document ${docid}:`, error);
          }
        );
    };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th style={{ backgroundColor: secundairyColor }}></th>
            <th style={{ backgroundColor: secundairyColor }}>DATUM</th>
            <th style={{ backgroundColor: secundairyColor }}>GEROOSTERD</th>
            <th style={{ backgroundColor: secundairyColor }}>AANWEZIGHEID</th>
            <th style={{ backgroundColor: secundairyColor }}>VERSCHIL</th>
            <th style={{ backgroundColor: secundairyColor }}>REDEN</th>
            <th style={{ backgroundColor: secundairyColor }}>VERWIJDEREN</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="presenceReports" direction="vertical">
            {(provided) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {dragArray &&
                  dragArray.map((presence, index) => (
                    <Draggable key={presence.ID} draggableId={presence.ID} index={index}>
                      {(provided) => (
                        <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <td>
                            <DragIndicatorOutlinedIcon />
                          </td>
                          <td>
                            <input
                              type="date"
                              defaultValue={formatDateForInput(presence.Date)}
                              data-docid={presence.docid}
                              onBlur={updatePresenceDate}
                            />
                          </td>
                          <td>
                            <input type="text" defaultValue={presence.Schedule} data-docid={presence.docid} onChange={handleSheduleUpdate} />
                          </td>
                          <td>
                            <div id='presence-table-attendance-container'>
                                <p> {convertTimeToMinutes(presence.End) - convertTimeToMinutes(presence.Start)}</p>
                                <Tooltip content="Aanwezigheid aanpassen" width="30px" top="-50px">
                                    <EditOutlinedIcon onClick={() => {
                                        setOpenModal(!openModal); 
                                        setUpdatedDocid(presence.docid);
                                        setUpdatedStart(presence.Start);
                                        setUpdatedEnd(presence.End)
                                        }}
                                    />
                                </Tooltip>
                            </div>
                          </td>
                          <td>
                            <p>{(convertTimeToMinutes(presence.End) - convertTimeToMinutes(presence.Start)) - presence.Schedule}</p>
                          </td>
                          <td>
                            <input type="text" defaultValue={presence.Reason} data-docid={presence.docid} onChange={updateAbsentReasonHandler}/>
                          </td>
                          <td>
                            <Tooltip content="Verwijderen" width="30px" top="-40px">
                              <img
                                className="table-delete-icon"
                                src={deleteIcon}
                                alt="delete"
                                onClick={() => deleteReport(presence.docid)}
                                data-docid={presence.docid}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
      <AddItemTableRow toolTipContent="Aanwezigheidsrapport toevoegen" onClick={addReport} />
      <Modal
              isOpen={openModal}
              onRequestClose={() => setOpenModal(false)}
              style={modalStyles}
              contentLabel="Update attendance"
            >
              <div>
                <div id="delete-modal-title-container">
                  <h1>Aanwezigheid aanpassen</h1>
                </div>
                <div>
                  <h3>Pas de starttijd aan</h3>
                    <input type="time" defaultValue={updatedStart} onChange={(e) => setUpdatedStart(e.target.value)} />
                    <h3>Pas de eindtijd aan</h3>
                    <input type="time" defaultValue={updatedEnd} onChange={(e) => setUpdatedEnd(e.target.value)}/>
                </div>
                <div id="delete-modal-button-container">
                  <button id="delete-modal-delete-button" onClick={() => setOpenModal(false)}>Annuleren</button>
                  <button onClick={saveUpdatedAttendance}>
                    Opslaan
                  </button>
                </div>
              </div>
            </Modal>
    </div>
  );
};

export default PresenceTable;
