import Modal from 'react-modal';
import { useFirestore} from '../../firebase/useFirestore';
import ActivityMeta from '../activities/ActivityMeta';
import useSettings from '../../hooks/Settings';
import { useState } from 'react';

const AddOutputsModal = ({ setAddOutputsModal, addOutputsModal, addOutput, selectedOutputs, setSelectedOutputs }) => {
    // Firestore
    const outputs = useFirestore('Outputs');

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
  
    // Modal settings
    Modal.setAppElement('#root');
  
    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };
  
    // Handle selection and deselection of outputs
    const handleSelectChange = (e) => {
      const id = e.target.dataset.id;
      const standard = e.target.dataset.standard === 'true'; // Convert to boolean
      const output = outputs.find((effect) => effect.ID === id);
  
      if (!output) return;
  
      const updatedOutput = { ...output, standard };
  
      if (e.target.checked) {
        // Add output to the selected list if not already present
        setSelectedOutputs((prev) => {
          if (prev.some((item) => item.ID === id)) {
            return prev; // Avoid duplicates
          }
          return [...prev, updatedOutput];
        });
      } else {
        // Remove outputs from the selected list
        setSelectedOutputs((prev) => prev.filter((item) => item.ID !== id));
      }
    };
  
    return (
      <Modal
        isOpen={addOutputsModal}
        onRequestClose={() => setAddOutputsModal(false)}
        style={modalStyles}
        contentLabel="Add outputs"
      >
        <div>
          <div id="delete-modal-title-container">
            <h1>Outputs synchroniseren</h1>
          </div>
          <div>
            <h2>Selecteer de outputs die u wilt synchroniseren</h2>
            <div className="table-container dashboard-container">
            <table>
                <tr>
                    <th style={{backgroundColor: secundairyColor}}>SELECTEER</th>
                    <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                    <th style={{backgroundColor: secundairyColor}}>ACTIVITEIT</th>
                </tr>
                    {outputs && outputs.map((output, index) => (
                        <tr key={output.ID}>
                          <td>
                          <input 
                            type="checkbox"
                            name={output.ID} 
                            data-docid={output.docid} 
                            defaultChecked={selectedOutputs.includes(output.ID)} 
                            data-id={output.ID} 
                            onChange={handleSelectChange} 
                            />
                          </td>
                          <td>
                            <p>{output.Title}</p>
                          </td>
                          <td>
                            <ActivityMeta id={output.ActivityID} />
                          </td>
                        </tr>
                    ))}
                </table>
            </div>
          </div>
          <div id="delete-modal-button-container">
            <button id="delete-modal-delete-button" onClick={() => setAddOutputsModal(false)}>Annuleren</button>
            <button onClick={addOutput}>
              Opslaan
            </button>
          </div>
        </div>
      </Modal>
    );
  };
  
  export default AddOutputsModal;
  