import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import TotalResponses from '../TotalResponses';

const MatrixDevelopmentBarChart = ({ data, lowerScale, upperScale, totalResponses}) => {
  const [hoveredBar, setHoveredBar] = useState(null);

  // Ensure data is not empty before processing
  if (!data || data.length === 0) {
    return <p>Nog geen onderzoeksresultaten binnen</p>;
  }

  // Extract unique keys for dynamic data
  const uniqueRowTitles = Object.keys(data[0]).filter((key) => key !== "name");

  // Calculate total growth normalized to the scale
  const calculateTotalGrowth = () => {
    const totalValues = data.map((item) =>
      uniqueRowTitles.reduce((sum, key) => {
        const value = item[key]?.value;
        const adjustedValue =
          item[key]?.negative && value !== undefined
            ? upperScale - value + lowerScale
            : value ?? 0;
        return sum + adjustedValue;
      }, 0)
    );

    if (totalValues.length > 1) {
      const initialTotal = totalValues[0];
      const finalTotal = totalValues[totalValues.length - 1];
      const maxTotal = uniqueRowTitles.length * (upperScale - lowerScale);

      return ((finalTotal - initialTotal) / maxTotal) * 100;
    }
    return 0;
  };

  const totalGrowth = calculateTotalGrowth().toFixed(1);

  // Calculate growth for each rowTitle between moments, normalized to the scale
  const growthDataForRows = uniqueRowTitles.reduce((acc, key) => {
    acc[key] = data.reduce((growthArray, current, index) => {
      if (index > 0) {
        const prevValue = data[index - 1][key]?.value;
        const prevNegative = data[index - 1][key]?.negative;
        const currentValue = current[key]?.value;
        const currentNegative = current[key]?.negative;

        // Adjust values based on the 'negative' property
        const adjustedPrevValue =
          prevNegative && prevValue !== undefined
            ? upperScale - prevValue + lowerScale
            : prevValue;

        const adjustedCurrentValue =
          currentNegative && currentValue !== undefined
            ? upperScale - currentValue + lowerScale
            : currentValue;

        const growth =
          adjustedPrevValue !== undefined && adjustedCurrentValue !== undefined
            ? ((adjustedCurrentValue - adjustedPrevValue) / (upperScale - lowerScale)) * 100
            : null;

        growthArray.push(growth !== null ? growth.toFixed(1) : null);
      } else {
        growthArray.push(null);
      }
      return growthArray;
    }, []);
    return acc;
  }, {});

  // Custom legend component
  const CustomizedLegend = ({ payload }) => (
    <ul>
      {payload.map((entry, index) => {
        const rowTitle = entry.dataKey;
        const growthArray = growthDataForRows[rowTitle];
        const lastGrowth = growthArray ? growthArray[growthArray.length - 1] : null;

        const growthLabel =
          lastGrowth !== null ? ` (${lastGrowth > 0 ? "+" : ""}${lastGrowth}%)` : "";

        return (
          <li
            key={`item-${index}`}
            onMouseEnter={() => setHoveredBar(rowTitle)}
            onMouseLeave={() => setHoveredBar(null)}
            style={{
              cursor: "pointer",
              fontWeight: hoveredBar === rowTitle ? "bold" : "normal",
              color: entry.color,
            }}
          >
            {rowTitle}
            {growthLabel}
          </li>
        );
      })}
    </ul>
  );

  return (
    <>
      <h3>Totale groei: {totalGrowth > 0 ? "+" : ""}{totalGrowth}%</h3>
      <TotalResponses totalResponses={totalResponses} />
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data.map((item) =>
            Object.fromEntries(
              Object.entries(item).map(([key, value]) => {
                if (key === "name") {
                  return [key, value];
                } else {
                  const adjustedValue =
                    value?.negative && value?.value !== undefined
                      ? upperScale - value.value + lowerScale
                      : value?.value ?? null;

                  return [key, adjustedValue];
                }
              })
            )
          )}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, upperScale]} />
          <Tooltip />
          <Legend content={<CustomizedLegend />} verticalAlign="bottom" />
          {uniqueRowTitles.map((rowTitle, index) => (
            <Bar
              key={`bar-${index}`}
              dataKey={rowTitle}
              fill={data[0][rowTitle]?.color || "#206c7f"}
              fillOpacity={hoveredBar && hoveredBar !== rowTitle ? 0.3 : 1}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default MatrixDevelopmentBarChart;
