import React from 'react'
import DraggableTableItems from '../../components/common/DraggableTableItems';
import { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddItemTableRow from '../../components/common/AddItemTableRow';
import { useFirestore } from '../../firebase/useFirestore';
import { db, timestamp } from '../../firebase/config';
import { useHistory } from 'react-router-dom';
import Tooltip from '../../components/common/Tooltip';
import deleteIcon from '../../images/icons/delete-icon.png'
import { client } from '../../hooks/Client'
import useSettings from "../../hooks/Settings";
import { v4 as uuid } from 'uuid';

const MyFinanciers = () => {
    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    
    // Firestore
    const financiers = useFirestore('Financiers')

    // Hooks
    const history = useHistory();
    const secundairyColor = useSettings().SecundairyColor;

    // Type array
    const typeArray = ['Fonds', 'Gemeente', 'Investeerder', 'Klant', 'Inkoper', 'Donateur', 'Maatschappelijk aandeelhouder', 'Anders']

    // Delete financier
    const deleteFinancier = (e) => {
        const docid = e.target.dataset.docid

        db.collection('Financiers').doc(docid).delete()
    }

    // Add financier
    const addFinancier = () => {
        
        db.collection('Financiers').add({
            Title: 'Nieuwe financier',
            Type: '',
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp
        })
    }

    // Title handler
    const titleHandler = (e) => {
        const docid = e.target.dataset.docid
        const value = e.target.value

        db.collection('Financiers').doc(docid).update({
            Title: value
        })
    }

    // Type handler
    const typeHandler = (e) => {
        const docid = e.target.dataset.docid
        const value = e.target.options[e.target.selectedIndex].value

        db.collection('Financiers').doc(docid).update({
            Type: value
        })
    }

  return (
    <div className="main">
        <div className="main-container">
            <div className="page-header">
                <h1>Mijn financiers</h1>
            </div>
            <div className="table-container dashboard-container">
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>NAAM</th>
                            <th style={{backgroundColor: secundairyColor}}>TYPE</th>
                            {/* <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th> */}
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                    </thead>    
                    {financiers && financiers.map((item, index) => (
                        <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={item}
                            input={
                                <Tooltip content={item.Title} width='80%' right='-220px' top='-35px'>
                                   <input type="text" defaultValue={item.Title} placeholder={item.Title} data-docid={item.docid} onChange={titleHandler}/>
                                </Tooltip>
                            }
                            parentIndex={index}
                            collection={'financiers'}
                            itemArray={financiers}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={item.Title}
                            setPlaceholderIndex={setPlaceholderIndex}
                            >
                            <td>
                                <select name="" id="" value={item.Type} data-docid={item.docid} onChange={typeHandler}>
                                    <option value="">-- Selecter type --</option>
                                    {typeArray.map((type, index) => (
                                        <option key={index} value={type}>{type}</option>
                                    ))}
                                </select>
                            </td>
                            {/* <td>
                                <EditOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/financiersdetails/${item.ID}`)} />
                            </td> */}
                            <td>
                                <img className='table-delete-icon' data-docid={item.docid} onClick={deleteFinancier} src={deleteIcon} alt="" />
                            </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
                <AddItemTableRow toolTipContent={'Financier toevoegen'} onClick={addFinancier}/>
            </div> 
        </div>
    </div>
  )
}

export default MyFinanciers