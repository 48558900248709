import React from 'react'
import { useFirestoreGeneralOrderBy } from '../../../firebase/useFirestore'
import TitleIcon from '@mui/icons-material/TitleOutlined';
import ImageIcon from '@mui/icons-material/InsertPhotoOutlined';
import ParagraphIcon from '@mui/icons-material/NotesOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import AirlineStopsOutlinedIcon from '@mui/icons-material/AirlineStopsOutlined';
import PlusOneOutlinedIcon from '@mui/icons-material/PlusOneOutlined';
import RowingOutlinedIcon from '@mui/icons-material/RowingOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import OutputData from '../../graphData/OutputData';
import LiveReportEffectData from '../LiveReportEffectData';

const ReportLayoutItems = ({elementId, setSidebarMenuItem, setItemDocid, itemDocid, setItemId, setSelectedElementId}) => {

  // Firestore
    const items = useFirestoreGeneralOrderBy('ReportLayoutItems', 'ElementID', elementId, 'Position', 'asc');

    // Element type
    const itemType = (item) => {
        switch (item.Type) {
            case 'title':
                return (
                  <>
                    {item.Title ?
                    <div id='live-report-title-item-container'>
                      <h1 style={{ 
                        color: item.Color, 
                        fontSize: item.Size === 'small' ? '24px' : item.Size === 'normal' ? '32px' : '56px',
                        textAlign: item.Position
                      }}>
                        {item.Title}
                      </h1>
                    </div>
                    :
                    <div className='layout-items-item-icon-container'>
                      <TitleIcon />
                    </div>

                    
                    }
                  </>
                )
            case 'paragraph':
              return (
                <>
                  {item.Paragraph ?
                  <div id='layout-items-item-paragraph-container'>
                    <p style={{color: item.Color}}>{item.Paragraph}</p> 
                  </div>
                  :
                  <div className='layout-items-item-icon-container'>
                    <ParagraphIcon />
                  </div>
                  }
                </>
              )
            case 'image':
                return (
                  <>
                    {item.Media ?
                    <div id='live-report-media-item-container'>
                      <img src={item.Media} alt="" style={{borderRadius: `${item.BorderRadius}px`, width: `${item.Size}%`}} /> 
                    </div>
                    :
                    <div className='layout-items-item-icon-container'>
                      <ImageIcon />
                    </div>
                    }
                  </>
                )
              case 'qoute':
                return (
                  <>
                    {item.Qoute ?
                    <div id='live-report-media-item-container'>
                      <div id='live-report-qoute-item-icon-container'>
                        <FormatQuoteOutlinedIcon style={{fill: item.Color}}/>
                        <h3 style={{color: item.Color}}>{item.Qoute}</h3>
                        <FormatQuoteOutlinedIcon style={{fill: item.Color}}/>
                      </div>
                      <p style={{color: item.Color}} id='live-report-qoute-name-item'>- {item.Name}</p>
                    </div>
                    :
                    <div className='layout-items-item-icon-container'>
                      <ChatOutlinedIcon />
                    </div>
                    }
                  </>
                )
                case 'anekdote':
                  return (
                    <>
                      {item.Anecdote?
                      <div id='live-report-media-item-container'>
                        <h2 style={{color: item.Color}}>Anekdote</h2>
                        <p style={{color: item.Color}}>{item.Anecdote}</p>
                      </div>
                      :
                      <div className='layout-items-item-icon-container'>
                        <AutoStoriesOutlinedIcon />
                      </div>
                      }
                    </>
                  )
                case 'activity':
                  return (
                    <>
                      {item.Activity ?
                      <div id='live-report-media-item-container'>
                        <div id='live-report-activity-graph-container'>
                          <OutputData activity={item.Activity} graphType={item.GraphType}/>
                        </div>
                      </div>
                      :
                      <div className='layout-items-item-icon-container'>
                        <RowingOutlinedIcon />
                      </div>
                      }
                    </>
                  )
                  case 'effect':
                  return (
                    <>
                      {item.Effect ?
                      <div id='live-report-media-item-container'>
                        <div id='live-report-activity-graph-container'>
                          <LiveReportEffectData effectId={item.Effect} />
                        </div>
                      </div>
                      :
                      <div className='layout-items-item-icon-container'>
                        <AirlineStopsOutlinedIcon/>
                      </div>
                      }
                    </>
                  )
            case '':
              return (
                <>
                  <div 
                  className='layout-items-item-icon-container layout-items-item-no-type-container'
                  >
                    <p>Voeg een item toe</p>
                  </div>
                </>
              )
            default:
                return null
        }
    }

  return (
    <div id='layout-items-container'>
        {items && items.length === 0 ?
        <div className='layout-items-item-container'
          onClick={() => {setSidebarMenuItem('addItems'); setItemDocid(''); setItemId(''); setSelectedElementId(elementId)}}
        >
          <h2>Voeg item toe</h2>
        </div>
        :
        items && items.map((item) => (
            <div 
            key={item.ID} 
            className='layout-items-item-container' 
            >
              {itemType(
                item
                )}
                <div id='report-layout-options-container'>
                    <div onClick={() => {
                      if(item.Type === '') {
                        setSidebarMenuItem('addItems');
                        setSelectedElementId(elementId) 
                      }
                      else {
                        setSidebarMenuItem(item.Type); 
                        setItemDocid(item.docid); 
                        setItemId(item.ID); 
                        setSelectedElementId(elementId)
                      }
                    }}
                      className="edit-layout-button">
                      <EditOutlinedIcon />
                    </div>
                </div>
            </div>
        ))
        }
    </div>
  )
}

export default ReportLayoutItems