import {useFirestoreTimestamp} from "../../firebase/useFirestore"
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import useSettings from "../../hooks/Settings";
import PreviousStepContainer from "../../components/common/PreviousStepContainer";
import NextStepContainer from "../../components/common/NextStepContainer.jsx";
import { useEffect, useContext } from "react";
import { Auth } from "../../StateManagment/Auth";
import { GoalSystemMessage, GoalToolCallContent, GoalSaveMessage } from "../../hooks/impactAI/Prompts"
import { db } from "../../firebase/config.js"
import { ImpactAI } from "../../StateManagment/ImpactAI";
import SavedNotice from "../../components/common/SavedNotice"
import PageHeader from "../../components/layout/PageHeader.jsx";

const Goals = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // Hooks
    const history = useHistory()
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const goals = useFirestoreTimestamp("Goals")

    //ImpactAI
    const pageSystemMessage = GoalSystemMessage()
    const pageToolCallContent = GoalToolCallContent()
    const pageSaveMessage = GoalSaveMessage()
    const pageStartMessage = `Welkom, ${auth?.ForName}. In deze stap gaan we het impactdoel van je organisatie beschrijven.
    Mag ik een voorstel doen?`
    const pageCollection = 'Goals'
    const pageField = 'Title'
    const pageParentId = ''
    const pageType = 'goal'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages([])
    }, [auth])

    // Set docid to impactAI context
    useEffect(() => {   
        goals && goals.forEach(item => {
            setDocid(item.docid)
        })
    },[goals])

    // Title handler
    const titleHandler = (e) => {

        const title = e.target.value
        const docid = e.target.dataset.docid
        
        db.collection("Goals")
        .doc(docid)
        .update({
            Title: title
        })
        .then(() => {
            setSaved('flex')
         })
    }

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])
   
    return (
        <div className="main">
            <div className="main-container">
                <PageHeader 
                    heading={'Impactdoel'}
                    description={`In deze stap beschrijf je het impactdoel van je organisatie. Dit is het maatschappelijke probleem dat je wilt oplossen.`}
                    AI={true}
                />
                <div className="table-container dashboard-container">
                    <table>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}>DOEL</th>
                        </tr>
                        {goals && goals.map(goal => (
                            <tr key={goal.ID}>
                                <td className="single-row-table">
                                    <textarea type="text" data-docid={goal.docid} defaultValue={goal.Title} placeholder='Omschrijf hier je impactdoel' onChange={titleHandler} />
                                </td>
                            </tr>  
                        ))}
                    </table>
                </div>
                <div className="impactstrategy-saved-and-nextstep-container">
                    <SavedNotice />
                    <div id='next-step-container'>
                        <PreviousStepContainer url={'stakeholders'} mainItem={'impactstrategy'}/>
                        <NextStepContainer url={'Activities'} mainItem={'impactstrategy'}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Goals
