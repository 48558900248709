import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import Location from "../../hooks/Location"
import { useFirestoreGeneral, useFirestore, useFirestoreNoCompagny } from '../../firebase/useFirestore';
import { db, timestamp } from '../../firebase/config';
import spinner from "../../images/spinner-ripple.svg";
import { useState } from "react";
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SpokeOutlinedIcon from '@mui/icons-material/SpokeOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import { v4 as uuid } from 'uuid';
import { client } from '../../hooks/Client';
import firebase from 'firebase/app';
import CompagnyMeta from '../../components/portfolio/CompagnyMeta';
import EffectMeta from '../../components/effects/EffectMeta';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Tooltip from '../../components/common/Tooltip';
import OutputMeta from '../../components/outputs/OutputMeta';

const SynchronisationBuilder = () => {
    // State
    const [loading, setLoading] = useState(false)
    const [activityId, setActivityId] = useState('')
    const [outputTitle, setOutputTitle] = useState('');

    // Hooks
    const syncID = Location()[3]

    // Firestore
    const syncs = useFirestoreNoCompagny('Synchronisations', 'ID', syncID ? syncID : '123')
    const outputs = useFirestore('Outputs')
    const activities = useFirestore('Activities')
    const MSIEffects = useFirestoreGeneral('OutputEffects', 'Standard', 'true')

    // adjust the name and color of the status
    const status = (statusCode) => {
        switch (statusCode) {
          case 'requested':
            return { text: 'Aangevraagd', color: '#FFA500' }; // Orange
          case 'accepted':
            return { text: 'Geaccepteerd', color: '#008000' }; // Green
        case 'paired':
                return { text: 'Gekoppeld', color: '#008000' }; // Green
          case 'declined':
            return { text: 'Geweigerd', color: '#FF0000' }; // Red
        case 'deleted':
            return { text: 'Verwijderd', color: '#000000' }; // Red
          default:
            return { text: 'Onbekende status', color: '#000000' }; // Black
        }
      }


    const acceptSyncHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('Synchronisations')
        .doc(docid)
        .update({
            Status: 'accepted'
        })
    }
    

    const declineSyncHandler = (e) => {
        const docid = e.target.dataset.docid

        db.collection('Synchronisations')
        .doc(docid)
        .update({
            Status: 'declined'
        })
    }

    // Onclick function to add output to project
    const projectOutputHandler = async (e) => {

        const docid = e.target.dataset.docid
        const id = e.target.options[e.target.selectedIndex].value

        db.collection('Synchronisations')
        .doc(docid)
        .update({
            ProjectOutput: id,
            Status: 'paired'
        })

    }

    // Onclick function to create a new output
    const createNewOutput = async (e) => {

        const id = e.target.dataset.id
        const docid = e.target.dataset.docid

        const output = await db.collection('Outputs')
        .where('ID', '==', id)
        .get()

        output.forEach( async (doc) => {

            const newOutputId = uuid()

              await db.collection('Outputs').add({
                ID: newOutputId,
                Compagny: client,
                CompagnyID: client,
                Timestamp: timestamp,
                Title: doc.data().Title,
                Position: outputs.length + 1,
                ActivityID: activityId,
                type: 'manual'
            })

            // Update syncronisation with new effect
            await db.collection('Synchronisations')
            .doc(docid)
            .update({
                ProjectOutput: newOutputId,
                Status: 'paired'
            })
        })
    }

    // Onclick function to add effect to project and project database
    const projectEffectHandler = async (e) => {

        setLoading(true)

        const docid = e.target.dataset.docid
        const effectId = e.target.dataset.effectid
        const type = e.target.dataset.type


        // Get all effects of the project
        const effects = await db.collection('OutputEffects')
        .where('CompagnyID', '==', client)
        .get()

        // Get the effect from the portfolio database
        const effect = await db.collection('OutputEffects')
        .where('ID', '==', effectId)
        .get()

        // Create a new effect in the project database
        effect.forEach( async (doc) => {

            const newEffectId = uuid()

            await db.collection('OutputEffects').add({
                Effect: doc.data().Effect,
                Position: effects.docs.length + 1,
                ID: newEffectId,
                Compagny: client,
                Term: 'long',
                CompagnyID: client,
                Timestamp: timestamp,
                type: 'manual',
                MSIId: doc.data().MSIId || '',
                Standard: doc.data().Standard === 'true' ? 'true' : 'false',
            })

            // Get the indicators of the effect
            const indicators = await db.collection('QuestionnaireFields')
            .where('EffectId', 'array-contains', effectId)
            .get()

            // create new indicators in the project database

            indicators.forEach(async (indicator) => {

                const fieldID = uuid()

                db.collection('QuestionnaireFields')
                .doc()
                .set({
                    Compagny: client,
                    CompagnyID: client,
                    ID: fieldID,
                    Timestamp: timestamp,
                    QuestionnaireID: '',
                    Type: indicator.data().Type || '',
                    Question: indicator.data().Question || '',
                    Explainer: '',
                    ReachStart: indicator.data().ReachStart || 1,
                    ReachStartLable: indicator.data().ReachStartLabel || '',
                    ReachEnd: indicator.data().ReachEnd || 5,
                    ReachEndLabel: indicator.data().ReachEndLabel || '',
                    Multiple: indicator.data().Multiple || [],
                    SectionID: '',
                    EffectId:  firebase.firestore.FieldValue.arrayUnion(newEffectId),
                    Indicator: 'true',
                    LiveReportHidden: false,
                    Standard: indicator.data().Standard === 'true' ? 'true' : 'false',
                    MSIId: indicator.data().MSIId || '',
                    SyncId: syncID || '',
                    Position: indicator.data().Position || 1,
                })

                console.log(indicator.data().Type)

                if (indicator.data().Type === 'multiple-one' || indicator.data().Type === 'multiple-multiple') {
                    duplicateOptions(indicator.data().ID, fieldID, indicator.data().Question)
                }

                if (indicator.data().Type === 'matrix-one' || indicator.data().Type === 'matrix-multiple') {
                    duplicateMatrixRows(indicator.data().ID, fieldID, indicator.data().Question)
                    duplicateMatrixColumns(indicator.data().ID, fieldID, indicator.data().Question)
                }
            })

           


            // Update syncronisation with new effect
            await db.collection('Synchronisations')
            .doc(docid)
            .update({
                ProjectEffect: newEffectId,
                Status: 'paired'
            })

        })

        setLoading(false)

    }

    // Helper function to duplicate options of a multiple choice question
    const duplicateOptions = async (portfolioFieldID, fieldID, question) => {

        db.collection('MultipleQuestionOptions')
        .where('Field', '==', portfolioFieldID)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MultipleQuestionOptions')
                .doc()
                .set({
                    Option: doc.data().Option,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('option duplicated')
                })
            })
        })
    }

    // Helper function to duplicate matrix rows
    const duplicateMatrixRows = (portfolioFieldID, fieldID, question) => {

        console.log(fieldID)

        db.collection('MatrixQuestionRows')
        .where('Field', '==', portfolioFieldID)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MatrixQuestionRows')
                .doc()
                .set({
                    Title: doc.data().Title,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('matrix rows duplicated')
                })
            })
        })
    }

    // Helper function to duplicate matrix columns
    const duplicateMatrixColumns = (portfolioFieldID, fieldID, question) => {
        db.collection('MatrixQuestionColumns')
        .where('Field', '==', portfolioFieldID)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MatrixQuestionColumns')
                .doc()
                .set({
                    Title: doc.data().Title,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('matrix columns duplicated')
                })
            })
        })
    }

    // Onclick function to pair a new effect to an existing MSI effect
    const pairToExcistingMSIEffect = async (e) => {

        const projectEffectId = e.target.options[e.target.selectedIndex].value
        const syncID = e.target.dataset.syncid
        const docid = e.target.dataset.docid

        setLoading(true)

        // Update syncronisation with new effect
       await db.collection('Synchronisations')
         .doc(docid)
            .update({
                ProjectEffect: projectEffectId,
                Status: 'paired'
            })

        // Update the indicators of the effect with the new sync id
        const indicators = await db.collection('QuestionnaireFields')
        .where('EffectId', 'array-contains', projectEffectId)
        .get()

        indicators.forEach(async (indicator) => {
            db.collection('QuestionnaireFields')
            .doc(indicator.id)
            .update({
                SyncId: syncID
            })
        })

    }

    // Onclick function to edit paired sync
    const editPairedSync = (e) => {

        const docid = e.target.dataset.docid

        db.collection('Synchronisations')
        .doc(docid)
        .update({
            Status: 'accepted'
        })
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className="page-header" >
                <SyncOutlinedIcon />
                <h1>Synchronisatie</h1>
            </div>
            <div className='dashboard-container'>
                {syncs && syncs.map( (sync) => (
                    <div key={sync.ID} id='sync-detail-container'>

                        {/* Portfolio organisation name */}
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <ApartmentOutlinedIcon />
                                <p><b>Portfolio-organisatie</b></p>
                            </div>
                            <p className='sync-content'><CompagnyMeta id={sync.Parent}/></p>
                        </div>
                        
                        {/* Type of sync */}
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <SpokeOutlinedIcon />   
                                <p><b>Type</b></p>
                            </div>
                            <p className='sync-content'>{sync.Type}</p>
                        </div>

                        {/* Name of output to sync  */}
                        {sync.Type === 'Output' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <OutputOutlinedIcon/>
                                    <p><b>Portfolio output</b></p>
                                </div>
                                <p className='sync-content'>{<OutputMeta id={sync.Output}/>}</p>
                            </div>
                        }

                        {/* Name of effect to sync  */}
                        {sync.Type === 'Effect' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <OutputOutlinedIcon/>
                                    <p><b>Portfolio effect</b></p>
                                </div>
                                <p className='sync-content'>{<EffectMeta item={sync.Effect}/>}</p>
                            </div>
                        }

                        {/* Status of sync */}
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <MoreHorizOutlinedIcon />
                                <p><b>Status</b></p>
                            </div>
                            <p className='sync-content' style={{color: status(sync.Status).color}}>{status(sync.Status).text}</p>
                        </div>  

                        {/* Accept or decline sync */}
                        <div className='sync-item-container'>
                            <div className='activity-meta-title-container'>
                                <ApartmentOutlinedIcon />
                                <p><b>Accepteren of weigeren</b></p>
                            </div>
                            {sync.Status === 'requested' && 
                                <>
                                    {loading ? <img src={spinner} alt='loading' className='spinner' /> :
                                        <div className='sync-content'>
                                            <button 
                                            className='button-simple' 
                                            data-docid={sync.docid} 
                                            data-status={'accepted'} 
                                            data-syncid={sync.ID} 
                                            onClick={acceptSyncHandler}>
                                                Accepteren
                                            </button>
                                            <button 
                                            className='button-simple' 
                                            id='decline-sync-button' 
                                            data-docid={sync.docid} 
                                            data-status={'declined'} 
                                            data-syncid={sync.ID} 
                                            onClick={declineSyncHandler}>
                                                Weigeren
                                            </button>
                                        </div>
                                    }
                                </>
                                
                            }
                            {sync.Status === 'accepted' &&
                                <p className='sync-content'>De synchronisatie is geaccepteerd</p>
                            }
                            {sync.Status === 'declined' &&
                                <p className='sync-content'>De synchronisatie is geweigerd</p>
                            }
                            {sync.Status === 'deleted' &&
                                <p className='sync-content'>De synchronisatie is verwijderd</p>
                            }
                            {sync.Status === 'paired' &&
                                <p className='sync-content'>De synchronisatie is gekoppeld</p>
                            }
                        </div>

                        {/* Accepted sync type is output */}
                        {sync.Status === 'accepted' && sync.Type === 'Output' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <ApartmentOutlinedIcon />
                                    <p><b>Gekopppeld output</b></p>
                                </div>
                                <div>
                                    {sync.ProjectOutput ?
                                        <p className='sync-content'>{outputTitle}</p>
                                    :
                                    <div>
                                        <p><b>Selecteer een bestaande output</b></p>
                                        <select data-syncid={sync.ID} data-docid={sync.docid} onChange={projectOutputHandler}>
                                            <option value=''>-- Selecteer een output --</option>
                                            {outputs && outputs.map((output) => (
                                                <option key={output.ID} value={output.ID}>{output.Title}</option>
                                            ))}
                                        </select>
                                        <p><b>- of creëer een nieuwe output</b></p>
                                        <select onChange={(e) => setActivityId(e.target.options[e.target.selectedIndex].value) }>
                                            <option value=''>-- Selecteer een activiteit --</option>
                                            {activities && activities.map((activity) => (
                                                <option key={activity.ID} value={activity.ID}>{activity.Activity}</option>
                                            ))}
                                        </select>
                                        <div className='button-container' id='sync-builder-create-output-button-container'>
                                            <button data-id={sync.Output} data-docid={sync.docid} onClick={createNewOutput}>Creëer output</button>
                                        </div>
                                       
                                    </div>
                                    }
                                </div>
                            </div>
                        }

                        {/* Accepted sync type is effect */}
                        {sync.Status === 'accepted' && sync.Type === 'Effect' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <ApartmentOutlinedIcon />
                                    <p><b>Gekopppeld effect</b></p>
                                </div>
                                {sync.Standard ?
                                    <div>
                                        <select name="" id="" data-syncid={sync.ID} data-docid={sync.docid} onChange={pairToExcistingMSIEffect}>
                                            <option value="">-- Selecteer een Meetstandaard effect --</option>
                                            {MSIEffects && MSIEffects.map((effect) => (
                                                <option key={effect.ID} value={effect.ID}>{effect.Effect}</option>
                                            ))}
                                        </select>
                                        <p>- of -</p>
                                        <div>
                                            <button 
                                            data-syncitemid={sync.SyncItem} 
                                            data-docid={sync.docid} 
                                            data-effectid={sync.Effect} 
                                            data-type={sync.Type}
                                            onClick={projectEffectHandler}>
                                                Effect toevoegen aan impactstrategie
                                            </button>
                                        </div>
                                    </div>
                                :
                                    <div>
                                        <button 
                                        data-syncitemid={sync.SyncItem} 
                                        data-docid={sync.docid} 
                                        data-effectid={sync.Effect} 
                                        data-type={sync.Type}
                                        onClick={projectEffectHandler}>
                                            Effect toevoegen aan impactstrategie
                                        </button>
                                    </div>
                                }
                            </div>
                        }

                        {/* Paired sync is type effect */}
                        {sync.Status === 'paired' && sync.Type === 'Effect' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <ApartmentOutlinedIcon />
                                    <p><b>Gekopppeld effect</b></p>
                                </div>
                                <div id='sync-builder-paired-effect-title-container'>
                                    <p className='sync-content'>{<EffectMeta item={sync.ProjectEffect}/>}</p>
                                    <Tooltip content='Bewerk gekoppeld effect' top='-60px'>
                                        <EditOutlinedIcon data-docid={sync.docid} onClick={editPairedSync} />
                                    </Tooltip>
                                </div>
                            </div>
                        }

                        {/* Paired sync is type output */}
                        {sync.Status === 'paired' && sync.Type === 'Output' &&
                            <div className='sync-item-container'>
                                <div className='activity-meta-title-container'>
                                    <ApartmentOutlinedIcon />
                                    <p><b>Gekopppeld output</b></p>
                                </div>
                                <div id='sync-builder-paired-effect-title-container'>
                                    <p className='sync-content'>{<OutputMeta id={sync.ProjectOutput}/>}</p>
                                    <Tooltip content='Bewerk gekoppeld output' top='-60px'>
                                        <EditOutlinedIcon data-docid={sync.docid} onClick={editPairedSync} />
                                    </Tooltip>
                                </div>
                            </div>
                        }

                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default SynchronisationBuilder