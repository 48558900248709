import { useFirestoreGeneral, useFirestoreGeneralOrderBy, useFirestoreOrderBy, useFirestoreNoCompagnyThree } from "../../firebase/useFirestore"
import ScrollToTop from "../../hooks/ScrollToTop";
import CompagnyMeta from '../../components/portfolio/CompagnyMeta';
import { client } from "../../hooks/Client"
import { db } from "../../firebase/config";
import uuid from 'react-uuid';
import deleteIcon from '../../images/icons/delete-icon.png'
import useSettings from "../../hooks/Settings";
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useState, useEffect } from "react";
import { OutputResultsSystemMessage } from "../../hooks/impactAI/Prompts"
import { useHistory } from "react-router-dom";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import PageHeader from "../../components/layout/PageHeader.jsx";
import NextStepContainer from "../../components/common/NextStepContainer";
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import SelectPersonas from "../../components/personas/SelectPersonas.jsx";
import EventOutputs from "../../components/outputs/EventOutputs";
import OutputMeta from "../../components/outputs/OutputMeta.jsx";
import SavedNotice from "../../components/common/SavedNotice"
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const OutputResults = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        startMessage: [startMessage, setStartMessage],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
    } = useContext(ImpactAI);

    // State
    const [activityID, setActivityID] = useState('123')
    const [allResults, setAllResults] = useState([])
    const [dragArrayResults, setDragArrayResults] = useState([]);
    const [dragArrayEvents, setDragArrayEvents] = useState([]);

    // Hooks
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const persona = useSettings().personasOn
    const portfolio = useSettings().portfolio
    const history = useHistory()

    // Firestore
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')
    const results = useFirestoreGeneralOrderBy('Results', 'ActivityID', activityID ? activityID : '123','Timestamp', 'asc')
    const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activityID ? activityID : '123')
    const events = useFirestoreGeneral('Events', 'ActivityID', activityID ? activityID : '123')
    const syncs = useFirestoreNoCompagnyThree('Synchronisations', 'Parent', client ? client : '123', 'Type', 'Output', 'Status', 'paired')

    //ImpactAI
    const pageSystemMessage = OutputResultsSystemMessage()
    const pageStartMessage = `Welkom bij de outputresultaten, ${auth?.ForName}. Hier kun je resultaten van activiteiten (outputs) toevoegen.
    Heb je vragen over de outputresultaten?`
    const pageExampleMessages = ['Hoe kan ik resultaten verwijderen?', 'Hoe kan ik resultaten aanpassen?']
    const pageType = 'outputresults'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setExampleMessages(pageExampleMessages)
    }, [auth])

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Whenever Firestore data changes, update dragArray state
    useEffect(() => {
        setDragArrayResults(allResults);
        setDragArrayEvents(events);
    }, [results, events]);

    // DRAG & DROP: reorder items and update Firestore
    const handleDragEndResults = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(dragArrayResults);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);
    
        // Update local state to reflect the reorder immediately
        setDragArrayResults(items);

        console.log(items)
    
        // Now update Firestore with new positions
        items.forEach((item, idx) => {
          db.collection('Results')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });
    
        // Show "saved" icon
        setSaved('flex');
      };

      const handleDragEndResultsEvents = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(dragArrayEvents);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);
    
        // Update local state to reflect the reorder immediately
        setDragArrayEvents(items);

        console.log(items)
    
        // Now update Firestore with new positions
        items.forEach((item, idx) => {
          db.collection('Events')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });
    
        // Show "saved" icon
        setSaved('flex');
      };

    // Push results to all results state (if the compangny is a portfolio account the portfolio results are pushed to the all results state as well)
    const getLocalResults = () => {

        const array = []

        results && results.map(item => {
            const object = {
                id: item.ID,
                outputId: item.OutputID,
                date: item.Timestamp,
                result: item.Result,
                docid: item.docid,
                projectId: client,
                portfolio: false
            }
            array.push(object)
        })

        return array

    }

     // Push portfolio outputs
  const portfolioOutputs = async () => {

    // 1. Make portfolioOutputs an async function
    const array = [];

    // 2. Guard for empty or null syncs
    if (!syncs || syncs.length === 0) {
      return array;
    }

    // 3. Use for...of  with async
    for (const item of syncs) {
      const projectId = item.Project;
      const projectOutput = item.ProjectOutput;

      // 4. Await the Firestore call
      const querySnapshot = await db
        .collection("Results")
        .where("CompagnyID", "==", projectId)
        .where("OutputID", "==", projectOutput)
        .get();

      // 5. Process the documents
      querySnapshot.forEach((doc) => {
        const resultObject = {
          id: doc.data().ID,
          outputId: doc.data().OutputID,
          date: doc.data().Timestamp,
          result: doc.data().Result,
          docid: doc.id,
          projectId: projectId,
          portfolio: true,
        };

        array.push(resultObject);
      });
    }

    return array;
  };

  // 6. UseEffect to call portfolioOutputs and localArray and setAllResults
  useEffect(() => {
    const loadResults = async () => {
      // 1. Get the local results
      const localArray = getLocalResults();
  
      // 2. Get the portfolio results
      const portfolioArray = await portfolioOutputs();
  
      // 3. Merge them all into one array
      const combined = [...localArray, ...portfolioArray];

      // 4. sort array by date
        combined.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
  
      // 4. Now set state once
      setAllResults(combined);
    };
  
    loadResults();
  }, [results, syncs]);


    // Select activity
    const selectActivity = (e) => {
        const activityID = e.target.dataset.id

        setActivityID(activityID)
    }

    // Set default activity
    useEffect(() => {
        activities.length > 0 ? setActivityID(activities[0].ID) : setActivityID(null)
    }, [activities])


    // Update output result
    const resultHandler = (e) => {

        const docid = e.target.dataset.docid

        const value = e.target.value 

        db.collection('Results')
        .doc(docid)
        .update({
            Result: Number(value)
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Save date
    const saveDate = (e) => {

        const docid = e.target.dataset.docid

        const value = e.target.value 

        const date = new Date(value)

        const randomNumber = Math.floor(Math.random() * 1000) + 1;

        date.setSeconds(date.getSeconds() + randomNumber);

        db.collection('Results')
        .doc(docid)
        .update({
            Timestamp: date
        })
        .then(() => {
            setSaved('flex')
        })

    }


    // Add output result
    const addOutputResult = () => {

        const date = new Date()

        const randomNumber = Math.floor(Math.random() * 1000) + 1;

        date.setSeconds(date.getSeconds() + randomNumber);

        db.collection('Results')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: uuid(),
            Result: Number(0),
            Timestamp: date,
            OutputID: '',
            ActivityID: activityID
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Update selected output
    const selectedOutputHandler = (e) => {

        const docid = e.target.dataset.docid

        const value = e.target.options[e.target.selectedIndex].value

        db.collection('Results')
        .doc(docid)
        .update({
            OutputID: value
        })
        .then(() => {
            setSaved('flex')
        })


    }


     // Delete result
     const deleteResult = (e) => {

        const docid = e.target.dataset.docid

        db.collection('Results')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })


    }

    // Update event title
    const eventTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        const value = e.target.value 

        db.collection('Events')
        .doc(docid)
        .update({
            Title: value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Update event date
    const eventDateHandler = (e) => {

        const docid = e.target.dataset.docid

        const value = e.target.value 

        const date = new Date(value)

        const randomNumber = Math.floor(Math.random() * 1000) + 1;

        date.setSeconds(date.getSeconds() + randomNumber);

        db.collection('Events')
        .doc(docid)
        .update({
            Date: date
        })
        .then(() => {
            setSaved('flex')
        })

    }

    // Delete event
    const deleteEvent = (e) => {

        const docid = e.target.dataset.docid

        db.collection('Events')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })
            
    }  

  return (
    <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Activiteiten'}
                description={`Voeg hier de resultaten van de activiteiten toe. Klik op de activiteit om de resultaten toe te voegen en te bekijken.`}
                AI={false}
            />
            <div className='add-results-container'>
                <div className="select-activity-container select-activity-container-wizard">
                    <div className="select-activity-inner-container">
                    {activities && activities.map(item => (
                            <div key={item.ID} data-id={item.ID} data-title={item.Activity} className='activity-select-item-container' onClick={selectActivity} style={{backgroundColor: activityID === item.ID ? secundairyColor : 'white'}}>
                                <p data-id={item.ID} data-title={item.Activity} onClick={selectActivity} style={{color:  activityID === item.ID ? 'white' : '#616161'}}>{item.Activity}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='table-container dashboard-container'>
                    <h2>Gebeurtenis toevoegen</h2>
                    <table>
                        <thead>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}></th>
                                <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                                <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                                <th style={{backgroundColor: secundairyColor}}>OUTPUTS</th>
                                <th style={{backgroundColor: secundairyColor, display: persona}}>DEELNEMERS</th>
                                <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                                <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                            </tr>
                        </thead>
                        <DragDropContext onDragEnd={handleDragEndResultsEvents}>
                        <Droppable droppableId="events" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                        {events && events.map((item, index) => (
                        <Draggable
                        key={item.ID}
                        draggableId={item.ID}
                        index={index}
                    >
                        {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <td>
                                <DragIndicatorOutlinedIcon />
                            </td>
                            <td>
                                <input type="text" defaultValue={item.Title} data-docid={item.docid} onChange={eventTitleHandler} />
                            </td>
                            <td>
                                <input 
                                type="date" 
                                data-docid={item.docid} 
                                defaultValue={item.Date.toDate().toISOString().split('T')[0]}
                                onChange={eventDateHandler} 
                                />
                            </td>
                            <td>
                                <EventOutputs eventId={item.ID} />
                            </td>
                            <td>
                                <SelectPersonas event={item} />
                            </td>
                            <td>
                                <EditOutlinedIcon onClick={() => history.push(`/${client}/editevent/${item.ID}`)}/>
                            </td>
                            <td>
                                <img className='table-delete-icon' src={deleteIcon} alt="" data-docid={item.docid} onClick={deleteEvent}/>
                            </td>
                        </tr>
                         )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                    </DragDropContext>
                </table>
                    <AddItemTableRow toolTipContent={'Gebeurtenis toevoegen'} onClick={() => history.push(`/${client}/addevent/${activityID}`)}/>
                </div>
               
                <div className='table-container dashboard-container'>
                    <h2>Losse outputs toevoegen</h2>
                    <table>
                        <thead>
                            <tr>
                                <th style={{backgroundColor: secundairyColor}}></th>
                                <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                                <th style={{backgroundColor: secundairyColor}}>RESULTAAT</th>
                                <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                                <th style={{backgroundColor: secundairyColor, display: portfolio}}>PROJECT</th>
                                <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                            </tr>
                        </thead>
                        <DragDropContext onDragEnd={handleDragEndResults}>
                        <Droppable droppableId="results" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                        {allResults && allResults.map((item, index) => (
                        <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                    >
                        {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <td>
                                <DragIndicatorOutlinedIcon />
                            </td>
                            <td>
                                {item.outputId === '' ? 
                                
                                <select data-docid={item.docid} onChange={selectedOutputHandler}>
                                    <option value="">Selecteer output</option>
                                    {outputs && outputs.map(output => (
                                        <option value={output.ID}>{output.Title}</option>
                                    ))}
                                </select>
                                :
                                <OutputMeta id={item.outputId}/>
                                }
                                
                            </td>
                            <td>
                                <input id='add-result-input' type="number" data-docid={item.docid} defaultValue={item.result} onChange={resultHandler} />
                            </td>
                            <td>
                                <input type="date" defaultValue={item.date.toDate().toISOString().split('T')[0]} data-docid={item.docid} onBlur={saveDate} />
                            </td>
                            <td style={{display: portfolio}}>
                                <CompagnyMeta id={item.projectId}/>
                            </td>
                            <td>
                                <img className='table-delete-icon' src={deleteIcon} alt="" data-docid={item.docid} onClick={deleteResult}/>
                            </td>
                        </tr>
                        )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                    </DragDropContext>
                </table>
                    <AddItemTableRow toolTipContent={'Outputresultaat toevoegen'} onClick={addOutputResult}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OutputResults