import { useContext } from "react"
import { ActiveMenu } from "../../StateManagment/ActiveMenuItem";
import { useHistory } from "react-router"
import { client } from '../../hooks/Client';

const NextStepContainer = ({url, mainItem}) => {
    // Context
    const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu);

    // Hooks
    const history = useHistory()

  return (
    <div className="step-container">
        <button onClick={() => {
            history.push(`/${client}/${url}`);
            setActive(mainItem);
            setActiveSubItem(url)
        }}>
            Volgende stap
        </button>
    </div>
  )
}

export default NextStepContainer