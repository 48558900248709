import ScrollToTop from "../../hooks/ScrollToTop";
import Hostname from "../../hooks/Hostname";
import { HomeSystemMessage } from "../../hooks/impactAI/Prompts"
import { useContext, useEffect } from "react";
import { Auth } from '../../StateManagment/Auth';
import { client } from "../../hooks/Client"
import { useHistory } from "react-router-dom";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import strategyImage from '../../images/impactstrategy.png'
import measureplanImage from '../../images/measureplan.png'
import communicateImage from '../../images/communicate.png'
import TOCProgress from "../../hooks/TOCProgress";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import useSettings from "../../hooks/Settings";
import Face4OutlinedIcon from '@mui/icons-material/Face4Outlined';

const Home = () => {
  // Context
  const [auth] = useContext(Auth)
  const {
    systemMessage: [systemMessage, setSystemMessage],
    startMessage: [startMessage, setStartMessage],
    exampleMessages: [exampleMessages, setExampleMessages],
    chatOpen: [chatOpen, setChatOpen],
    type: [type, setType],
    hightlighted: [hightlighted, setHightlighted],
    hightlightText: [hightlightText, setHightlightText],
} = useContext(ImpactAI);

  // Hooks
  ScrollToTop()
  const host = Hostname()
  const history = useHistory()
  const progress = TOCProgress(client)
  const portfolio = useSettings().portfolio
  const personas = useSettings().personasOn
  const onboarding = useSettings().onboarding || false

  // ImpactAI
  const pageSystemMessage = HomeSystemMessage()
  const pageStartMessage = `Hallo ${auth?.ForName}, welkom bij ${host?.Name}. Ik ben Impact AI, jouw impact consultant. Ik sta altijd klaar 
  om je te helpen met de impactmanagementsoftware. 
  Ik ben hier om ervoor te zorgen dat je alles uit de software haalt wat erin zit. 
  Als je vragen hebt over hoe je iets moet doen, waar je iets kunt vinden of als je gewoon wat hulp nodig hebt, 
  ben ik er voor je. Waar kan ik je mee helpen? 😊`
  const pageExampleMessages = ['Ik ben nieuw. Waar begin ik?']

  useEffect(() => {
    setSystemMessage(pageSystemMessage)
    setStartMessage(pageStartMessage)
    setExampleMessages(pageExampleMessages)
    setType('welcome')
    setChatOpen(false)
}, [auth, host])

// Reset hightlights
useEffect(() => {
  setHightlighted(false)
  setHightlightText('')
},[])

  return (
    <div className="main">
    <div className="main-container">
        <div className="page-header">
          <h1>Welkom bij {host?.Name}, {auth?.ForName}</h1>
        </div>

        {onboarding &&
          <div id='onboarding-container'>
            <div id='onboarding-inner-container'>
              <Face4OutlinedIcon id='home-decca-icon'/>
              <h2>Hallo {auth?.ForName}, mijn naam is Decca en ik leid je graag rond in de software.</h2>
              <p>Zal ik je rondleiding geven?</p>
              <p>Ik zit verstopt in de balk aan de rechterkant van je scherm.</p>
              <button onClick={() => setChatOpen(true)}>Klik hier om mij te openen</button>
            </div>
          </div>
        }

        <div className="home-quick-start-container">

          <div className='impact-guide-container' id='home-startegy-container'>
            <div className="home-start-image-container">
              <img src={strategyImage} alt="" />
            </div>
            <div className="home-section-explainer-container">
              <h1>Impactstrategie</h1>
              <p>Je impactstrategie is de basis van je impactmanagement proces.</p>
            </div>
            <div className="home-cta-container" id='home-strategy-cta-container' onClick={() => history.push(`/${client}/impactstrategy`)}>
                <PlayArrowOutlinedIcon/>
              </div>
          </div>

          <div className="home-element-title-container">
            <h2>Modules</h2>
          </div>


          <div className='impact-guide-container'>
            <div className="home-start-image-container">
              <img src={measureplanImage} alt="" />
            </div>
            <div className="home-section-explainer-container">
              <h1>Meten</h1>
              <p>Meet en analyseer de impact van je activiteiten.</p>
            </div>
            <div className="home-cta-container" onClick={() => progress < 100 ? null :  history.push(`/${client}/measurementplan`)}>
                {console.log(progress)}
                {progress < 100 ? <LockOutlinedIcon/> : <PlayArrowOutlinedIcon/>}
              </div>
          </div>

          <div className='impact-guide-container'>
            <div className="home-start-image-container">
              <img src={communicateImage} alt="" />
            </div>
            <div className="home-section-explainer-container">
              <h1>Communiceren</h1>
              <p>Communiceer je impact aan je stakeholders aan de hand van op maat gemaakte live-rapportages.</p>
            </div>
            <div className="home-cta-container" onClick={() => progress < 100 ? null : history.push(`/${client}/communication`)}>
                {progress < 100 ? <LockOutlinedIcon/> : <PlayArrowOutlinedIcon/>}
            </div>
          </div>

          {personas === 'flex' &&
            <div className='impact-guide-container'>
              <div className="home-start-image-container">
                <img src={communicateImage} alt="" />
              </div>
              <div className="home-section-explainer-container">
                <h1>Personas</h1>
                <p>Meet impact op individueel niveau.</p>
              </div>
              <div className="home-cta-container" onClick={() => progress < 100 ? null : history.push(`/${client}/personasexplainer`)}>
                {progress < 100 ? <LockOutlinedIcon/> : <PlayArrowOutlinedIcon/>}
              </div>
            </div>
          }

          {portfolio === 'block' &&
            <div className='impact-guide-container'>
              <div className="home-start-image-container">
                <img src={communicateImage} alt="" />
              </div>
              <div className="home-section-explainer-container">
                <h1>Portfolio</h1>
                <p>Meet de impact van je portfolio projecten</p>
              </div>
              <div className="home-cta-container" onClick={() => progress < 100 ? null : history.push(`/${client}/personasexplainer`)}>
                {progress < 100 ? <LockOutlinedIcon/> : <PlayArrowOutlinedIcon/>}
              </div>
            </div>
          }

        </div>
      </div>

    </div>
  )
}

export default Home