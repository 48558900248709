import { useFirestoreNoCompagnyGeneralOrderBy } from '../../firebase/useFirestore'

const ProjectResearchProgress = ({projectId}) => {
  // Hooks
  const options = { day: 'numeric' ,month: 'numeric', year: 'numeric'};

  // Firestore
  const responses = useFirestoreNoCompagnyGeneralOrderBy('QuestionnairesResponses', 'CompagnyID', projectId ? projectId : '', 'Timestamp', 'desc')

  // Last result
  const lastResult = responses[responses.length - 1]
  const lastResultDate = lastResult?.Timestamp.toDate().toLocaleDateString("nl-NL", options)
  const daysAgo = Math.floor((new Date() - lastResult?.Timestamp.toDate()) / (1000 * 60 * 60 * 24))

return (
  <div>
      <p style={{color: responses.length === 0 ? 'red' : 'green'}}>Aantal: {responses.length}</p>
      {responses.length > 0 ? <p style={{color: daysAgo > 30 ? 'red' : 'green'}}>Laatste: {lastResultDate}</p> : '-'}
  </div>
)
}

export default ProjectResearchProgress