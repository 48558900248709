import Fields from './Fields'
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import useTranslations from "../../hooks/useTranslations";
import { useMemo } from 'react';

const Section = ({field, index, setFieldIndex, fieldIndex, compagnyId, questionaire, languageCode}) => {

    const sectionFields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'SectionID', field.ID, 'Position', 'asc')

    // Translations
    const stringsToTranslate = useMemo(() => {
      if (!sectionFields) return {};
  
      return {
          "Terug": "Terug",
          "Volgende": "Volgende",
          ...(sectionFields ? Object.fromEntries(sectionFields.map(field => [field.Question, field.Question])) : {}),
      };
    }, [sectionFields]);

    const { translations, loading } = useTranslations(stringsToTranslate, languageCode ? languageCode : 'nl');

  return (
    <div id='section-outer-container' style={{display: index !== fieldIndex ? 'none' : 'block' }}>
      <div id='section-container' style={{borderLeft: `5px solid ${field.Color}`, display: index !== fieldIndex ? 'none' : 'flex' }} >
          <h2>{translations?.[field.Question] || field.Question}</h2>
          {sectionFields && sectionFields.map((field, index) => (
              <Fields 
              field={field} 
              compagnyId={compagnyId} 
              questionaire={questionaire}
              languageCode={languageCode}
              />
          ))}
          
      </div>
      <div className="questionnaire-button-container">
        <button onClick={() => setFieldIndex(fieldIndex - 1)}>{translations?.["Terug"] || "Terug"}</button>
        <button onClick={() => setFieldIndex(index + 1)}>{translations?.["Volgende"] || "Volgende"}</button>
      </div>
    </div>
  )
}

export default Section