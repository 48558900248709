import Tooltip from '../../components/common/Tooltip'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import deleteIcon from '../../images/icons/delete-icon.png'
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import { useContext } from "react";
import useSettings from "../../hooks/Settings";
import settingsIcon from "../../images/icons/settings-icon.png";
import { useHistory } from "react-router-dom";
import AddItemTableRow from '../../components/common/AddItemTableRow'
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { useState, useEffect } from 'react';

const Goals = ({personaNavigation}) => {
      // Context
      const [saved, setSaved] = useContext(SavedIcon)

      // State
       const [dragArray, setDragArray] = useState([]);

    // Hooks
    const secundairyColor = useSettings().SecundairyColor
    const personaId = Location()[3]
    const history = useHistory()

    // Firestore
    const goals = useFirestoreGeneralOrderBy('PersonaGoals', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')

    // Whenever Firestore data changes, update dragArray state
    useEffect(() => {
        setDragArray(goals);
    }, [goals]);

    // DRAG & DROP: reorder items and update Firestore
    const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArray);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArray(items);

    // Now update Firestore with new positions
    items.forEach((dragArray, idx) => {
        db.collection('PersonaGoals')
        .doc(dragArray.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

    // Show "saved" icon
        setSaved('flex');
    };

    // Add new goal
    const addGoal = () => {

        db.collection('PersonaGoals')
        .add({
            ID: uuid(),
            PersonaID: personaId,
            Goal: '',
            Description: '',
            CompagnyID: client,
            Compagny: client,
            Position: goals.length + 1,
            Timestamp: timestamp
        })

    }

    // Delete goal
    const deleteGoal = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaGoals')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved('flex')
        })

    }

    // Update goal title
    const goalTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaGoals')
        .doc(docid)
        .update({
            Goal: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }

    const goalDescriptionHanlder = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaGoals')
        .doc(docid)
        .update({
            Description: e.target.value
        })
        .then(() => {
            setSaved('flex')
        })

    }


  return (
    <div style={{display: personaNavigation === 'goals' ? 'block' : 'none'}}>
        {/* <h2>Doelen</h2> */}
        <div  className="persona-option-container">            
            <div className='table-container'>
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}></th>
                            <th style={{backgroundColor: secundairyColor}}>DOEL</th>
                            <th style={{backgroundColor: secundairyColor}}>VOORTGANG</th>
                            <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="goals" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            > 
                    {goals && goals.map((goal, index) => (
                         <Draggable
                         key={goal.ID}
                         draggableId={goal.ID}
                         index={index}
                     >
                         {(provided, snapshot) => (
                         <tr
                             ref={provided.innerRef}
                             {...provided.draggableProps}
                             {...provided.dragHandleProps}
                         >
                             <td>
                                 <DragIndicatorOutlinedIcon />
                             </td>
                            <td>
                                <Tooltip content={goal.Goal} top='-30px' width={'100%'}>
                                    <input type="text" placeholder='Schrijf hier de titel van het doel' data-docid={goal.docid} defaultValue={goal.Goal} onChange={goalTitleHandler} />
                                </Tooltip>
                            </td>
                            <td>
                                <Tooltip content={'Voortgang bekijken'} top='-40px'>
                                    <EqualizerOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/personagoalprogress/${personaId}/${goal.ID}`)}/>
                                </Tooltip>
                            </td>
                            <td>
                                <Tooltip content={'Doel instellingen bekijken'} top='-40px'>
                                    <EditOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/personagoaldetail/${personaId}/${goal.ID}`)}/>
                                </Tooltip>
                            </td>
                            <td>
                                <Tooltip content={'Goal verwijderen'} top='-60px'>
                                    <img className='table-delete-icon' data-docid={goal.docid} onClick={deleteGoal} src={deleteIcon} alt="" />
                                </Tooltip>
                            </td>
                            </tr>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                    </DragDropContext>
                </table>
                <AddItemTableRow toolTipContent={'Doel toevoegen'} onClick={addGoal} />
            </div>
        </div>
    </div>
  )
}

export default Goals