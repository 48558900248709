import Location from "../../hooks/Location"
import { useFirestoreID, useFirestoreGeneralOrderBy } from "../../firebase/useFirestore"
import { useState, useEffect } from "react";
import useSettings from "../../hooks/Settings";
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import { GoogleLogin } from '@react-oauth/google';
import firebase from "firebase";

const ImportResults = () => {
    // States
    const [importType, setImportType] = useState('google');

    // Hooks
    const researchId = Location()[3];
    const secundairyColor = useSettings().SecundairyColor;

    // Firestore
    const research = useFirestoreID('Research', researchId ? researchId : '');
    const measureMoments = useFirestoreGeneralOrderBy('MeasureMoments', 'ResearchID', researchId ? researchId : '', 'Position', 'asc');

    const handleLoginSuccess = async (credentialResponse) => {
        // 1. The 'credential' is actually the authorization code now
        const authorizationCode = credentialResponse.credential;
      
        if (!authorizationCode) {
          console.error('No authorization code received');
          return;
        }
      
        try {
          // 2. Call a function on your backend that exchanges this code for access tokens
          const exchangeAuthCode = firebase.functions().httpsCallable('exchangeAuthCode');
          const result = await exchangeAuthCode({ code: authorizationCode });
      
          // 'result.data' should have: { access_token, refresh_token, ... }
          const accessToken = result.data.access_token;
      
          if (!accessToken) {
            console.error('No access token returned from backend');
            return;
          }
      
          // 3. Now we can call the Drive API with a valid access token
          const response = await fetch(
            "https://www.googleapis.com/drive/v3/files?q=mimeType='application/vnd.google-apps.form'",
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
      
          const data = await response.json();
          console.log("Forms from Drive:", data.files);
        } catch (error) {
          console.error('Error exchanging auth code or fetching Drive data', error);
        }
      };
      


    const handleLoginError = () => {
        console.log('Login Failed');
    };


    // db.collection('QuestionnaireFields')
    //     .doc()
    //     .set({
    //         Compagny: client,
    //         CompagnyID: client,
    //         ID: uuid(),
    //         Timestamp: timestamp,
    //         QuestionnaireID: route,
    //         Type: 'paragraph',
    //         Question: 'Vraag ' + position,
    //         Explainer: '',
    //         ReachStart: 0,
    //         ReachStartLable: '',
    //         ReachEnd: 5,
    //         ReachEndLabel: '',
    //         Key: uuid(),
    //         Position: position,
    //         Multiple: [],
    //         SectionID: '',
    //         LiveReportHidden: false,
    //     })

    // db.collection('QuestionnairesResponses')
    //     .doc()
    //     .set({
    //     FieldID: response.FieldID,
    //     Input: response.Input,
    //     Timestamp: timestamp,
    //     CompagnyID: client,
    //     QuestionannaireID: questionaire.ID,
    //     MomentID: moment,
    //     ResearchID: research,
    //     FormID: formID,
    //     ID: uuid(),
    //     OptionType: response.OptionType ? response.OptionType : '',
    //     Persona: persona ? persona : '',
    //     RowTitle: response.RowTitle ? response.RowTitle : '',
    //     FieldType: response.FieldType ? response.FieldType : '',
    //     ColumnID: response.ColumnID ? response.ColumnID : '',
    // })

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>Resultaten importeren</h1>
                </div>
                <div className="select-activity-container select-activity-container-wizard">
                    <div className="select-activity-inner-container">
                        <div
                            className='activity-select-item-container'
                            onClick={() => setImportType('google')}
                            style={{ backgroundColor: importType === 'google' ? secundairyColor : 'white' }}
                        >
                            <p style={{ color: importType === 'google' ? 'white' : '#616161' }}>Google Forms</p>
                        </div>
                        <div
                            className='activity-select-item-container'
                            onClick={() => setImportType('microsoft')}
                            style={{ backgroundColor: importType === 'microsoft' ? secundairyColor : 'white' }}
                        >
                            <p style={{ color: importType === 'microsoft' ? 'white' : '#616161' }}>Microsoft Forms</p>
                        </div>
                    </div>
                </div>
                {importType === 'google' &&
                    <div className='dashboard-container'>
                        <h2>Google Forms</h2>
                        <GoogleLogin
                        onSuccess={handleLoginSuccess}
                        onError={handleLoginError}
                        useOneTap
                        scope="https://www.googleapis.com/auth/drive.readonly"
                        flow="auth-code" // <--- Important: instructs library to get an auth code
                        />
                    </div>
                }

                {importType === 'microsoft' &&
                    <div className='table-container dashboard-container'>
                        <h2>Microsoft Forms</h2>
                        <div id="research-results-import-button-container">
                            <ConstructionOutlinedIcon />
                            <p>Wordt aan gewerkt</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ImportResults;
