import { ActiveMenu } from "../../StateManagment/ActiveMenuItem";
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { PersonasExplainerSystemMessage } from "../../hooks/impactAI/Prompts"
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"

const PersonasExplainer = () => {
 // Context
 const [auth] = useContext(Auth)
 const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu);
 const {
     type: [type, setType],
     systemMessage: [systemMessage, setSystemMessage],
     startMessage: [startMessage, setStartMessage],
     exampleMessages: [exampleMessages, setExampleMessages],
     chatOpen: [chatOpen, setChatOpen]
 } = useContext(ImpactAI);

 // Hooks
 const history = useHistory()

 //ImpactAI
 const pageSystemMessage = PersonasExplainerSystemMessage()
 const pageStartMessage = `Welkom bij Personas, ${auth?.ForName}. 
 Kan ik iets voor je verduidelijken?`
 const pageExampleMessages = ['Wat zijn personas?']
 const pageType = 'personasExplainer'

 useEffect(() => {
    setType(pageType)
    setSystemMessage(pageSystemMessage)
    setStartMessage(pageStartMessage)
    setExampleMessages(pageExampleMessages)
}, [auth])

const segmentSteps = [
  {
    name: 'Deelnemers',
    url: 'personas'
  },
  {
    name: 'Planning',
    url: 'agenda'
  },
  {
    name: 'Instellingen',
    url: 'personasettings'
  }
]

 return (
  <div className="main">
      <div className="main-container">
          <div className="page-header">
              <GroupsOutlinedIcon/>
              <h1>Deelnemers</h1>
          </div>
          <div className='section-explainer-text-container'>
            <div>
              <p>Welkom bij de deelnemers, {auth?.ForName}. Hier kun je deelnemers volgen en hun impact meten.</p>
            </div>
            <div id='segment-overview-steps-container'>
              {segmentSteps.map((step, index) => (
                <div 
                key={index} 
                className="segment-overview-step-container"
                onClick={() => {
                  history.push(`/${client}/${step.url}`);
                  setActive('personas');
                  setActiveSubItem(step.url)
                }}
                >
                  <div className="segment-overview-step-inner-container">
                    <div className="segment-overview-checked-name-container">
                      <div>
                        <p><b>{step.name}</b></p>
                      </div>
                    </div>
                    <p id='segment-overview-step-button'>Bekijk/aanpassen</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Breadcrumb
          section={'Personas'}
          previousPage={'Home'}
          previousPageUrl={'documentation'}
          nextPage={'Personas overzicht'}
          nextPageUrl={'personas'}
          counter={0}
          totalSteps={1}
          />
        </div>
      </div>
  )
}

export default PersonasExplainer