import React from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  LineChart,
  Line,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import spinner from '../../../images/spinner-ripple.svg';

// Custom tooltip component that displays the date, total, and project breakdown.
const CustomTooltip = ({ active, payload, label, portfolio }) => {
  if (active && payload && payload.length) {
    // Retrieve the full data row from the first payload element.
    const rowData = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: 'white',
          border: '1px solid #ccc',
          padding: 10,
          borderRadius: 4,
        }}
      >
        <h2 style={{ margin: 0, fontWeight: 'bold' }}>{label}</h2>
        {Object.keys(rowData).map((key) => {
          if (key === 'name') return null; // Skip the date field
          const outputData = rowData[key];
          if (!outputData) return null;
          return (
            <div key={key} style={{ marginTop: 5 }}>
              <div>
                <strong>{key}</strong>: {outputData.total}
              </div>
              {/* Conditionally render projects info only if portfolio !== 'none' */}
              {portfolio !== 'none' && (
                <div style={{ fontSize: 12, marginLeft: 10 }}>
                  {Object.keys(outputData.projects).map((projKey) => (
                    <p key={projKey} style={{ marginRight: 8 }}>
                      {projKey}: {outputData.projects[projKey]}
                    </p>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
  return null;
};

const CustomLegend = ({ payload, growth }) => {
  if (!payload) return null;
  return (
    <ul
      style={{
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {payload.map((entry, index) => {
        // entry.value should now contain the output title (provided via the name prop)
        const title = entry.value || '';
        const growthValue =
          growth && growth[title] !== undefined ? growth[title] : 0;
        return (
          <li key={`legend-item-${index}`} style={{ marginRight: 10 }}>
            <span
              style={{
                display: 'inline-block',
                width: 10,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
              }}
            ></span>
            {title}: {growthValue >= 0 ? '+' : ''}
            {growthValue.toFixed(1)}%
          </li>
        );
      })}
    </ul>
  );
};

const OutputMultiLineGraph = ({ data, isLoading, outputs, graphType, growth, height, portfolio }) => {
  // Retrieve the latest data row so we can display current totals in the legend.
  const latestData = data && data.length ? data[data.length - 1] : {};

  return (
    <>
      <h3>
        Gemiddelde groei:{' '}
        {growth?.average && growth?.average > 0 ? '+' : ''}
        {`${growth?.average?.toFixed(1)}%`}
      </h3>
      {isLoading ? (
        <div id="graph-loading-container">
          <img src={spinner} alt="spinner" className="spinner" />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={height}>
          {graphType === 'bar' ? (
            <BarChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              {/* Use the custom tooltip */}
              <Tooltip content={<CustomTooltip portfolio={portfolio} />} />
              <Legend
                content={
                  <CustomLegend
                    growth={growth?.perOutput}
                    latestData={latestData}
                  />
                }
              />
              {outputs &&
                outputs.map((output, index) => (
                  <Bar
                    key={`bar-${index}`}
                    name={output.Title}
                    dataKey={(row) =>
                      row[output.Title] ? row[output.Title].total : 0
                    }
                    fill={output.Color}
                    activeBar={
                      <Rectangle fill={output.Color} stroke={output.Color} />
                    }
                  />
                ))}
            </BarChart>
          ) : (
            <LineChart
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip portfolio={portfolio} />} />
              <Legend
                content={
                  <CustomLegend
                    growth={growth?.perOutput}
                    latestData={latestData}
                  />
                }
              />
              {outputs &&
                outputs.map((output, index) => (
                  <React.Fragment key={`line-${index}`}>
                    <Line
                      type="monotone"
                      name={output.Title}
                      dataKey={(row) =>
                        row[output.Title] ? row[output.Title].total : 0
                      }
                      stroke={output.Color}
                      strokeWidth="3"
                    />
                  </React.Fragment>
                ))}
            </LineChart>
          )}
        </ResponsiveContainer>
      )}
    </>
  );
};

export default OutputMultiLineGraph;



