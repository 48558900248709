import Location from "../../hooks/Location"
import { useState, useContext } from "react"
import { SavedIcon } from "../../StateManagment/SavedIcon"
import useSettings from "../../hooks/Settings";
import firebase from "firebase";
import { client } from "../../hooks/Client";

const Evaluations = ({ personaNavigation, item, courseStartDate, courseEndDate }) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [loading, setLoading] = useState('')
    const [startDate, setStartDate] = useState('2000-01-01');
     const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
     const [analysis, setAnalysis] = useState([])
     const [prompt, setPrompt] = useState('')
     const [costs, setCosts] = useState(0)
    
    // Hooks
    const personaId = Location()[3]
    const secundairyColor = useSettings().SecundairyColor
 
     // Start ananlyse
     const createProgressAnalysis = async () => {
         try {
             setLoading(true)
             setCosts(0)
     
             const personaProgressAnalysis  = firebase.functions().httpsCallable('personaProgressAnalysis ')  
 
             const data = {
                 startDate: startDate,
                 endDate: endDate,
                 companyId: client,
                 prompt: prompt,
                 personaId: personaId
             }
     
             const result = await personaProgressAnalysis ({data})
     
             console.log(result)
     
             const answer = result.data.message 
             const costs = result.data.costs
     
             setAnalysis(answer)
             setCosts(costs)
 
             setLoading(false)
     
         } catch (error) {
             console.log(error.message)
             setAnalysis('Er is iets fout gegaan. Probeer het opnieuw.')
             setLoading(false)
         }
     }



  return (
    <div style={{ display: personaNavigation === 'evaluations' ? 'block' : 'none' }}>

        <div className="table-container dashboard-container">
            <h2>Doelen rapportage</h2>
                <button onClick={createProgressAnalysis}>Creëer analyse</button>
                <div className='insights-analysis-content-container'>
                    <div>
                        <p>(Kosten: {costs.toFixed(4)} euro)</p>
                    </div>
                    <div>
                        {loading ? <p>Loading...</p> : <p dangerouslySetInnerHTML={{__html: analysis}}></p>}
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Evaluations