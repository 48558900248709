import Section from "./Section";
import TopLevelFields from "./TopLevelFields";


const QuestionnaireField = ({
    questionaire,
    topLevelFields,
    setFieldIndex,
    fieldIndex,
    compagnyId,
    languageCode,
}) => {

    return (
        <div id='questionnaire-fields-container'>
            {topLevelFields &&
                topLevelFields.map((field, index) => (
                    <div key={field.ID} id='questionnaire-field-section-item-container'>
                        {field.Type === 'section' && (
                            <Section
                                field={field}
                                index={index}
                                setFieldIndex={setFieldIndex}
                                fieldIndex={fieldIndex}
                                compagnyId={compagnyId}
                                questionaire={questionaire}
                                languageCode={languageCode}
                                topLevelFields={topLevelFields}
                            />
                        )}
                        {field.Type !== 'section' && (
                            <TopLevelFields
                                field={field}
                                index={index}
                                setFieldIndex={setFieldIndex}
                                fieldIndex={fieldIndex}
                                compagnyId={compagnyId}
                                questionaire={questionaire}
                                languageCode={languageCode}
                                topLevelFields={topLevelFields}
                            />
                        )}
                    </div>
                ))}
        </div>
    );
};

export default QuestionnaireField;
