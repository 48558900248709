import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect, useState } from "react";
import { ImpactstrategySystemMessage } from "../../hooks/impactAI/Prompts"
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"
import { useFirestore, useFirestoreOrderBy, useFirestoreTimestamp } from "../../firebase/useFirestore";
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { ActiveMenu } from "../../StateManagment/ActiveMenuItem";

const Impactstrategy = () => {
  // Context
  const [auth] = useContext(Auth)
  const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu);
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen]
  } = useContext(ImpactAI);

  // Hooks
  const history = useHistory()

  // Firestore
  const sourceData = useFirestore('SourceData')
  const centralProblem = useFirestore('CentralProblem')
  const stakeholders = useFirestoreOrderBy('Stakeholders', 'Position', 'asc')
  const goals = useFirestoreTimestamp("Goals")
  const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')
  const outputs = useFirestoreOrderBy('Outputs', 'Position', 'asc')
  const effects = useFirestoreOrderBy('OutputEffects', 'Position', 'asc')

  //ImpactAI
  const pageSystemMessage = ImpactstrategySystemMessage()
  const pageStartMessage = `Welkom bij de impactstrategie, ${auth?.ForName}. De impactstrategie is de basis van je impactmanagement.
  Heb je vragen over de impactstrategie?`
  const pageExampleMessages = ['Wat is een impactstrategie?', 'Wat is een Theory of Change?']
  const pageType = 'impactstrategy'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])

  const theoryOfChangeComplete = () => {
    if (centralProblem && centralProblem[0].CentralProblem === '' && stakeholders && stakeholders.length === 0 && goals && goals[0].Title === '' && activities && activities.length === 0 && outputs && outputs.length === 0 && effects && effects.length === 0) {
      return false
    } else {
      return true
    }
  }

  console.log(sourceData[0])



  const segmentSteps = [
    {
      name: 'Organisatie omschrijving',
      info: `Omschrijf je organisatie kort en krachtig. 
      Deze informatie wordt gebruikt door impactAI om je te helpen bij het formuleren van je impactstrategie`,
      completed: sourceData && sourceData[0].Summary === '' || sourceData && sourceData[0].Summary === undefined ? false : true,
      url: 'organisationcontext',
      icon: ContentPasteOutlinedIcon
    },
    {
      name: 'Maatschappelijk probleem',
      info: 'Formuleer het maatschappelijk probleem dat je wilt oplossen',
      completed: centralProblem && centralProblem[0].CentralProblem === '' ? false : true,
      url: 'problemanalysis'
    },
    {
      name: 'Doelgroepen',
      info: 'Formuleer wie je doelgroep(en) is/zijn',
      completed: stakeholders && stakeholders.length === 0 ? false : true,
      url: 'stakeholders'
    },  
    {
      name: 'Impactdoel',
      info: 'Formulier het impactdoel dat je wilt bereiken',
      completed: goals && goals[0].Title === '' ? false : true,
      url: 'Goals'
    },
    {
      name: 'Activiteiten',
      info: 'Formuleer welke activiteiten je uitvoert om het impactdoel te bereiken',
      completed: activities && activities.length === 0 ? false : true,
      url: 'Activities'
    },
    {
      name: 'Outputs',
      info: 'Formuleer welke outputs (kwantitatieve impact) je gaat bijhouden',
      completed: outputs && outputs.length === 0 ? false : true,
      url: 'Outputs'
    },
    {
      name: 'Effecten',
      info: 'Formuleer welke effecten (kwalitatieve impact) je wilt bereiken voor je doelgroep',
      completed: effects && effects.length === 0 ? false : true,
      url: 'effects'
    },
    // {
    //   name: 'Theory of Change',
    //   info: 'Hier vind je de visuele weergave van je impactstrategie',
    //   completed: theoryOfChangeComplete(),
    //   url: 'theoryofchange'
    // }
  ]

  return (
    <div className="main">
      <div className="main-container">
          <div className="page-header">
              <BarChartOutlinedIcon/>
              <h1>Impactstrategie</h1>
          </div>
        <div className='section-explainer-text-container'>
          <div>
            <p>Welkom bij de impactstrategie (ook wel de Theory of Change genoemd). Dit is de eerste stap in 
              het impactmanagementproces en tevens de basis voor het meetplan en de communicatie.</p>
            <p>
              De impactstrategie is een visuele weergave van de impact die je wilt maken. Het is een reis die begint met het maatschappelijke probleem dat je wilt oplossen.
              Vervolgens doorloop je de stappen die nodig zijn om tot de gewenste maatschappelijke effecten (impact) te komen.
            </p>
          </div>
          <div id='segment-overview-steps-container'>
            {segmentSteps.map((step, index) => (
              <div key={index} className="segment-overview-step-container">
                <div 
                  className="segment-overview-step-inner-container"
                  onClick={() => {
                    history.push(`/${client}/${step.url}`);
                    setActive('impactstrategy');
                    setActiveSubItem(step.url)
                  }}
                  >
                  <div className="segment-overview-checked-name-container">
                    {/* {step.completed ? <CheckCircleOutlineOutlinedIcon style={{color: '#00A96D'}}/> : <RadioButtonUncheckedOutlinedIcon style={{color: '#00A96D'}}/>} */}
                    <div>
                      <p id='segment-overview-step-index'>Stap {index + 1}</p>
                      <p style={{color: step.completed ? '#00A96D' : 'gray'}}><b>{step.name}</b></p>
                    </div>
                  </div>
                  <p id='segment-overview-step-button'>{step.completed ? <EditOutlinedIcon/> : <PlayArrowOutlinedIcon/>}</p>
                </div>

              </div>

            ))}
          </div>
        </div>
        <Breadcrumb
        section={'Impactstrategie'}
        previousPage={'Home'}
        previousPageUrl={'home'}
        nextPage={'Organisatie context'}
        nextPageUrl={'organisationcontext'}
        counter={0}
        totalSteps={8}
        />
      </div>
    </div>
  )
}

export default Impactstrategy