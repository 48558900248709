import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import { v4 as uuid } from 'uuid';
import { useState, useEffect, useContext } from "react";
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

import { db, timestamp } from "../../firebase/config";
import { useFirestoreOrderBy } from "../../firebase/useFirestore";
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";

import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { client } from "../../hooks/Client";

import deleteIcon from '../../images/icons/delete-icon.png';
import spinner from '../../images/spinner-ripple.svg';

import PageHeader from "../../components/layout/PageHeader.jsx";
import PreviousStepContainer from "../../components/common/PreviousStepContainer";
import NextStepContainer from "../../components/common/NextStepContainer.jsx";
import Tooltip from "../../components/common/Tooltip";
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import SavedNotice from "../../components/common/SavedNotice";
import DeleteModal from "../../components/common/DeleteModal";
import {
  StakeholdersSystemMessage,
  StakeholdersToolCallContent,
  StakeholdersSaveMessage
} from "../../hooks/impactAI/Prompts";
import targetgroupCategories from "../../hooks/impactAI/TargetgroupCategories";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Stakeholders = () => {
  // Context
  const [saved, setSaved] = useContext(SavedIcon);
  const [auth] = useContext(Auth);
  const {
    type: [type, setType],
    systemMessage: [systemMessage, setSystemMessage],
    toolCallContent: [toolCallContent, setToolCallContent],
    collection: [collection, setCollection],
    docid: [docid, setDocid],
    field: [field, setField],
    saveMessage: [saveMessage, setSaveMessage],
    startMessage: [startMessage, setStartMessage],
    parentId: [parentId, setParentId],
    exampleMessages: [exampleMessages, setExampleMessages],
    chatOpen: [chatOpen, setChatOpen],
    hightlighted: [hightlighted, setHightlighted],
    hightlightText: [hightlightText, setHightlightText]
  } = useContext(ImpactAI);

  // Local state
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteDocid, setDeleteDocid] = useState('');
  const [deleteName, setDeleteName] = useState('');
  const [openAddTargetgroupModal, setOpenAddTargetgroupModal] = useState(false);
  const [targetgroupName, setTargetgroupName] = useState('');
  const [targetgroupCategorie, setTargetgroupCategorie] = useState('');
  const [loading, setLoading] = useState(false);
  const [dragArray, setDragArray] = useState([]);

  // Firestore
  const stakeholdersFirestore = useFirestoreOrderBy('Stakeholders', 'Position', 'asc');

  // Keep table color from settings
  const secundairyColor = useSettings().SecundairyColor;

  // Impact AI lines (systemMessage, etc.)
  const pageSystemMessage = StakeholdersSystemMessage();
  const pageToolCallContent = StakeholdersToolCallContent();
  const pageSaveMessage = StakeholdersSaveMessage();
  const pageStartMessage = `Hoi, ${auth?.ForName}. In deze stap gaan we bepalen wie jullie doelgroep is.
    Zal ik een voorstel doen?`;
  const pageExampleMessages = [];
  const pageCollection = 'Stakeholders';
  const pageField = 'Organisation';
  const pageParentId = '';
  const pageType = 'stakeholder';

  // For router
  ScrollToTop();
  const history = useHistory();

  // Modal settings
  Modal.setAppElement('#root');
  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  // On mount, push relevant data to ImpactAI context
  useEffect(() => {
    setType(pageType);
    setSystemMessage(pageSystemMessage);
    setStartMessage(pageStartMessage);
    setToolCallContent(pageToolCallContent);
    setSaveMessage(pageSaveMessage);
    setCollection(pageCollection);
    setField(pageField);
    setParentId(pageParentId);
    setExampleMessages(pageExampleMessages);
  }, [auth]);

  // Whenever Firestore data changes, update dragArray state
  useEffect(() => {
    setDragArray(stakeholdersFirestore);
  }, [stakeholdersFirestore]);

  // Reset highlights
  useEffect(() => {
    setHightlighted(false);
    setHightlightText('');
  }, []);

  // DRAG & DROP: reorder items and update Firestore
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArray);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArray(items);

    // Now update Firestore with new positions
    items.forEach((item, idx) => {
      db.collection('Stakeholders')
        .doc(item.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

    // Show "saved" icon
    setSaved('flex');
  };

  // ========== CRUD handlers ==========

  // Opens the delete modal
  const deleteModalHandler = (e) => {
    const docid = e.target.dataset.docid;
    const delName = e.target.dataset.deletename;

    setDeleteDocid(docid);
    setDeleteName(delName);
    setDeleteModal(true);
  };

  // Actually deletes one stakeholder doc
  const deleteStakeholder = () => {
    db.collection('Stakeholders')
      .doc(deleteDocid)
      .delete()
      .then(() => {
        setDeleteModal(false);
        setSaved('flex');
      });
  };

  // Add a new stakeholder
  const addStakeholder = () => {
    const targetgroupID = uuid();
    const docRef = db.collection('Stakeholders').doc();

    docRef.set({
      docid: docRef.id, // Store docRef.id so we know how to update it later
      Organisation: targetgroupName,
      Categorie: targetgroupCategorie,
      ID: targetgroupID, // your own custom ID if needed
      Compagny: client,
      CompagnyID: client,
      Timestamp: timestamp,
      Position: stakeholdersFirestore.length + 1
    })
    .then(() => {
      setOpenAddTargetgroupModal(false);
      setSaved('flex');
    });
  };

  // Edit name
  const editNameHandler = (e) => {
    const docid = e.target.dataset.docid;
    const name = e.target.value;

    db.collection('Stakeholders')
      .doc(docid)
      .update({ Organisation: name })
      .then(() => setSaved('flex'));
  };

  // Edit category
  const editCategoryHandler = (e) => {
    const docid = e.target.dataset.docid;
    const categorie = e.target.value;

    db.collection('Stakeholders')
      .doc(docid)
      .update({ Categorie: categorie })
      .then(() => setSaved('flex'));
  };

  // Input change for new stakeholder name
  const nameHandler = (e) => {
    setTargetgroupName(e.target.value);
  };

  // Input change for new stakeholder categorie
  const categorieHandler = (e) => {
    const categorie = e.target.options[e.target.selectedIndex].innerText;
    setTargetgroupCategorie(categorie);
  };

  // (Optional) highlight ImpactAI
  const impactaiHandler = () => {
    setChatOpen(true);
    setHightlighted(!hightlighted);
    setHightlightText('Ja, zeker!');
  };

  // ========== RENDER ==========

  return (
    <div className="main">
      <div className="main-container">

        <PageHeader
          heading={'Doelgroepen'}
          description={`In deze stap bepaal je wie jullie doelgroepen zijn. Dit zijn de mensen of organisaties die jullie willen bereiken met jullie impact.`}
          AI={true}
        />

        <div className="table-container dashboard-container">
          <table>
            <thead>
              <tr>
                <th style={{ backgroundColor: secundairyColor }}></th>
                <th style={{ backgroundColor: secundairyColor }}>DOELGROEP</th>
                <th style={{ backgroundColor: secundairyColor }}>CATEGORIE</th>
                <th style={{ backgroundColor: secundairyColor }}>DELETE</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="stakeholders" direction="vertical">
                {(provided, snapshot) => (
                  <tbody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {stakeholdersFirestore && stakeholdersFirestore.map((stakeholder, index) => (
                      <Draggable
                        key={stakeholder.ID}
                        draggableId={stakeholder.ID}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>
                                <DragIndicatorOutlinedIcon />
                            </td>
                            <td>
                              <Tooltip
                                content={stakeholder.Organisation}
                                width="80%"
                                top="-60px"
                              >
                                <input
                                  type="text"
                                  data-docid={stakeholder.docid}
                                  defaultValue={stakeholder.Organisation}
                                  placeholder='Omschrijving van doelgroep'
                                  onChange={editNameHandler}
                                />
                              </Tooltip>
                            </td>
                            <td>
                              <select
                                name="categorie"
                                data-docid={stakeholder.docid}
                                defaultValue={stakeholder.Categorie}
                                onChange={editCategoryHandler}
                              >
                                <option value="">-- Selecteer een categorie --</option>
                                {targetgroupCategories.map((cat) => (
                                  <option value={cat} key={cat}>{cat}</option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <img
                                className='table-delete-icon'
                                data-docid={stakeholder.docid}
                                data-deletename={stakeholder.Organisation}
                                onClick={deleteModalHandler}
                                src={deleteIcon}
                                alt="delete icon"
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>

          <AddItemTableRow
            toolTipContent='Voeg een doelgroep toe'
            onClick={() => setOpenAddTargetgroupModal(true)}
          />
        </div>

        <div className="impactstrategy-saved-and-nextstep-container">
          <SavedNotice />
          <div id='next-step-container'>
            <PreviousStepContainer url={'problemanalysis'} mainItem={'impactstrategy'} />
            <NextStepContainer url={'Goals'} mainItem={'impactstrategy'} />
          </div>
        </div>

        {/* Delete modal */}
        <DeleteModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteItem={deleteStakeholder}
          deleteName={deleteName}
        />

        {/* Add stakeholder modal */}
        <Modal
          isOpen={openAddTargetgroupModal}
          onRequestClose={openAddTargetgroupModal}
          style={modalStyles}
          contentLabel="Doelgroep toevoegen"
        >
          <div>
            <h1>Doelgroep toevoegen</h1>
            <div>
              <p>Selecteer een categorie</p>
              <select name="categorie" onChange={categorieHandler}>
                <option value="">-- Selecteer een categorie --</option>
                {targetgroupCategories.map((categorie) => (
                  <option value={categorie} key={categorie}>{categorie}</option>
                ))}
              </select>
              <p>Geef de doelgroep een naam</p>
              <input
                type="text"
                placeholder='Schrijf hier de naam van de doelgroep'
                onChange={nameHandler}
              />
            </div>
            <div id='modal-button-container'>
              <button
                id='modal-cancel-button'
                onClick={() => setOpenAddTargetgroupModal(false)}
                style={{ display: loading ? 'none' : 'block' }}
              >
                Annuleren
              </button>
              <button
                id='modal-save-button'
                onClick={addStakeholder}
                style={{ display: loading ? 'none' : 'block' }}
              >
                Opslaan
              </button>
              <img
                src={spinner}
                alt=""
                style={{ display: loading ? 'block' : 'none' }}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Stakeholders;

