import {useFirestoreOrderBy} from "../../firebase/useFirestore"
import { client } from "../../hooks/Client"
import ScrollToTop from "../../hooks/ScrollToTop";
import useSettings from "../../hooks/Settings";
import Tooltip from "../../components/common/Tooltip";
import NextStepContainer from "../../components/common/NextStepContainer.jsx";
import { ActivitiesSystemMessage, ActivitiesToolCallContent, ActivitiesSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { useContext, useState, useEffect } from "react";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import PreviousStepContainer from "../../components/common/PreviousStepContainer";
import DeleteModal from "../../components/common/DeleteModal";
import deleteIcon from '../../images/icons/delete-icon.png'
import DraggableTableItems from "../../components/common/DraggableTableItems";
import { db, timestamp } from "../../firebase/config.js"
import { v4 as uuid } from 'uuid';
import PageHeader from "../../components/layout/PageHeader.jsx";
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import SavedNotice from "../../components/common/SavedNotice"
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Activities = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // State
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteDocid, setDeleteDocid] = useState('123')
    const [deleteName, setDeleteName] = useState('')
    const [activityId, setActivityId] = useState('')
    const [dragArray, setDragArray] = useState([]);

    // Hooks
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')   

     //ImpactAI
     const pageSystemMessage = ActivitiesSystemMessage()
     const pageToolCallContent = ActivitiesToolCallContent()
     const pageSaveMessage = ActivitiesSaveMessage()
     const pageStartMessage = `Welkom bij de activiteiten, ${auth?.ForName}. Hier ga we de concrete activiteiten benoemen die je uitvoert om je impactdoel te bereiken.
     Mag ik een voorstel doen?`
     const pageExampleMessages = []
     const pageCollection = 'Activities'
     const pageField = 'Activity'
     const pageParentId = ''
     const pageType = 'activity'

     useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth])

     // Delete modal 
     const deleteModalHandler = (e) => {
        const docid = e.target.dataset.docid
        const deleteName = e.target.dataset.deletename
        const activityId = e.target.dataset.id

        setDeleteDocid(docid)
        setDeleteName(deleteName)
        setActivityId(activityId)
        setDeleteModal(!deleteModal)
    }

    // Whenever Firestore data changes, update dragArray state
    useEffect(() => {
        setDragArray(activities);
    }, [activities]);

    // DRAG & DROP: reorder items and update Firestore
    const handleDragEnd = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(dragArray);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);
    
        // Update local state to reflect the reorder immediately
        setDragArray(items);
    
        // Now update Firestore with new positions
        items.forEach((item, idx) => {
          db.collection('Activities')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });
    
        // Show "saved" icon
        setSaved('flex');
      };

    const activityHandler = (e) => {

        const title = e.target.value 
        const docid = e.target.dataset.docid

        db.collection('Activities')
        .doc(docid)
        .update({
            Activity: title
        })
        .then(() => {
            setSaved('flex')
         })

    }

    const addActivity = (e) => {

        const id = uuid()
        
        db.collection('Activities')
        .doc()
        .set({
            Activity: '',
            ID: id,
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Position: activities.length + 1,
        })
    }

    const deleteActivity = (e) => {

        db.collection('Activities')
        .doc(deleteDocid)
        .delete()
        .then(() => {
            // Delete outputs
            db.collection('Outputs')
            .where('ActivityID', '==', activityId)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    doc.ref.delete()
                })
            })
        })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    return (
        <div className="main">
        <div className="main-container">
            <PageHeader 
                heading={'Activiteiten'}
                description={`De activiteiten zijn de concrete acties die je onderneemt om je impactdoel te bereiken. Hier benoem je de activiteiten die je uitvoert.`}
                AI={true}
            />
            <div className="table-container dashboard-container">
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}></th>  
                            <th style={{backgroundColor: secundairyColor}}>ACTIVITEIT</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="stakeholders" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {activities && activities.map((activity, index) => (
                                    <Draggable
                                    key={activity.ID}
                                    draggableId={activity.ID}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                    <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <td>
                                            <DragIndicatorOutlinedIcon />
                                        </td>
                                        <td>
                                            <Tooltip content={activity.Activity} width='100%' top='-60px' >
                                                <input contentEditable type="text" data-docid={activity.docid} defaultValue={activity.Activity} placeholder='Titel' onChange={activityHandler} />
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip content='Activiteit verwijderen' top='-60px'>
                                                <img className='table-delete-icon' data-id={activity.ID} data-deletename={activity.Activity} data-docid={activity.docid} onClick={deleteModalHandler} src={deleteIcon} alt="" />
                                            </Tooltip>
                                        </td>
                                    </tr>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                    </DragDropContext>
                </table>
                <AddItemTableRow toolTipContent={'Activiteit toevoegen'} onClick={addActivity} />
            </div>
            <div className="impactstrategy-saved-and-nextstep-container">
                <SavedNotice />
                <div id='next-step-container'>
                    <PreviousStepContainer url={'Goals'} mainItem={'impactstrategy'}/>
                    <NextStepContainer url={'Outputs'} mainItem={'impactstrategy'}/>
                </div>
            </div>
            <DeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} deleteItem={deleteActivity} deleteName={deleteName} />
        </div>
    </div>
    )
}

export default Activities
