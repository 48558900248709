import Location from "../../hooks/Location"
import { useFirestoreID, useFirestoreGeneralTwo, useFirestoreGeneralOrderBy } from "../../firebase/useFirestore";
import { useState, useEffect, useContext } from "react";
import useSettings from "../../hooks/Settings";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { PersonaBuilderSystemMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from "../../StateManagment/Auth";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import Tooltip from "../../components/common/Tooltip";
import Breadcrumb from "../../components/common/Breadcrumb";
import { useHistory } from "react-router-dom";
import { client } from "../../hooks/Client";
import CategorizeFieldNotice from "../../components/researchResults/CategorizeFieldNotice";
import FieldResponsesCountPersonas from "../../components/personas/FieldResponsesCountPersonas";
import MomentMeta from "../../components/Research/MomentMeta";
import MeetstandardEffectMeta from '../../components/meetstandaard/MeetstandaardEffectMeta';

const ReserachResultsPersonas = () => {
    // Context
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        startMessage: [startMessage, setStartMessage],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
    } = useContext(ImpactAI);

    //State
    const [questionnaireID, setQuestionnaireID] = useState('')
    const [researchTitle, setResearchTitle] = useState('')
    const [title, setTitle] = useState('')
    const [personaTitle, setPersonaTitle] = useState('')
    const [resultType, setResultType] = useState('forms')
    const [forms, setForms] = useState([])

    // Hooks
    const researchId = Location()[4]
    const personaId = Location()[3]
    const secundairyColor = useSettings().SecundairyColor
    const history = useHistory()
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    console.log(questionnaireID)

    // Firestore
    const research = useFirestoreID('Research', researchId ? researchId : '')
    const fields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'QuestionnaireID', questionnaireID ? questionnaireID : '', 'Position', 'asc')
    const personas = useFirestoreID('Personas', personaId ? personaId : '')
    const results = useFirestoreGeneralTwo('QuestionnairesResponses', 'ResearchID', researchId ? researchId : '', 'Persona', personaId ? personaId : '')

    // ImpactAI
    const pageSystemMessage = PersonaBuilderSystemMessage()
    const pageStartMessage = `Hoi ${auth?.ForName}, welkom bij de onderzoeksanalyse van onderzoek ${title}. 
 Hier kun je de resultaten van het onderzoek inzien en de eventuele open vragen categoriseren.
 Kan ik je ergens mee helpen?`
    const pageType = 'personabuilder'
    const paegExampleMessages = ['Hoe kan ik een open vraag analyseren?']

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setExampleMessages(paegExampleMessages)
    }, [auth, title])

    // Reset hightlights
    useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    }, [])

    // Set research title
    useEffect(() => {
        research && research.forEach(item => {
            setQuestionnaireID(item.QuestionnaireID)
            setResearchTitle(item.Title)
        })
    }, [research])

    // Set persona title
    useEffect(() => {
        personas && personas.forEach(item => {
            setPersonaTitle(item.Name)
        })
    }, [personas])

    // Sort forms from responses by date
    const sortForms = () => {
        const groupedForms = {};
    
        results && results.forEach(item => {
            const timestamp = item.Timestamp.toDate().getTime();
    
            if (!groupedForms[timestamp]) {
                groupedForms[timestamp] = {
                    date: item.Timestamp.toDate(),  // store the date
                    momentID: item.MomentID,        // store the MomentID
                    items: []                       // store the responses
                };
            }
    
            groupedForms[timestamp].items.push(item);
        });
    
        // Sort timestamps in descending order
        const sortedTimestamps = Object.keys(groupedForms)
            .map(ts => parseInt(ts))
            .sort((a, b) => b - a);
    
        // Map to the sorted form you want
        const sortedGroupedForms = sortedTimestamps.map(timestamp => {
            const group = groupedForms[timestamp];
            return {
                date: group.date,
                momentID: group.momentID,
                items: group.items
            };
        });
    
        return sortedGroupedForms;
    };
    

    useEffect(() => {
        const sortedForms = sortForms(); // Sort forms by date
        setForms(sortedForms);
    }, [results]);

    // Set field title
const fieldTitleType = (item) => {
    switch(item.Type){
        case 'paragraph':
            return item.Question
        case 'scale':
            return item.Question
        case 'multiple-one':
            return item.Question
        case 'multiple-multiple':
            return item.Question
        case 'matrix-one':
            return matrixType(item)
        case 'matrix-multiple':
            return matrixType(item)
    }
}

// Matrix type

const matrixType = (item) => {

    if(item.Standard === 'true'){

        return (
            <div id='meetstandaard-effect-meta-container'>
                <p>Gestandaardiseerd effect: </p>
                <MeetstandardEffectMeta effectId={item.MSIId}/>
            </div>
        )

    } else {
        return item.Question
    }
}

    return (
        <div className="main">
            <div className="main-container">
                <div className="page-header">
                    {/* <DateRangeOutlinedIcon /> */}
                    <h1>Onderzoeksresultaten</h1>
                    <h2>{personaTitle}</h2>
                    <h2>{researchTitle}</h2>
                </div>
                <div className='table-container table-container-dashboard'>
                    <div className="select-activity-container select-activity-container-wizard">
                        <div className="select-activity-inner-container">
                            <div className='activity-select-item-container' onClick={() => setResultType('forms')} style={{ backgroundColor: resultType === 'forms' ? secundairyColor : 'white' }}>
                                <p onClick={() => setResultType('forms')} style={{ color: resultType === 'forms' ? 'white' : '#616161' }}>Formulieren</p>
                            </div>
                            <div className='activity-select-item-container' onClick={() => setResultType('questions')} style={{ backgroundColor: resultType === 'questions' ? secundairyColor : 'white' }}>
                                <p onClick={() => setResultType('questions')} style={{ color: resultType === 'questions' ? 'white' : '#616161' }}>Lijst met vragen</p>
                            </div>
                        </div>
                    </div>
                    {resultType === 'questions' &&
                        <table>
                            <tr>
                                <th style={{ backgroundColor: secundairyColor }}>VRAAG</th>
                                <th style={{ backgroundColor: secundairyColor }}>RESPONSES</th>
                            </tr>
                            {fields && fields.map(item => (
                                <tr>
                                    <td>
                                        <p>{fieldTitleType(item)}</p>
                                    </td>
                                    <td>
                                        <div id='reserach-overview-responses-container' onClick={() => { history.push(`/${client}/researchresponsespersonas/${personaId}/${researchId}/${item.ID}`) }}>
                                            <Tooltip content='Bekijk de responses' top={'-60px'}>
                                                <ChatBubbleOutlineOutlinedIcon className='table-delete-icon' />
                                            </Tooltip>
                                            <FieldResponsesCountPersonas fieldID={item.ID} researchID={researchId} personaID={personaId} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    }
                    {resultType === 'forms' &&
                        <table>
                            <tr>
                                <th style={{ backgroundColor: secundairyColor }}>DATUM</th>
                                <th style={{ backgroundColor: secundairyColor }}>MEETMOMENT</th>
                                <th style={{ backgroundColor: secundairyColor }}>RESPONSES</th>
                            </tr>
                            {forms && forms.map(item => (
                                <tr>
                                    <td>
                                        <p>{item.date.toLocaleDateString('nl-NL', options)} </p>
                                    </td>
                                    <td>
                                        <MomentMeta item={item.momentID} />
                                    </td>
                                    <td>
                                        <div id='reserach-overview-responses-container' onClick={() => { history.push(`/${client}/researchresultspersonasforms/${personaId}/${researchId}/${item.date}`) }}>
                                            <Tooltip content='Bekijk de responses' top={'-60px'}>
                                                <ChatBubbleOutlineOutlinedIcon className='table-delete-icon' />
                                            </Tooltip>
                                            <p>({item.items.length})</p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    }
                </div>
                <Breadcrumb
                    section={'Meten'}
                    previousPage={'Onderzoeken'}
                    previousPageUrl={'Research'}
                    nextPage={''}
                    nextPageUrl={''}
                    counter={2}
                    totalSteps={5}
                />
            </div>
        </div>
    )
}

export default ReserachResultsPersonas