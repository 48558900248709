import { useEffect, useState } from 'react'
import Location from "../../hooks/Location"
import { useFirestoreGeneralTwo, useFirestoreID, useFirestoreGeneral } from '../../firebase/useFirestore'
import firebase from 'firebase/app'
import FieldMetaForms from '../../components/Questionnaire/FieldMetaForms'
import { db } from '../../firebase/config'
import { type } from '../../hooks/Client'
import { use } from 'react'

const ResearchResultsPersonasForms = () => {

    // State
    const [researchTitle, setResearchTitle] = useState('')
    const [personaTitle, setPersonaTitle] = useState('')
    const [orderedResponses, setOrderedResponses] = useState([])

    // Hooks
    const timestamp = Location()[5]
    const researchId = Location()[4]
    const personaId = Location()[3]
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

    // Firestore
    const responses = useFirestoreGeneralTwo('QuestionnairesResponses', 'ResearchID', researchId ? researchId : '', 'Persona', personaId)
    const research = useFirestoreID('Research', researchId ? researchId : '')
    const personas = useFirestoreID('Personas', personaId ? personaId : '')

    console.log(research)


    // Set the research title
    useEffect(() => {
        research && research.forEach(item => {
            setResearchTitle(item.Title)
        })
    }, [research])

    // Set the persona title
    useEffect(() => {
        personas && personas.forEach(item => {
            setPersonaTitle(item.Name)
        })
    }, [personas])

    // Form date
    const formDate = () => {
        // 1. Extract the numbers
        const secondsMatch = timestamp.match(/seconds=(\d+)/);
        const nanosMatch = timestamp.match(/nanoseconds=(\d+)/);

        // 2. Convert them to integers
        const seconds = secondsMatch ? parseInt(secondsMatch[1], 10) : 0;
        const nanoseconds = nanosMatch ? parseInt(nanosMatch[1], 10) : 0;

        // 3. Convert to milliseconds
        //    1 second  = 1000 ms
        //    1 nanosec = 0.000001 ms
        const milliseconds = seconds * 1000 + nanoseconds / 1e6;

        // 4. Build a JavaScript Date
        const date = new Date(milliseconds);
        const convertedDate = date.toLocaleDateString('nl-NL', options)

        return convertedDate
    }

    // Find fields 
    const findFields = async () => {

        for( const r of research){
            console.log(r.QuestionnaireID)
            const fields = await db.collection('QuestionnaireFields')
                .where('QuestionnaireID', '==', r.QuestionnaireID)
                .where('SectionID', '==', '')
                .orderBy('Position', 'asc')
                .get()

                const fieldsArray = []

                for (const field of fields.docs) {
                    const object = {
                        id: field.ID,
                        title: field.data().Question,
                        type: field.data().Type,
                        position: field.data().Position
                    }
                    fieldsArray.push(object)
                }

            return fieldsArray
        }
    }

    useEffect(async () => {
        const fields = await findFields()
    }, [research])


    // Order reponses by question position
    const orderResponses = async () => {

        const array = []

        for (const response of responses) {
            const fields = await db.collection('QuestionnaireFields')
                .where('ID', '==', response.FieldID)
                .orderBy('Position', 'asc')
                .get()

            for (const field of fields.docs) {
                const object = {
                    fieldTitle: field.data().Question,
                    response: response.Input,
                    position: field.data().Position,
                    type: field.data().FieldType,
                    rowTitle: response.RowTitle
                }
                array.push(object)
            }
        }

        const orderedArray = array.sort((a, b) => a.position - b.position)

        return orderedArray

    }

    useEffect(async () => {
        const responses = await orderResponses()
        setOrderedResponses(responses)
    }, [responses])

    return (
        <div className="main">
            <div className="main-container">
                <div className='page-header'>
                    <h1>{researchTitle}</h1>
                    <h2>{personaTitle}</h2>
                    <h2>{formDate()}</h2>
                </div>

                {responses && responses.map((response) => (
                    <div key={response.id} className='dashboard-container'>
                        {response.FieldType === 'matrix-one' || response.FieldType === 'matrix-multiple' ?
                            <div>
                                <h3>{response.RowTitle}</h3>
                                <p>{response.Input}</p>
                            </div>
                            :
                            <div>
                                <FieldMetaForms fieldId={response.FieldID} />
                                <p>{response.Input}</p>
                            </div>
                        }
                    </div>
                ))}

            </div>
        </div>
    )
}

export default ResearchResultsPersonasForms