import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext, useState, useEffect } from "react";
import useSettings from "../../hooks/Settings";
import deleteIcon from '../../images/icons/delete-icon.png'
import AddItemTableRow from '../../components/common/AddItemTableRow'
import { v4 as uuid } from 'uuid';
import React from 'react'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Presence = ({ personaNavigation, item }) => {
  // Context
  const [saved, setSaved] = useContext(SavedIcon)

  // State
  const [dragArray, setDragArray] = useState([]);

  // Hooks
  const secundairyColor = useSettings().SecundairyColor

  // Firestore
  const shedule = useFirestoreGeneralOrderBy('PersonaShedule', 'PersonaID', item.ID ? item.ID : '', 'Position', 'asc')

  // Whenever Firestore data changes, update dragArray state
  useEffect(() => {
    setDragArray(shedule);
  }, [shedule]);

  // DRAG & DROP: reorder items and update Firestore
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArray);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArray(items);

    // Now update Firestore with new positions
    items.forEach((item, idx) => {
      db.collection('PersonaShedule')
        .doc(item.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

    // Show "saved" icon
    setSaved('flex');
  };

  // Add day
  const addDay = () => {
    db.collection('PersonaShedule')
      .add({
        Compagny: client,
        CompagnyID: client,
        Timestamp: timestamp,
        PersonaID: item.ID,
        PersonaDocid: item.docid ,
        Day: 'monday',
        StartTime: '',
        EndTime: '',
        Position: shedule.length + 1,
        ID: uuid()
      })
  }

  // Delete day
  const deleteDay = (e) => {
    const docid = e.target.dataset.docid
    db.collection('PersonaShedule')
      .doc(docid)
      .delete()

    setSaved(true)
  }

  // Update weekday
  const updateWeekday = (e) => {
    const day = e.target.options[e.target.selectedIndex].value
    const docid = e.target.dataset.docid

    db.collection('PersonaShedule')
      .doc(docid)
      .update({
        Day: day
      })

      setSaved(true)
  }


  // Update start and endtime
  const startTimeHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaShedule')
      .doc(e.target.dataset.docid)
      .update({
        StartTime: value
      })

    setSaved(true)
  }

  const endTimeHandler = (e) => {
    const value = e.target.value

    db.collection('PersonaShedule')
      .doc(e.target.dataset.docid)
      .update({
        EndTime: value
      })

    setSaved(true)
  }

  return (
    <div style={{ display: personaNavigation === 'presence' ? 'block' : 'none' }}>
      {/* <h2>Aanwezigheid</h2> */}
      <div className='table-container dashboard-container'>
        <h2>Rooster</h2>
        <table>
          <thead>
              <tr>
                <th style={{ backgroundColor: secundairyColor }}></th>
                <th style={{backgroundColor: secundairyColor}}>DAG</th>
                <th style={{backgroundColor: secundairyColor}}>START</th>
                <th style={{backgroundColor: secundairyColor}}>EIND</th>
                <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
              </tr>
          </thead>
          <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="shedule" direction="vertical">
                {(provided, snapshot) => (
                  <tbody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
          {shedule && shedule.map((item, index) => (
               <Draggable
               key={item.docid}
               draggableId={item.docid}
               index={index}
             >
               {(provided, snapshot) => (
                 <tr
                   ref={provided.innerRef}
                   {...provided.draggableProps}
                   {...provided.dragHandleProps}
                 >
                   <td>
                       <DragIndicatorOutlinedIcon />
                   </td>
                   <td>
                        <select name="" id=""  data-docid={item.docid} defaultValue={item.Day} onChange={updateWeekday}>
                          <option value="monday">Maandag</option>
                          <option value="tuesday">Dinsdag</option>
                          <option value="wednesday">Woensdag</option>
                          <option value="thursday">Donderdag</option>
                          <option value="friday">Vrijdag</option>
                          <option value="saturday">Zaterdag</option>
                          <option value="sunday">Zondag</option>
                        </select>
                  </td>
                  <td>
                      <input type="time" defaultValue={item.StartTime}  data-docid={item.docid} data-type='start' onChange={startTimeHandler} />
                  </td>
                  <td>
                    <input type="time" defaultValue={item.EndTime}  data-docid={item.docid} data-type='end' onChange={endTimeHandler} />
                  </td>
                  <td>
                      <img className='table-delete-icon' data-docid={item.docid} onClick={deleteDay} src={deleteIcon} alt="" />
                  </td>
                  </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
      <AddItemTableRow onClick={addDay} toolTipContent='Dag toevoegen' />
      </div>
    </div>
  )
}

export default Presence