import { useFirestoreGeneralOrderBy } from '../../../firebase/useFirestore';
import { useHistory } from "react-router-dom";
import DashboardPresenceCheckComponent from './DashboardPresenceCheckComponent';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Tooltip from '../../common/Tooltip';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import { client } from "../../../hooks/Client"

const PresenceCheckedComponent = ({shedule}) => {

  // Firestore
  const presenceReports = useFirestoreGeneralOrderBy('PersonaPresenceReports', 'PersonaID', shedule.PersonaID ? shedule.PersonaID : '', 'Timestamp', 'asc');

  // Hooks
  const history = useHistory()
  
  // Get the report of today
  const getTodaysReport = () => {
    return presenceReports && presenceReports.filter(report => {
      // Format 'today' in Dutch long-form, matching "vrijdag 25 oktober 2024" style
      const todayDutch = new Date().toLocaleDateString("nl-NL", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric"
      });
      
      if (report.Date === todayDutch) {
        return report;
      }
      
      return false;
    });
  };

  return (
    <div id='check-precense-container'>
        {getTodaysReport().length > 0 ?
        <div id='presence-options-container'>
          <div className='register-precense-options-container'onClick={() => history.push(`/${client}/personapresencereportdetail/${getTodaysReport()[0]?.PersonaID}/${getTodaysReport()[0]?.ID}`)} >
            <PlagiarismOutlinedIcon/>
            <p>Bekijk report</p>
          </div>
        </div>
        :
        <DashboardPresenceCheckComponent shedule={shedule} />
        }
    </div>
  )
}

export default PresenceCheckedComponent