import { client } from "../../hooks/Client"
import { useFirestoreGeneralOrderBy, useFirestoreOrderBy} from "../../firebase/useFirestore"
import { useHistory } from "react-router-dom";
import ScrollToTop from "../../hooks/ScrollToTop";
import { db } from "../../firebase/config";
import useSettings from "../../hooks/Settings";
import NextStepContainer from "../../components/common/NextStepContainer.jsx";
import { OutputsSystemMessage, OutputsToolCallContent, OutputsSaveMessage } from "../../hooks/impactAI/Prompts"
import { Auth } from '../../StateManagment/Auth';
import { useContext, useState, useEffect } from "react";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import { v4 as uuid } from 'uuid';
import { timestamp } from "../../firebase/config";
import RandomColorGenerator from "../../hooks/RandomColorGenerator";
import { ImpactAI } from '../../StateManagment/ImpactAI';
import PreviousStepContainer from "../../components/common/PreviousStepContainer";
import OutputIsSyncedTitle from "../../components/syncronisations/OutputIsSyncedTitle";
import OutputIsSyncedDelete from "../../components/syncronisations/OutputIsSyncedDelete";
import PageHeader from "../../components/layout/PageHeader.jsx";
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import SavedNotice from "../../components/common/SavedNotice"
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const OutputSettings = () => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        parentId: [parentId, setParentId],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText]
    } = useContext(ImpactAI);

    // States
    const [dragArray, setDragArray] = useState([]);
    const [activityID, setActivityID] = useState('')
    
    // Hooks
    const history = useHistory()
    ScrollToTop()
    const secundairyColor = useSettings().SecundairyColor
    const randomColor = RandomColorGenerator()

    // Firestore
    const outputs = useFirestoreGeneralOrderBy('Outputs', 'ActivityID', activityID, 'Position', 'asc')
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')

    //ImpactAI
    const pageSystemMessage = OutputsSystemMessage()
    const pageToolCallContent = OutputsToolCallContent()
    const pageSaveMessage = OutputsSaveMessage(activities.length)
    const pageStartMessage = `Hoi, ${auth?.ForName}. In deze stap gaan we de kwantitatieve impact (outputs) benoemen die je gaat bijhouden voor je activiteiten. 
    Mag ik een voorstel doen?`
    const pageExampleMessages = []
    const pageCollection = 'Outputs'
    const pageField = 'Title'
    const pageParentId = ''
    const pageType = 'output'

    useEffect(() => {
        setType(pageType)
        setSystemMessage(pageSystemMessage)
        setStartMessage(pageStartMessage)
        setToolCallContent(pageToolCallContent)
        setSaveMessage(pageSaveMessage)
        setCollection(pageCollection)
        setField(pageField)
        setParentId(pageParentId)
        setExampleMessages(pageExampleMessages)
    }, [auth, activities])

    // Set first activity as default
    useEffect(() => {
        activities && setActivityID(activities[0] ? activities[0].ID : '')
    },[activities])

    // Whenever Firestore data changes, update dragArray state
    useEffect(() => {
        setDragArray(outputs);
    }, [outputs]);

    // DRAG & DROP: reorder items and update Firestore
    const handleDragEnd = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(dragArray);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);
    
        // Update local state to reflect the reorder immediately
        setDragArray(items);
    
        // Now update Firestore with new positions
        items.forEach((item, idx) => {
          db.collection('Outputs')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });
    
        // Show "saved" icon
        setSaved('flex');
      };

    // Add output
    const addOutput = (e) => {

        db.collection('Outputs')
        .doc()
        .set({
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Title: '',
            Position: outputs.length + 1,
            Color: randomColor,
            ActivityID: activityID
        })
    }

     // Reset hightlights
     useEffect(() => {
        setHightlighted(false)
        setHightlightText('')
    },[])

    // Output count indicator
    const ouputCountIndicator = (activityID) => {
        const outputCount = outputs.filter(output => output.ActivityID === activityID).length
        return outputCount
    }

  return (
    <div className="main">
        <div className="main-container" >
            <PageHeader 
                heading={'Outputs'}
                description={`In deze stap ga je de kwantitatieve impact (outputs) benoemen die je gaat bijhouden voor je activiteiten.`}
                AI={true}
            />
            <div className="select-activity-container select-activity-container-wizard">
                <div className="select-activity-inner-container">
                {activities && activities.map(item => (
                        <div key={item.ID} data-id={item.ID} data-title={item.Activity} className='activity-select-item-container' onClick={() => setActivityID(item.ID)} style={{backgroundColor: activityID === item.ID ? secundairyColor : 'white'}}>
                            <p data-id={item.ID} data-title={item.Activity} onClick={() => setActivityID(item.ID)} style={{color:  activityID === item.ID ? 'white' : '#616161'}}>{item.Activity}</p>
                            {/* <p>{ouputCountIndicator(item.ID)}</p> */}
                        </div>
                    ))}
                </div>
            </div>
            <div className='table-container dashboard-container'>
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}></th>
                            <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="stakeholders" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {outputs && outputs.map((output, index) => (
                                    <Draggable
                                    key={output.ID}
                                    draggableId={output.ID}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                    <tr
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <td>
                                            <DragIndicatorOutlinedIcon />
                                        </td>
                                        <td>
                                            <OutputIsSyncedTitle outputID={output.ID}/>
                                        </td>
                                        
                                        <td>
                                            <OutputIsSyncedDelete outputID={output.ID}/>
                                        </td>
                                    </tr>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                    </DragDropContext>
                </table>
                <AddItemTableRow toolTipContent={'Output toevoegen'} onClick={addOutput}/>
            </div>
            <div className="impactstrategy-saved-and-nextstep-container">
                <SavedNotice />
                <div id='next-step-container'>
                    <PreviousStepContainer url={'Activities'} mainItem={'impactstrategy'}/>
                    <NextStepContainer url={'effects'} mainItem={'impactstrategy'}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OutputSettings