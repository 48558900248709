import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import Breadcrumb from "../../components/common/Breadcrumb";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { useContext, useEffect } from "react";
import { MeasurementplanSystemMessage } from "../../hooks/impactAI/Prompts"
import { useHistory } from 'react-router-dom';
import { client } from "../../hooks/Client"
import { ActiveMenu } from "../../StateManagment/ActiveMenuItem";

const Measurementplan = () => {
  // Context
  const [auth] = useContext(Auth)
  const { active, setActive, activeSubItem, setActiveSubItem } = useContext(ActiveMenu);
  const {
      type: [type, setType],
      systemMessage: [systemMessage, setSystemMessage],
      startMessage: [startMessage, setStartMessage],
      exampleMessages: [exampleMessages, setExampleMessages],
      chatOpen: [chatOpen, setChatOpen]
  } = useContext(ImpactAI);

  // Hooks
  const history = useHistory()

  //ImpactAI
  const pageSystemMessage = MeasurementplanSystemMessage()
  const pageStartMessage = `Welkom bij het meetplan, ${auth?.ForName}.
   Heb je vragen over het meetplan?`
  const pageExampleMessages = ['Wat is een meetplan?']
  const pageType = 'measurementplan'

  useEffect(() => {
     setType(pageType)
     setSystemMessage(pageSystemMessage)
     setStartMessage(pageStartMessage)
     setExampleMessages(pageExampleMessages)
 }, [auth])

 const segmentSteps = [
  {
    name: 'Storytelling',
    url: 'storytelling'
  },
  {
    name: 'Activiteiten',
    url: 'outputresults'
  },
  {
    name: 'Onderzoeken',
    url: 'research',
  }

]

  return (
    <div className="main">
      <div className="main-container">
          <div className="page-header">
              <BiotechOutlinedIcon/>
              <h1>Meten</h1>
          </div>
        <div className='section-explainer-text-container'>
          <div>
            <p>Welkom bij het meten, {auth?.ForName}. Hier ga je 
              je impact meten door de effecten die je in de impactstrategie hebt benoemd te gaan onderbouwen met impactdata die je op gaat halen bij je doelgroep(en).</p>
          </div>
          <div id='segment-overview-steps-container'>
            {segmentSteps.map((step, index) => (
              <div 
              key={index} 
              className="segment-overview-step-container"
              onClick={() => {
                history.push(`/${client}/${step.url}`);
                setActive('measurementplan');
                setActiveSubItem(step.url)
              }}
              >
                <div className="segment-overview-step-inner-container">
                  <div className="segment-overview-checked-name-container">
                    <div>
                      <p><b>{step.name}</b></p>
                    </div>
                  </div>
                  <p id='segment-overview-step-button'>Bekijk/aanpassen</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
  </div>
  )
}

export default Measurementplan