import { useFirestoreNoCompagnyGeneralOrderBy } from '../../firebase/useFirestore'

const ProjectReportsProgress = ({projectId}) => {
  // Hooks
  const options = { day: 'numeric' ,month: 'numeric', year: 'numeric'};

  // Firestore
  const reports = useFirestoreNoCompagnyGeneralOrderBy('LiveReports', 'CompagnyID', projectId ? projectId : '', 'Timestamp', 'desc')

  // Last result
  const lastResult = reports[reports.length - 1]
  const lastResultDate = lastResult?.Timestamp.toDate().toLocaleDateString("nl-NL", options)
  const daysAgo = Math.floor((new Date() - lastResult?.Timestamp.toDate()) / (1000 * 60 * 60 * 24))

    return (
    <div>
        <p style={{color: reports.length === 0 ? 'red' : 'green'}}>Aantal: {reports.length}</p>
        {reports.length > 0 ? <p style={{color: daysAgo > 30 ? 'red' : 'green'}}>Laatste: {lastResultDate}</p> : '-'}
    </div>
    )
}

export default ProjectReportsProgress