import { useFirestoreGeneralOrderBy } from "../../firebase/useFirestore"
import DraggableItems from "../common/DraggableItems"
import { useState, useRef, useContext} from 'react'
import useSettings from "../../hooks/Settings";
import MoveOutIcon from '../../images/icons/move-out-icon.png'
import { db, timestamp } from "../../firebase/config";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Tooltip from "../common/Tooltip";
import deleteIcon from '../../images/icons/delete-icon.png'
import scaleIcon from '../../images/icons/scale-icon.png'
import multipleOneIcon from '../../images/icons/multiple-one-icon.png'
import multipleMultipleIcon from '../../images/icons/multiple-multiple-icon.png'
import matrixIcon from '../../images/icons/matrix-icon.png'
import textIcon from '../../images/icons/text-icon.png'
import editIcon from '../../images/icons/edit-icon.png'
import QuestionnaireField from "./QuestionnaireField";
import copyIcon from '../../images/icons/copy-icon.png'
import { v4 as uuid } from 'uuid'
import { client } from "../../hooks/Client";

const OrderQuestionnaireSectionFields = ({section}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // States
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [editQuestion, setEditQuestion] = useState(null)

    // Refs
    const sectionIdRef = useRef('field');
    const sectionRef = useRef('field')

    // Firestore
    const sectionFields = useFirestoreGeneralOrderBy('QuestionnaireFields', 'SectionID', section ? section : '123', 'Position', 'asc')

    // Hooks
    const secundairyColor = useSettings().SecundairyColor

    // Handlers
    const titleHandler = (e) => {
        const docid = e.target.dataset.docid
        const value = e.target.value

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            Question: value
        })
        .then(() => {
            setSaved(true)
        })
    }

    // Handlers
    const removeFromSectionHandler = (e) => {
        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            SectionID: ''
        })
        .then(() => {
            setSaved(true)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const deleteQuestionHandler = (e) => {
        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .delete()
        .then(() => {
            setSaved(true)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const editHandler = (e) => {

        const questionaireId = e.target.dataset.id

        if(editQuestion !== questionaireId) {
            setEditQuestion(questionaireId)
        } else {
            setEditQuestion(null)
        }

    }

    // Type icon
    const type = (type) => {
        if (type === 'scale') {
            return scaleIcon
        } else if (type === 'paragraph') {
            return textIcon
        } else if (type === 'multiple-one') {
            return multipleOneIcon
        } else if (type === 'multiple-multiple') {
            return multipleMultipleIcon
        } else if (type === 'matrix') {
            return matrixIcon
        } else {
            return editIcon
        }
    }

    // Duplicate question
    const duplicateHandler = (e) => {

        const questionaireId = e.target.dataset.questionaireid
        const type = e.target.dataset.type
        const question = e.target.dataset.question
        const explainer = e.target.dataset.explainer
        const reachStart = e.target.dataset.reachstart
        const reachStartLable = e.target.dataset.reachstartlable
        const reachEnd = e.target.dataset.reachend
        const reachEndLabel = e.target.dataset.reachendlabel
        const multiple = e.target.dataset.multiple
        const sectionID = e.target.dataset.sectionid
        const id = e.target.dataset.id

        const fieldID  = uuid()

        db.collection('QuestionnaireFields')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: fieldID,
            Timestamp: timestamp,
            QuestionnaireID: questionaireId,
            Type: type,
            Question: ('Kopie van ' + question),
            Explainer: explainer,
            ReachStart: reachStart,
            ReachStartLable: reachStartLable,
            ReachEnd: reachEnd,
            ReachEndLabel: reachEndLabel,
            Position: sectionFields.length + 1,
            Multiple: multiple,
            SectionID: sectionID,
        })
        .then(() => {

            duplicateOptions(id, fieldID, question)
            duplicateMatrixRows(id, fieldID, question)
            duplicateMatrixColumns(id, fieldID, question)

        })
    }

    const duplicateOptions = (id, fieldID, question) => {

        db.collection('MultipleQuestionOptions')
        .where('CompagnyID', '==', client)
        .where('Field', '==', id)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MultipleQuestionOptions')
                .doc()
                .set({
                    Option: doc.data().Option,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('option duplicated')
                })
            })
        })
    }

    const duplicateMatrixRows = (id, fieldID, question) => {

        db.collection('MatrixQuestionRows')
        .where('Compagny', '==', client)
        .where('Field', '==', id)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MatrixQuestionRows')
                .doc()
                .set({
                    Title: doc.data().Title,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('matrix rows duplicated')
                })
            })
        })
    }

    const duplicateMatrixColumns = (id, fieldID, question) => {

        db.collection('MatrixQuestionColumns')
        .where('Compagny', '==', client)
        .where('Field', '==', id)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MatrixQuestionColumns')
                .doc()
                .set({
                    Title: doc.data().Title,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('matrix columns duplicated')
                })
            })
        })
    }



  return (
    <>
        {sectionFields && sectionFields.map((field, index) => (
            <div key={index} className="order-questionnaire-field-item-container" style={{backgroundColor: editQuestion === field.ID ? 'white' : 'transparent'}}>
                {placeholderIndex === index && (
                    <div className="placeholder-div" style={{backgroundColor: secundairyColor}}></div>
                )}

                <div id='section-order-field-container'>
                    <DraggableItems 
                    item={field}
                    input={
                        <input type="text" data-docid={field.docid} defaultValue={field.Question} onChange={titleHandler}/>
                        }
                    parentIndex={index}
                    collection={'QuestionnaireFields'}
                    itemArray={sectionFields}
                    setHoverIndex={setHoverIndex}
                    hoverIndex={hoverIndex}
                    title={field.Question}
                    sectionIdRef={sectionIdRef}
                    sectionRef={sectionRef}
                    setPlaceholderIndex={setPlaceholderIndex}
                    >
                    </DraggableItems>

                    <Tooltip content="Vraagtype aanpassen" width={'30px'} data-index={index}>
                        <img 
                        id='remove-field-from-section-icon'
                        src={type(field.Type)} 
                        alt="" 
                        data-id={field.ID}
                        data-index={index} 
                        onClick={editHandler}/>
                    </Tooltip>
                    <Tooltip content="Kopieren" width={'30px'} data-index={index}>
                        <img 
                        id='remove-field-from-section-icon'
                        src={copyIcon} 
                        alt="" 
                        data-id={field.ID}
                        data-index={index} 
                        data-questionaireid={field.QuestionnaireID}
                        data-type={field.Type}
                        data-question={field.Question}
                        data-explainer={field.Explainer}
                        data-reachstart={field.ReachStart}
                        data-reachstartlable={field.ReachStartLable}
                        data-reachend={field.ReachEnd}
                        data-reachendlabel={field.ReachEndLabel}
                        data-position={field.Position}
                        data-multiple={field.Multiple}
                        data-sectionid={field.SectionID}
                        data-color={field.Color}
                        data-title={field.Title}
                        onClick={duplicateHandler}/>
                    </Tooltip>
                    <Tooltip content='Uit sectie' width={'30px'}>
                        <img id='remove-field-from-section-icon' src={MoveOutIcon} alt="" data-docid={field.docid} onClick={removeFromSectionHandler}/>
                    </Tooltip>
                    <Tooltip content='Verwijder vraag' width={'30px'}>
                        <img id='remove-field-from-section-icon' src={deleteIcon} alt="" data-docid={field.docid} onClick={deleteQuestionHandler}/>
                    </Tooltip>
                </div>
                {editQuestion === field.ID &&
                    <QuestionnaireField 
                    field={field} 
                    index={index} 
                    position={sectionFields.length}
                    />
                }
            </div>
        ))}
    </>
  )
}

export default OrderQuestionnaireSectionFields