import { useFirestoreGeneral } from '../../firebase/useFirestore';
import PersonaGoalData from '../../components/personas/PersonaGoalData';
import { useState, useContext } from 'react';
import { Auth } from '../../StateManagment/Auth';
import { useHistory } from "react-router-dom";
import Presence from '../../components/personas/Dashboard/Presence';
import useSettings from "../../hooks/Settings";
import { useEffect } from 'react';

const Dashboard = ({ personaNavigation, item, courseStartDate, courseEndDate }) => {
  // Context
  const [auth] = useContext(Auth);

  // State
  const [showGoalDetails, setShowGoalDetails] = useState('');
  const [goalID, setGoalID] = useState('');

  // Hooks
  const history = useHistory();
  const secundairyColor = useSettings().SecundairyColor

  // Firestore
  const goals = useFirestoreGeneral('PersonaGoals', 'PersonaID', item.ID ? item.ID : '');

  // Set default goalID
  useEffect(() => {
    if (goals && goals.length > 0) {
      setGoalID(goals[0].ID);
    }
  }, [goals]);
  
  // Function to calculate the current date on the timeline
  const currentDate = (startDate, endDate) => {
    const currentDate = new Date();
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);
    const totalDays = (endDateObject - startDateObject) / (1000 * 60 * 60 * 24);
    const differenceInDaysFromStart = (currentDate - startDateObject) / (1000 * 60 * 60 * 24);
    const percentageDifference = (differenceInDaysFromStart / totalDays) * 100;

    return (
      <div id='timeline-current-date-container' style={{ width: `${percentageDifference}%` }}>
        <p>Vandaag</p>
        <div id='timeline-current-date'></div>
      </div>
    );
  };

  return (
    <div style={{ display: personaNavigation === 'dashboard' ? 'block' : 'none' }}>
      {/* <h2>Dashboard</h2> */}

      <div className='table-container dashboard-container'>
        <h2>Tijdlijn</h2>
        <div id='timeline-container'>
          <div>
            <h3>Startdatum</h3>
            <p>{item.StartDate}</p>
          </div>
          <div id='timeline-activities-container'>
            {currentDate(item.StartDate, item.EndDate)}
          </div>
          <div>
            <h3>Einddatum</h3>
            <p>{item.EndDate}</p>
          </div>
        </div>
      </div>

      <Presence item={item} courseStartDate={courseStartDate} courseEndDate={courseEndDate}/>

      <div className='dashboard-container'>
        <h2>Doelen</h2>
        <div className="select-activity-container select-activity-container-persona-goal-dashboard">
              <div className="select-activity-inner-container">
              {goals && goals.map(goal => (
                      <div key={goal.ID} data-id={goal.ID} data-title={goal.Goal} className='activity-select-item-container' onClick={() => setGoalID(goal.ID)} style={{backgroundColor: goalID === goal.ID ? secundairyColor : 'white'}}>
                          <p data-id={goal.ID} data-title={goal.Goal} onClick={() => setGoalID(goal.ID)} style={{color:  goalID === goal.ID ? 'white' : '#616161'}}>{goal.Goal}</p>
                      </div>
                  ))}
              </div>
          </div>
        
          {goals && goals.map(goal => (
            <div key={goal.ID} style={{display: goalID === goal.ID ? 'block' : 'none'}}>
              <PersonaGoalData goal={goal} personaId={item.ID} />
            </div>
          ))}
            
      </div>
     
    </div>
  );
};

export default Dashboard;
