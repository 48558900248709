import { useState, useEffect } from "react";
import translateString from "../components/AI/TranslateString";

const useTranslations = (strings, languageCode = "en") => {
    const [translations, setTranslations] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTranslations = async () => {
            const translateValue = async (value) => {
                if (typeof value === "string") {
                    let translatedText = await translateString({ string: value, languageCode });

                    // Replace "Vertaling niet beschikbaar" with an empty string
                    return translatedText === "Vertaling niet beschikbaar" ? "" : translatedText;
                } else if (typeof value === "object" && value !== null) {
                    // Recursively translate nested objects
                    const translatedObject = {};
                    for (const [nestedKey, nestedValue] of Object.entries(value)) {
                        translatedObject[nestedKey] = await translateValue(nestedValue);
                    }
                    return translatedObject;
                }
                return value;
            };

            const translationsObj = {};
            const promises = Object.entries(strings).map(async ([key, value]) => {
                translationsObj[key] = await translateValue(value);
            });

            await Promise.all(promises);

            setTranslations(translationsObj);
            setLoading(false);
        };

        if (strings && Object.keys(strings).length > 0) {
            fetchTranslations();
        } else {
            setLoading(false);
        }
    }, [strings, languageCode]);

    return { translations, loading };
};

export default useTranslations;

  
