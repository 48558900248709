import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useFirestoreOrderBy } from "../../firebase/useFirestore";
import { useHistory } from "react-router-dom";
import deleteIcon from '../../images/icons/delete-icon.png'
import { db, timestamp } from "../../firebase/config.js"
import uuid from "react-uuid";
import { client } from "../../hooks/Client"
import { useContext, useState } from "react";
import DraggableTableItems from "../../components/common/DraggableTableItems";
import Tooltip from "../../components/common/Tooltip";
import Settings from "../../hooks/Settings";
import AddItemTableRow from '../../components/common/AddItemTableRow.jsx';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const PersonaSettings = () => {
    // State
    const [hoverIndex, setHoverIndex] = useState(null);
    const [placeholderIndex, setPlaceholderIndex] = useState(null);

    // Hooks
    const { secundairyColor } = Settings()
    const history = useHistory()

    // Firestore
    const routes = useFirestoreOrderBy('PersonaRoutes', 'Position', 'asc')
    const methodes = useFirestoreOrderBy('PersonaMethodes', 'Position', 'asc')
    const reportStandards = useFirestoreOrderBy('PersonaReportStandard', 'Position', 'asc')

    const addRoute = () => {

        db.collection('PersonaRoutes')
        .add({
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Title: '',
            Position: routes.length + 1,
        })

    }

    const changeRouteTitle = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRoutes')
        .doc(docid)
        .update({
            Title: e.target.value
        })
    }

    const codeHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRoutes')
        .doc(docid)
        .update({
            Code: e.target.value
        })
    }

    const tariffHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRoutes')
        .doc(docid)
        .update({
            Tariff: parseInt(e.target.value)
        })
    }

    const unitHandler = (e) => {

        const docid = e.target.dataset.docid
        const value = e.target.options[e.target.selectedIndex].value

        db.collection('PersonaRoutes')
        .doc(docid)
        .update({
            Unit: value
        })
    }

    const deleteRoute = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaRoutes')
        .doc(docid)
        .delete()
    }

    // Add methode
    const addMethode = () => {
            
        db.collection('PersonaMethodes')
        .add({
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Title: '',
            Description: '',
            Position: methodes.length + 1,
        })
    }

    // Methode title handler
    const methodeTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaMethodes')
        .doc(docid)
        .update({
            Title: e.target.value
        })
    }

    // Delete methode
    const deleteMethode = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaMethodes')
        .doc(docid)
        .delete()
    }

    // Add standard
    const addStandard = () => {
            
        db.collection('PersonaReportStandard')
        .add({
            ID: uuid(),
            Compagny: client,
            CompagnyID: client,
            Timestamp: timestamp,
            Title: '',
            Description: '',
            Position: reportStandards.length + 1,
        })
    }

    // Standard title handler
    const standardTitleHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaReportStandard')
        .doc(docid)
        .update({
            Title: e.target.value
        })
    }

    // Delete standard
    const deleteStandard = (e) => {

        const docid = e.target.dataset.docid

        db.collection('PersonaReportStandard')
        .doc(docid)
        .delete()
    }

  return (
    <div className="main">
        <div className="main-container" >
            <div className='page-header'>
                <h1>Instellingen</h1>
            </div>
            <div className="table-container dashboard-container">
                <h2>Trajecten</h2>
                <table>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                        <th style={{backgroundColor: secundairyColor}}>CODE</th>
                        <th style={{backgroundColor: secundairyColor}}>TARIEF</th>
                        <th style={{backgroundColor: secundairyColor}}>EENHEID</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                    </tr>
                    {routes && routes.map((route, index) => (
                            <>
                            {placeholderIndex === index && (
                                <tr className="placeholder-row">
                                    <td style={{backgroundColor: secundairyColor}}></td>
                                </tr>
                            )}
                            <DraggableTableItems 
                            item={route}
                            input={
                                <Tooltip content={route.Title} width='80%' top='35px'>
                                    <input type="text" data-docid={route.docid} defaultValue={route.Title} onChange={changeRouteTitle} />
                                </Tooltip>
                            }
                            parentIndex={index}
                            collection={'PersonaRoutes'}
                            itemArray={routes}
                            setHoverIndex={setHoverIndex}
                            hoverIndex={hoverIndex}
                            title={route.Title}
                            setPlaceholderIndex={setPlaceholderIndex}
                            > 
                                <td>
                                    <input type="text" data-docid={route.docid} defaultValue={route.Code} onChange={codeHandler} />
                                </td>
                                <td>
                                    <input type="number" data-docid={route.docid} defaultValue={route.Tariff} onChange={tariffHandler} />
                                </td>
                                <td>
                                    <select value={route.Unit} data-docid={route.docid} onChange={unitHandler}>
                                        <option value=""> -- Selecteer een eenheid --</option>
                                        <option value="hour">Uur</option>
                                        <option value="halfday">Dagdeel</option>
                                        <option value="day">Dag</option>
                                        <option value="week">Week</option>
                                        <option value="month">Maand</option>
                                        <option value="year">Jaar</option>
                                    </select>
                                </td>
                                <td>
                                    <Tooltip content={'Verwijder traject'} width='80%' top='-60px'>
                                        <img className='table-delete-icon' data-id={route.ID} data-docid={route.docid} data-deletename={route.Title} onClick={deleteRoute} src={deleteIcon} alt="" />
                                    </Tooltip>
                                </td>
                            </DraggableTableItems>
                        </>
                    ))}
                </table>
                <AddItemTableRow toolTipContent={'Traject toevoegen'} onClick={addRoute}/>
            </div>

            {/* <div class="table-container dashboard-container">
                <h2>Rapportage standaard</h2>
                <table>
                <tr>
                    <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                    <th style={{backgroundColor: secundairyColor}}>OMSCHRIJVING</th>
                    <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                    <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                </tr>
                {reportStandards && reportStandards.map((standard, index) => (
                        <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={standard}
                        input={
                            <Tooltip content={standard.Title} width='80%' top='35px'>
                                <input type="text" data-docid={standard.docid} placeholder='Schrijf hier de titel van de methode' defaultValue={standard.Title} onChange={standardTitleHandler} />
                            </Tooltip>
                        }
                        parentIndex={index}
                        collection={'PersonaReportStandard'}
                        itemArray={reportStandards}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={standard.Title}
                        setPlaceholderIndex={setPlaceholderIndex}
                        > 
                            <td>
                                <p dangerouslySetInnerHTML={{__html: standard.Description?.substring(0, 50) + '...'}}></p>
                            </td>
                            <td>
                                <EditOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/personareportstandarddetails/${standard.ID}`)}/>
                            </td>
                            <td>
                                <Tooltip content={'Verwijder rapportagestandaard'} width='80%' top='-60px'>
                                    <img className='table-delete-icon' data-id={standard.ID} data-docid={standard.docid} data-deletename={standard.Title} onClick={deleteStandard} src={deleteIcon} alt="" />
                                </Tooltip>
                            </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
            <AddItemTableRow toolTipContent={'Rapportagestandaard toevoegen'} onClick={addStandard}/>


            </div> */}

            {/* <div class="table-container dashboard-container">
                <h2>Methodieken</h2>
                <table>
                <tr>
                    <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                    <th style={{backgroundColor: secundairyColor}}>OMSCHRIJVING</th>
                    <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                    <th style={{backgroundColor: secundairyColor}}>VERWIJDER</th>
                </tr>
                {methodes && methodes.map((methode, index) => (
                        <>
                        {placeholderIndex === index && (
                            <tr className="placeholder-row">
                                <td style={{backgroundColor: secundairyColor}}></td>
                            </tr>
                        )}
                        <DraggableTableItems 
                        item={methode}
                        input={
                            <Tooltip content={methode.Title} width='80%' top='35px'>
                                <input type="text" data-docid={methode.docid} placeholder='Schrijf hier de titel van de methode' defaultValue={methode.Title} onChange={methodeTitleHandler} />
                            </Tooltip>
                        }
                        parentIndex={index}
                        collection={'PersonaMethodes'}
                        itemArray={methodes}
                        setHoverIndex={setHoverIndex}
                        hoverIndex={hoverIndex}
                        title={methode.Title}
                        setPlaceholderIndex={setPlaceholderIndex}
                        > 
                            <td>
                                <p dangerouslySetInnerHTML={{__html: methode.Description?.substring(0, 50) + '...'}}></p>
                            </td>
                            <td>
                                <EditOutlinedIcon className='table-delete-icon' onClick={() => history.push(`/${client}/personamethodedetails/${methode.ID}`)}/>
                            </td>
                            <td>
                                <Tooltip content={'Verwijder methode'} width='80%' top='-60px'>
                                    <img className='table-delete-icon' data-id={methode.ID} data-docid={methode.docid} data-deletename={methode.Title} onClick={deleteMethode} src={deleteIcon} alt="" />
                                </Tooltip>
                            </td>
                        </DraggableTableItems>
                    </>
                ))}
            </table>
            <AddItemTableRow toolTipContent={'Methode toevoegen'} onClick={addMethode}/>

            </div> */}
        </div>
    </div>
  )
}

export default PersonaSettings