import firebase from 'firebase/app';
import { useState, useContext, useEffect, useRef } from "react"
import { Auth } from '../../StateManagment/Auth';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { db } from "../../firebase/config.js"
import { useFirestoreGeneralOrderBy, useFirestoreOrderBy } from "../../firebase/useFirestore";
import { client } from "../../hooks/Client";
import uuid from "react-uuid";
import { timestamp } from "../../firebase/config";
import robotImage from "../../images/icons/robot-icon.png"
import deleteImage from "../../images/icons/delete-icon.png"
import sendIcon from "../../images/icons/send-icon-gray.png"
import spinner from '../../images/spinner-ripple.svg'
import { useHistory } from 'react-router-dom';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import Tooltip from "../common/Tooltip";
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import { ImpactAI } from '../../StateManagment/ImpactAI';
import useSettings from "../../hooks/Settings";
import useScrollToBottom from './useScrollToBottom.js';
import useEnterToSend from './useEnterToSend';
import useDelayedOpenState from './useDelayedOpenState.js';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';

const ChatScreen = () => {

    // Context
    const [auth] = useContext(Auth)
    const [saved, setSaved] = useContext(SavedIcon)
    const {
        type: [type, setType],
        systemMessage: [systemMessage, setSystemMessage],
        toolCallContent: [toolCallContent, setToolCallContent],
        collection: [collection, setCollection],
        docid: [docid, setDocid],
        field: [field, setField],
        saveMessage: [saveMessage, setSaveMessage],
        startMessage: [startMessage, setStartMessage],
        exampleMessages: [exampleMessages, setExampleMessages],
        chatOpen: [chatOpen, setChatOpen],
        parentId: [parentId, setParentId],
        hightlighted: [hightlighted, setHightlighted],
        hightlightText: [hightlightText, setHightlightText],
        position: [position, setPosition],
        extraData: [extraData, setExtraData],
        newMessage: [message, setMessage], 
        sendMessageHandlerRef
    } = useContext(ImpactAI);

    // State
    const [loading, setLoading] = useState(false)
    const [loadingType, setLoadingType] = useState('')
    const [assistentThread, setAssistentThread] = useState('')
    const [delayedOpen, setDelayedOpen] = useState(false)

    // Refs
    const textareaRef = useRef(null);
    const chatContainerRef = useRef(null);

    // Helpers
    const options = {year: 'numeric', month: 'numeric', day: 'numeric' };

    // Firebase
    const messages = useFirestoreGeneralOrderBy('Messages', 'Type', type ? type : '', 'Position', 'asc')
    const questionnaires = useFirestoreOrderBy('Questionnaires', 'Position', 'asc')

    // Set the hightlighted text
    useEffect(() => {
      setMessage(hightlightText)
    }, [hightlighted])
  
     // Handle the delayed open state
     useDelayedOpenState(chatOpen, setDelayedOpen)

    // Always scroll to the bottom of the chat when a new message is added
    useScrollToBottom(messages, chatContainerRef)

     // Handle the message input field
    const messageHandler = (e) => {

      // Set the message in the input field to the state
      setMessage(e.target.value)

    }

    console.log(message)

    const sendMessageHandler = async () => {

      console.log(message)
      console.log('test')

      // Format the new message for the OpenAI API
      const newMessage = {"role": "user", "content": message}

      // Build the messages array
      const messagesArray = [
          systemMessage,
          { role: 'assistant', content: startMessage },
          ...messages && messages.map((message) => ({
              role: message.Role,
              content: message.Message,
          })),
          newMessage
      ];

      // Set the messages array in the state
      setAssistentThread(messagesArray);

      // Display the loading icon
      setLoading(true);
      setLoadingType('thinking');

      // Save the message in the database
      saveNewMessage(message);

      // Clear the input field message
      setMessage('');

      try {
          // Get an instance of the function using the httpsCallable method
          const completionOnCall = firebase.functions().httpsCallable('completionOnCall');

          // Call the function and pass the data
          const result = await completionOnCall({ messages: messagesArray });

          // The result of the function is the answer
          const answer = result.data;

          const { tool_calls: toolCalls, content: response } = answer;

          console.log(toolCalls);
          console.log(response);

          // Check for tool calls and handle accordingly
          if (Array.isArray(toolCalls) && toolCalls.length) {
              for (const toolCall of toolCalls) {
                  if (toolCall.function.name === 'save') {
                      toolCallSave();
                  }
              }
          }

          // If there’s a response, update the UI and database
          if (response) {
              setLoading(false); // Hide loading icon
              saveResponse(response); // Save the answer in the database
          }
      } catch (error) {
          console.error("Error in sendMessageHandler:", error);
          setLoading(false); // Hide loading icon in case of error
      }
    };

    // Send message by pressing enter
    useEnterToSend(textareaRef, sendMessageHandler);
    
    // Function to handle the save to database toolcall
    const toolCallSave = () => {
        console.log('save');

         // Set the loading icon to display none
         setLoading(true)
         setLoadingType('saving')

        // Get an instance of the JSON function using the httpsCallable method
        const completionOnCallJSON = firebase.functions().httpsCallable('completionOnCallJSON') 

        // Create a new message object with the toolCallContent as content
        const newMessage = { role: "user", content: toolCallContent };

        // Update the thread with the new message
        const updatedThread = [...assistentThread, newMessage];

        console.log(updatedThread);

        // Call the completion function with the updated thread
        completionOnCallJSON({ messages: updatedThread })
        .then((summary) => {
            console.log(summary);

            // Suggestion is always returned as JSON
            const suggestion = summary.data.content;

            // Parse the suggestion to an array
            const array = [JSON.parse(suggestion)]

            console.log(array);
            console.log(type)
            console.log(docid)

            // If a specific field needs to be updated
            if(type === 'goal' || type === 'problem' || type === 'context' || type.startsWith('liveReportBuilder')) {
                array && array.map(item => {
                    saveSuggestionDocid(item.item);
                })
            // If a new document needs to be created
            } else if (type === 'stakeholder') {
                array && array[0].array.map(item => {

                    const set = {
                        Organisation: item.item,
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        Position: array.length + 1,
                        Categorie: item.category
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'activity') {
                array && array[0].array.map(item => {

                    const set = {
                        Activity: item.item,
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        Position: array.length + 1,
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'effect') {
                array && array[0].array.map(item => {

                    const set = {
                        Effect: item.item,
                        Position: array.length + 1,
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp
                    }

                    saveSuggestion(set);
                })
                
            } else if (type === 'indicator') {
                array && array[0].array.map(item => {

                    const set = {
                       Compagny: client,
                        CompagnyID: client,
                        ID: uuid(),
                        Timestamp: timestamp,
                        QuestionnaireID: '',
                        Type: 'scale',
                        Question: item.item,
                        Explainer: '',
                        ReachStart: 0,
                        ReachStartLable: '',
                        ReachEnd: 5,
                        ReachEndLabel: '',
                        Multiple: [],
                        SectionID: '',
                        EffectId:  firebase.firestore.FieldValue.arrayUnion(parentId),
                        Indicator: 'true'
                    }

                    saveSuggestion(set);
                })
                
            } else if (type === 'output') {

                array && array[0].array.map(item => {

                    const set = {
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        ActivityID: item.activityID,
                        Title: item.item,
                        Position: array.length + 1,
                        Color: '#47acc3'
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'questionaire') {

                array && array[0].array.map(item => {

                    const set = {
                        ID: uuid(),
                        Timestamp: timestamp,
                        Compagny: client,
                        CompagnyID: client,
                        EvidenceBased: false,
                        Position: array.length + 1,
                        Title: item.item,
                    }

                    saveSuggestion(set);
                })
            } else if (type.startsWith('questionaireFields')) {

                array && array[0].array.map(item => {

                    const set = {
                        Compagny: client,
                        CompagnyID: client,
                        ID: uuid(),
                        Timestamp: timestamp,
                        QuestionnaireID: parentId,
                        Type: 'scale',
                        Question: item.item,
                        Explainer: '',
                        ReachStart: 1,
                        ReachStartLable: 'Helemaal niet',
                        ReachEnd: 5,
                        ReachEndLabel: 'Helemaal wel',
                        Key: uuid(),
                        Position: position,
                        Multiple: [],
                        SectionID: ''
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'research') {

                array && array[0].array.map(item => {

                    const questionnaireID = uuid()

                    const set = {
                        ID: uuid(),
                        Timestamp: timestamp,
                        Compagny: client,
                        CompagnyID: client,
                        QuestionnaireID: questionnaireID,
                        QuestionnaireTitle: '',
                        Position: array.length + 1,
                        Title: item.item,
                    }

                    const then = () => db.collection('Questionnaires')
                    .doc()
                    .set({
                        ID: questionnaireID,
                        Timestamp: timestamp,
                        Compagny: client,
                        CompagnyID: client,
                        EvidenceBased: false,
                        Position: questionnaires.length + 1,
                        Title: 'Vragenlijst' + ' ' + item.item,
                    })

                    saveSuggestion(set, then);
                })
            }else if (type === 'categorizeResponse') {

                array && array[0].array.map(item => {

                    const ID = uuid()

                    const set = {
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        FieldID: parentId,
                        ResearchID: '',
                        Categorie: item.item,
                        Color: '#47acc3',
                    }

                    saveSuggestion(set);
                })
            } else if (type === 'researchBuilder') {

                array && array[0].array.map(item => {

                    const set = {
                        ResearchID: parentId,
                        Moment: new Date(),
                        Title: item.item,
                        ID: uuid(),
                        Compagny: client,
                        CompagnyID: client,
                        Timestamp: timestamp,
                        QuestionnaireID: extraData,
                        Responses: 0,
                        Position: position
                    }

                    saveSuggestion(set);
                })
            }else if (type === 'liveReportTitle') {

                array && array[0].array.map(item => {

                    const set = {
                        Title: item.item,
                        Timestamp: timestamp,
                        Compagny: client,
                        CompagnyID: client,
                        Position: array.length + 1,
                        ID: uuid(),
                        Banner: '',
                        TargetgroupTitle: 'Onze doelgroep',
                        ActivityTitle: 'Onze activiteiten',
                        KPITitle: 'Onze impact',
                        Start: null,
                        End: null
                    }

                    saveSuggestion(set);
                })
            }
        })
        .catch((error) => {
            // It's good practice to also handle any potential errors
            console.error('Error when calling completionOnCall:', error);
            setLoading(false)
            saveResponse(`Er is iets mis gegaan: ${error}: ${timestamp.toDate().toLocaleDateString("nl-NL", options)}. Probeer het opnieuwd en mocht het probleem zich blijven voordoen neem dan contact op met info@decocs.nl`)
        });

    }

    // Assign the sendMessageHanlder function to the ref when the component mounts. 
    // This makes the function accessible via the context. 
    // This way a message can be send from another component, like PageHeader
    useEffect(() => {
      sendMessageHandlerRef.current = sendMessageHandler;
  }, [sendMessageHandlerRef]); // Run once when component mounts

    // Save the new message in the database
    const saveNewMessage = async (message) => {

        await db.collection('Messages')
        .doc()
        .set({
            Message: message,
            Timestamp: timestamp,
            CompagnyID: client,
            User: auth?.ID || '',
            ID: uuid(),
            Type: type,
            Role: 'user',
            Position: messages.length + 1,
            ID: uuid()
        })
    }

    // Save the response in the database
    const saveResponse = (response) => {

        db.collection('Messages')
        .doc()
        .set({
            Message: response,
            Timestamp: timestamp,
            CompagnyID: client,
            User: auth?.ID,
            ID: uuid(),
            Type: type,
            Role: 'assistant',
            Position: messages.length + 2,
            ID: uuid()
        })
    }

    // Save the suggestion
    const saveSuggestion = ( set, then) => {

        db.collection(collection)
        .doc()
        .set(set)
        .then(() => {
            setLoading(false)
            setSaved(true)
            saveResponse(saveMessage)
            setLoadingType('none')
            
        })
        .then(() => {
            console.log('suggestion saved')
        })
        .then(() => {
            then && then()
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
        });
    }

    // Save the suggestion with the docid
    const saveSuggestionDocid = (suggestion) => {

        console.log(suggestion)
        console.log(docid)
        console.log(collection)

        db.collection(collection)
        .doc(docid)
        .update({
            [field]: suggestion
        })
        .then(() => {
            setSaved(true)
            saveResponse(saveMessage)
            setLoadingType('none')
            
        })
        .then(() => {
            console.log('Suggestion saved')
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
        });
    }

    // Set the style of the message
    const messageStyle = (role) => {

        if(role === 'user') {
            return 'message-container-user'
        } else {
            return 'message-container-assistant'
        }
    }

    // Set the image of the message
    const messageImage = (role) => {

        if(role === 'user') {
            return auth?.Photo
        } else {
            return robotImage
        }
    }

    // Delete a message
    const deleteMessage = (e) => {

        const docid = e.target.dataset.docid 

        db.collection('Messages')
        .doc(docid)
        .delete()

    }

    // Set the font weight of the message
    const messageFontWeigth = (position) => {

        if(position === 0){
            return 'bold'
        }
    }

    // Display the delete icon
    const displayDeleteIcon = (position) => {

        if(position === 0){
            return 'none'
        } else {
            return 'block'
        }
    }

    // Set example message to the input field
    const sendExampleMessage = (e) => {

        const message = e.target.dataset.message

        // Set the message to the input field and the state
        setMessage(message)

    }

    return (
        <div id='impactai-toggle-container' >
            <div id='leftsidebar-toggle-item-container' >
                <div style={{display: chatOpen ? 'block' : 'none'}} onClick={() => setChatOpen(false)}>
                    <Tooltip content='ImpactAI inklappen'>
                        <KeyboardDoubleArrowRightOutlinedIcon/>
                    </Tooltip>
                </div>
                <div style={{display: chatOpen ? 'none' : 'block'}} onClick={() => setChatOpen(true)}>
                    <Tooltip content='ImpactAI uitklappen' >
                        <KeyboardDoubleArrowLeftOutlinedIcon className='impactai-toggle'/>
                    </Tooltip>
                </div>
            </div>
            <div id='closed-chatscreen-icon-container' style={{display: chatOpen ? 'none' : 'flex'}}>
                <img src={robotImage} alt="" id='robot-image-chatscreen'/>
            </div>
            <div className="impactai-container" style={{width: chatOpen ? '350px' : '0px'}}>
                <div id='impactai-logo-container' style={{display: delayedOpen ? 'flex' : 'none'}}>
                    <img src={robotImage} alt="" id='robot-image-chatscreen'/>
                    <h3>Chat met ImpactAI</h3>
                </div> 
                
                <div className="leftsidebar-seperator"></div>

                <div id='chat-screen-container' style={{display: delayedOpen ? 'flex' : 'none'}}>
                    <div id='chat-text-container' ref={chatContainerRef}>
                        <div className='message-container-assistant'>
                            <img src={robotImage} alt="" />
                            <div className='chat-text-options-container'>
                                <p style={{fontWeight: 'bold'}}>{startMessage}</p>
                            </div>
                        </div>
                        {messages && messages.map(message => (
                            <div key={message.ID} className={message.Role === 'user' ? 'message-container-user' : 'message-container-assistant'}>
                                <img src={messageImage(message.Role)} alt="" />
                                <div className={message.Role === 'user' ? 'chat-text-options-user-container' : 'chat-text-options-assistant-container'}>
                                    <p style={{fontWeight: messageFontWeigth(message.Position)}} dangerouslySetInnerHTML={{__html: message.Message}}></p>
                                    <div id='chat-screen-text-delete-container'>
                                        <img id='message-delete-icon' data-docid={message.docid} src={deleteImage} alt="" onClick={deleteMessage} style={{display: displayDeleteIcon(message.Position)}} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div style={{display: loading ? 'flex' : 'none'}} className="message-container-assistant">
                            <img src={robotImage} alt="" />
                            <p style={{display: loadingType === 'thinking' ? 'block' : 'none'}}>Nadenken... &#129504;</p>
                            <div id='chatscreen-saving-container' style={{display: loadingType === 'saving' ? 'flex' : 'none'}}>
                                <img src={spinner} alt="" />
                                <p>Opslaan...</p>
                            </div>
                            <p style={{display: loadingType === 'redirect' ? 'block' : 'none'}}>Doorsturen...</p>
                        </div>
                   
                    </div>
                    <div id='chat-input-container' style={{boxShadow: hightlighted ? '0 0 8px green' : 'none'}}>
                        <textarea type="text" ref={textareaRef} placeholder="Stuur een bericht" value={message} onChange={messageHandler} />
                        <img src={sendIcon} alt="" onClick={sendMessageHandler}/>
                    </div>
                    <div id='example-messages-container' style={{display: exampleMessages.length > 1 ? 'flex' : 'none'}}>
                        <p id='example-question-title'>Voorbeeld vragen:</p>
                        <div id='example-messages-messages-container'>
                            {exampleMessages && exampleMessages.map(message => (
                                <div key={uuid()} className='example-message' data-message={message} onClick={sendExampleMessage}>
                                    <p>{message}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div id='chatscreen-helpdesk-container' style={{display: delayedOpen ? 'flex' : 'none'}}>
                        <p>Kom je er niet uit? <br></br>Neem contact op met de <a href={`/${client}/helpdesk`}>helpdesk</a></p>
                    </div>
                </div>
            </div>
        </div>
      )

}

export default ChatScreen
