import Fields from "./Fields"
import useTranslations from "../../hooks/useTranslations";
import { useMemo } from "react";

const TopLevelFields = ({field, index, setFieldIndex, fieldIndex, compagnyId, questionaire, languageCode,  topLevelFields}) => {

  // Translations
  const stringsToTranslate = useMemo(() => {
    if (!questionaire) return {};

    return {
       "Volgende": "Volgende",
       "Terug": "Terug",
    };
  }, [questionaire]);

  const { translations, loading } = useTranslations(stringsToTranslate, languageCode ? languageCode : 'nl');

  return (
    <div style={{display: index !== fieldIndex ? 'none' : 'flex' }} className="top-level-field-container">
        <Fields 
        field={field} 
        compagnyId={compagnyId} 
        questionaire={questionaire}
        languageCode={languageCode}
        />
        <div className="questionnaire-button-container">
            <button 
            style={{display: fieldIndex === 0 ? 'none' : 'block'}} 
            onClick={() => setFieldIndex(fieldIndex - 1)}>
              {translations?.["Terug"] || "Terug"}
            </button>
            <button onClick={() => setFieldIndex(index + 1)}>
            {translations?.["Volgende"] || "Volgende"}
            </button>
        </div>
        
    </div>
  )
}

export default TopLevelFields