import { useEffect, useState } from 'react';
import { db } from '../../firebase/config';
import { client } from '../../hooks/Client';
import { useFirestoreGeneral, useFirestoreNoCompagnyThree } from '../../firebase/useFirestore'
import firebase from 'firebase/app'
import OutputMultiLineGraph from '../Visualisations/outputs/OutputMultiLineGraph'
import useSettings from '../../hooks/Settings';

const OutputData = ({activity, startDate, endDate, graphType}) => {
      // State
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [growth, setGrowth] = useState({ perOutput: {}, total: 0 });

  // Hooks
  const portfolio = useSettings().portfolio

  // Firestore
  const outputs = useFirestoreGeneral('Outputs', 'ActivityID', activity ? activity : '')
  const activityData = useFirestoreGeneral('Activities', 'ID', activity? activity : '')

  // Default start and end date if dates are undefined
  const defaultStartDate = firebase.firestore.Timestamp.fromDate(new Date(2000, 1, 1));
  const defaultEndDate = firebase.firestore.Timestamp.fromDate(new Date());

  // Helper function to get the results for the syncronisations if the client is a portfolio manager
  const getSyncResults = async (output) => {
  
    // 1. Get the synchronisations docs
    const syncsQuerySnapshot = await db
      .collection("Synchronisations")
      .where("Output", "==", output)
      .where("Type", "==", "Output")
      .where("Status", "==", "paired")
      .get();
  
    // If there are no syncs, return [0] early
    if (syncsQuerySnapshot.empty) {
      // Do nothing
    }
  
    const array = [];
  
    // 2. Iterate over the docs in the "Synchronisations" query
    for (const doc of syncsQuerySnapshot.docs) {
      const item = doc.data();
      const projectId = item.Project;
      const projectOutput = item.ProjectOutput;
  
      // 3. Query "Results" collection for each item
      const querySnapshot = await db
        .collection("Results")
        .where("CompagnyID", "==", projectId)
        .where("OutputID", "==", projectOutput)
        .where("Timestamp", ">=", startDate || defaultStartDate)
        .where("Timestamp", "<=", endDate || defaultEndDate)
        .get();
  
      // 4. Push data into the array
      if (querySnapshot.empty) {
        // Do nothing   
      } else {
        // Map over each doc to get its data
        const data = querySnapshot.docs.map((doc) => doc.data());
        array.push(data);
      }
    }

    return array.flat();
  };
  

  // Get the output results for each date
  const outputResults = async (output) => {
    const array = [];
  
    const querySnapshot = await db
      .collection('Results')
      .where('CompagnyID', '==', client)
      .where('OutputID', '==', output)
      .where('Timestamp', '>=', startDate || defaultStartDate)
      .where('Timestamp', '<=', endDate || defaultEndDate)
      .get();
  
    if (querySnapshot.empty) {
      // Do nothing
    } else {
      // Otherwise, push the docs' data
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        array.push(data);
      });
    }
  
    return array;
  };
  

  // Query output results
  const queryResults = async () => {
    try {
      setIsLoading(true); // Start loading

      const array = [];
  
        // Query the local output
        const outputsSnapshot = await db.collection('Outputs')
          .where('CompagnyID', '==', client)
          .where('ActivityID', '==', activity)
          .get();
  
        // Get the results for each output
        await Promise.all(outputsSnapshot.docs.map(async (output) => {
          // Get the local results
          const results = await outputResults(output.data().ID);

          // Get the sync results
          const syncResults = await getSyncResults(output.data().ID);

          // Merge the results
          const mergedResults = results.concat(syncResults);

          // Push the merged results into the array
          array.push(...mergedResults);
        }));


      // 4) Now we have a flat array of all raw docs. Sort by Timestamp.
      array.sort((a, b) => a.Timestamp - b.Timestamp);

      // 5) Transform this flat array to the shape your second component expects
      //    => one object per date, `name: dateString`, plus each outputTitle as a key
      const shapedData = await transformData(array);

      // 6) Use shapedData with your existing logic
      shapedData.length > 0 && addResults(shapedData); // This does cumulative if line
      shapedData.length > 0 && calculateGrowth(shapedData);

      setData(shapedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setData([]);
      // Handle error appropriately
    } finally {
      setIsLoading(false); // Stop loading whether there was an error or not
    }
  };

  // Helper to get the output title from the output ID
  const getOutputTitle = async (outputID) => {
    const outputSnapshot = await db.collection('Outputs')
    .where('ID', '==', outputID)
    .get();

    if (outputSnapshot.empty) {
      return 'Unknown Output';
    }

    return outputSnapshot.docs[0].data().Title;
  };

  // Helper to get the project name from the project ID
  const getProjectName = async (projectID) => {
    const projectSnapshot = await db.collection('CompagnyMeta')
    .where('CompagnyID', '==', projectID)
    .get();

    if (projectSnapshot.empty) {
      return 'Unknown Project';
    }

    return projectSnapshot.docs[0].data().CommunityName;
  };

    // Helper to shape raw docs into the date-based objects
    const transformData = async (rawArray) => {
      const groupedByDate = {};
    
      // Process each raw document
      for (const docData of rawArray) {
        // Convert Firestore timestamp to a date string (for example "dd-mm-yyyy")
        const dateObj = docData.Timestamp.toDate();
        const dateString = dateObj.toLocaleDateString("nl-NL");
    
        // Retrieve the output title and project name
        const title = await getOutputTitle(docData.OutputID);
        const projectName = await getProjectName(docData.CompagnyID);
    
        // Make sure we have a numeric value (or default to 0)
        const numericValue = typeof docData.Result === 'number' ? docData.Result : 0;
    
        // Initialize the date bucket if needed
        if (!groupedByDate[dateString]) {
          groupedByDate[dateString] = { name: dateString };
        }
    
        // Initialize the output object on that date if needed
        if (!groupedByDate[dateString][title]) {
          groupedByDate[dateString][title] = {
            total: 0,
            projects: {}
          };
        }
    
        // Add the numeric value to the overall total for this output on that date
        groupedByDate[dateString][title].total += numericValue;
    
        // Add the numeric value to the appropriate project bucket
        if (!groupedByDate[dateString][title].projects[projectName]) {
          groupedByDate[dateString][title].projects[projectName] = 0;
        }
        groupedByDate[dateString][title].projects[projectName] += numericValue;
      }
    
      // (Optional) If you need a flat array of dates, simply get the values of the grouped object.
      const shapedArray = Object.values(groupedByDate);
    
      // Optionally sort shapedArray by the actual date value (if needed)
      shapedArray.sort((a, b) => {
        const [dayA, monthA, yearA] = a.name.split("-");
        const [dayB, monthB, yearB] = b.name.split("-");
        return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
      });
  
      return shapedArray;
    };
    


  // This code is used to process data for different types of graphs: a line graph requires cumulative data, whereas a bar graph does not need this processing.
  const addResults = (array) => {
    if (graphType === 'line' && array.length > 0) {
      // For each row starting from the second one...
      for (let i = 1; i < array.length; i++) {
        const currentRow = array[i];
        const previousRow = array[i - 1];
  
        // Iterate over each output key in the previous row (skip 'name')
        Object.keys(previousRow).forEach((key) => {
          if (key === 'name') return;
  
          // If the current row is missing this output key, copy it entirely
          if (!currentRow.hasOwnProperty(key)) {
            currentRow[key] = JSON.parse(JSON.stringify(previousRow[key]));
          } else {
            // Otherwise, for the overall total:
            // If the current raw total is 0, then carry forward the previous row’s cumulative total.
            // Otherwise, add the previous row’s cumulative total.
            if (currentRow[key].total === 0) {
              currentRow[key].total = previousRow[key].total;
            } else {
              currentRow[key].total += previousRow[key].total;
            }
  
            // Now for each project in the previous row’s projects:
            Object.keys(previousRow[key].projects).forEach((project) => {
              // If the current row does not have this project (or its value is 0),
              // use the previous row’s cumulative value.
              if (
                !currentRow[key].projects.hasOwnProperty(project) ||
                currentRow[key].projects[project] === 0
              ) {
                currentRow[key].projects[project] = previousRow[key].projects[project];
              } else {
                // Otherwise, add the previous row’s cumulative value.
                currentRow[key].projects[project] += previousRow[key].projects[project];
              }
            });
          }
        });
      }
    }
  };
  
  
  

  // Helper function to calculate growth
  const calculateGrowth = (data) => {
    if (data.length === 0) return;
  
    const perOutputGrowth = {};
    const perProjectGrowth = {};
    const averageGrowthArray = [];
  
    const firstEntry = data[0]; // The first date's data
    const lastEntry = data[data.length - 1]; // The last date's data
  
    // Iterate over each output key (except 'name')
    Object.keys(lastEntry).forEach((key) => {
      if (key === "name") return;
  
      const lastTotal = lastEntry[key].total;
      const firstTotal = (firstEntry[key] && firstEntry[key].total) || 0;
  
      let outputGrowth = 0;
      if (firstTotal !== 0) {
        outputGrowth = ((lastTotal - firstTotal) / firstTotal) * 100;
      } else {
        outputGrowth = lastTotal === 0 ? 0 : lastTotal * 100;
      }
      perOutputGrowth[key] = outputGrowth;
      averageGrowthArray.push(outputGrowth);
  
      // Calculate growth for each project within the output
      perProjectGrowth[key] = {};
      const projectsLast = lastEntry[key].projects;
      const projectsFirst = (firstEntry[key] && firstEntry[key].projects) || {};
  
      Object.keys(projectsLast).forEach((project) => {
        const lastProjectVal = projectsLast[project];
        const firstProjectVal = projectsFirst[project] || 0;
  
        let projectGrowth = 0;
        if (firstProjectVal !== 0) {
          projectGrowth = ((lastProjectVal - firstProjectVal) / firstProjectVal) * 100;
        } else {
          projectGrowth = lastProjectVal === 0 ? 0 : lastProjectVal * 100;
        }
  
        perProjectGrowth[key][project] = projectGrowth;
      });
    });
  
    const averageGrowth =
      averageGrowthArray.reduce((sum, growth) => sum + growth, 0) /
      averageGrowthArray.length;
  
    setGrowth({
      perOutput: perOutputGrowth,
      perProject: perProjectGrowth,
      average: averageGrowth,
    });
  };
  

  // Build the data array when the outputs, activity, activityData, graphType, startDate, or endDate change
  useEffect(() => {
    queryResults();
}, [outputs, activity, activityData, graphType, startDate, endDate]);

  return (
    <>
        <OutputMultiLineGraph 
        data={data} 
        isLoading={isLoading} 
        outputs={outputs} 
        graphType={graphType} 
        growth={growth} 
        height={400} 
        portfolio={portfolio}
        />
    </>
  )
}

export default OutputData