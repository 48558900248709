import firebase from "firebase";

const translateString = async ({string, languageCode}) => {

    console.log(languageCode)

    try {

        if(languageCode){
            const translateText = firebase.functions().httpsCallable("translateText");

            const result = await translateText({
                text: string,
                targetLanguage: languageCode || 'nl',
            })

            const translation =  result.data.translatedText

            return translation
        } else {
            return string
        }

    } catch (error) {
        console.log(error.message)
        return 'Vertaling niet beschikbaar'
    }
}

export default translateString