import Calendar from "../../components/common/Calender";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { useState, useEffect } from "react";
import { useFirestore } from "../../firebase/useFirestore";
import { db } from "../../firebase/config";
import { format, subWeeks, nextDay, addWeeks } from 'date-fns';
import { client } from "../../hooks/Client"

const Agenda = () => {
  const [events, setEvents] = useState([]);

  const shedules = useFirestore('PersonaShedule');

  // Get the event title
  const eventTitle = async (personaDocid) => {
    let personaName = '';
    await db.collection('Personas')
      .doc(personaDocid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          personaName = doc.data().Name;
        } else {
          console.log('No such document!');
        }
      });
    return personaName;
  };

  // Get the persona status
  const personaStatus = async (personaDocid) => {
    let status = '';
    await db.collection('Personas')
      .doc(personaDocid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          status = doc.data().Status;
        } else {
          console.log('No such document!');
        }
      });
    return status;
  };

  // Format the date
  const formatDutchDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('nl-NL', { 
        weekday: 'long', 
        day: 'numeric', 
        month: 'long', 
        year: 'numeric' 
    }).format(date);
};

  // Get the presence report
  const getPresenceReport = async (personaID, day) => {
    let presenceReportId = '';

    const formattedDate = formatDutchDate(day);

     const snapshot = await db.collection('PersonaPresenceReports')
      .where('CompagnyID', '==', client)
      .where('PersonaID', '==', personaID ? personaID : '')
      .where('Date', '==', formattedDate ? formattedDate : '')
      .get()

      for (const doc of snapshot.docs) {
          presenceReportId = doc.data().ID;
      };

    return presenceReportId;
  };

  const getNext7Weekdays = (startDate, weekday) => {
    let currentDay = nextDay(startDate, weekday);
    let previousDay = subWeeks(currentDay, 1);
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.unshift(format(previousDay, 'yyyy-MM-dd'));
      previousDay = subWeeks(previousDay, 1);
    }
    currentDay = nextDay(startDate, weekday);
    for (let i = 0; i < 7; i++) {
      weekDays.push(format(currentDay, 'yyyy-MM-dd'));
      currentDay = addWeeks(currentDay, 1);
    }
    return weekDays;
  };

  const createEvents = async (weekday, item, start, end) => {
    const next7Days = getNext7Weekdays(new Date(), weekday);
    const eventsToAdd = await Promise.all(next7Days.map(async day => {
      const title = await eventTitle(item.PersonaDocid);
      const status = await personaStatus(item.PersonaDocid);
      const presenceReportId = await getPresenceReport(item.PersonaID, day);

      return {
        Title: title,
        Deadline: day,
        Time: `${start} - ${end}`,
        Status: status,
        Type: 'shedule',
        Id: item.docid,
        PersonaId: item.PersonaID,
        PresenceReportId: presenceReportId,
        Start: item.StartTime,
        End: item.EndTime
      };
    }));
    setEvents(prevEvents => [...prevEvents, ...eventsToAdd]);
  };

  const createSheduleEvents = () => {
    if (shedules) {
      for (let item of shedules) {
        const sheduleDay = item.Day || "";
        // Monday
        if (sheduleDay === 'monday') {
          createEvents(1, item, item.StartTime, item.EndTime);
        }
        // Tuesday
        if (sheduleDay === 'tuesday') {
          createEvents(2, item, item.StartTime, item.EndTime);
        }
        // Wednesday
        if (sheduleDay === 'wednesday') {
          createEvents(3, item, item.StartTime, item.EndTime);
        }
        // Thursday
        if (sheduleDay === 'thursday') {
          createEvents(4, item, item.StartTime, item.EndTime);
        }
        // Friday
        if (sheduleDay === 'friday') {
          createEvents(5, item, item.StartTime, item.EndTime);
        }
        // Saturday
        if (sheduleDay === 'saturday') {
          createEvents(6, item, item.StartTime, item.EndTime);
        }
        // Sunday
        if (sheduleDay === 'sunday') {
          createEvents(0, item, item.StartTime, item.EndTime);
      }
    }
  };
};

  useEffect(() => {
    if (shedules) {
      createSheduleEvents();
    }
  }, [shedules]);

  return (
    <div className="main">
      <div className="main-container">
        <div className="page-header">
          <DateRangeOutlinedIcon />
          <h1>Planning</h1>
        </div>
        <div id='agenda-calender-container'>
          <Calendar events={events} />
        </div>
      </div>
    </div>
  );
};

export default Agenda;