import Modal from 'react-modal';
import SaveFile from '../../hooks/SaveFile'

const NewProjectModal = ({newProjectModal, setNewProjectModal, setProjectName, setLogo, setWebsite, logo, addProject}) => {

    Modal.setAppElement('#root');

    const modalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

  return (
    <Modal
    isOpen={newProjectModal}
    onRequestClose={newProjectModal}
    style={modalStyles}
    contentLabel="Add new project"
    >
      <div>
        <div id='delete-modal-title-container'>
          <h1>Nieuw project toevoegen</h1>
        </div>
        <div>
            <div className='add-new-project-modal-input-container'>
                <label><b>Projectnaam</b></label>
                <input type='text' onChange={(e) => setProjectName(e.target.value)} />
            </div>
            <div className='add-new-project-modal-input-container'>
                <label><b>Logo</b></label>
                <img src={logo} alt='' id='add-new-project-modal-image'/>
                <input type='file' onChange={(e) => SaveFile(e, setLogo)} />
            </div>
            <div className='add-new-project-modal-input-container'>
            <label><b>Website</b></label>
            <input type='text' placeholder='Schrijf hier de website' onChange={(e) => setWebsite(e.target.value)} />
        </div>
        </div>
        
        <div id='delete-modal-button-container'>
          <button onClick={() => setNewProjectModal(false)}>Annuleren</button>
          <button id='delete-modal-delete-button' onClick={addProject} >Opslaan</button>
        </div>
      </div>
  </Modal>
  )
}

export default NewProjectModal