import { useState, useEffect } from 'react';
import { useFirestoreGeneral,  useFirestoreGeneralArrayContainsOrderBy, useFirestoreGeneralOrderBy, useFirestoreOrderBy } from '../../firebase/useFirestore';
import { db, timestamp } from '../../firebase/config';
import { v4 as uuid } from 'uuid';
import { SavedIcon } from "../../StateManagment/SavedIcon";
import { useContext } from "react";
import useSettings from "../../hooks/Settings";
import Tooltip from '../../components/common/Tooltip'
import deleteIcon from '../../images/icons/delete-icon.png'
import DraggableTableItems from '../../components/common/DraggableTableItems'
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import ActivityMeta from '../../components/activities/ActivityMeta.jsx';
import React from 'react';
import SelectOutput from '../../components/outputs/SelectOutput.jsx';
import { client } from '../../hooks/Client'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

const Activities = ({personaNavigation, personaId}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // State
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null)
    const [showAddContainer, setShowAddContainer] = useState(false)
    const [selectedActivity, setSelectedActivity] = useState('')
     const [dragArrayActivities, setDragArrayActivities] = useState([]);

    // Hooks
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const secundairyColor = useSettings().SecundairyColor

    // Firestore
    const activities = useFirestoreOrderBy('Activities', 'Position', 'asc')
    const personaActivities = useFirestoreGeneralOrderBy('PersonaActivities', 'PersonaID', personaId ? personaId : '', 'Position', 'asc')
    const events =  useFirestoreGeneralArrayContainsOrderBy('Events', 'Personas', personaId ? personaId : '', 'Date', 'desc')

     // Whenever Firestore data changes, update dragArray state
        useEffect(() => {
            setDragArrayActivities(personaActivities);
        }, [personaActivities]);
    
        // DRAG & DROP: data
      const handleDragEndActivities = (result) => {
        if (!result.destination) return;
    
        const items = Array.from(dragArrayActivities);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);
    
        // Update local state to reflect the reorder immediately
        setDragArrayActivities(items);
    
        // Now update Firestore with new positions
        items.forEach((item, idx) => {
          db.collection('PersonaActivities')
            .doc(item.docid) // assuming .docid is stored for each doc
            .update({ Position: idx + 1 });
        });
    
        // Show "saved" icon
        setSaved('flex');
      };

    // Main activity handler
    const mainActivityHandler = (e) => {
        const activityId = e.target.value
        setSelectedActivity(activityId)
    }

    // Add main activity
    const addMainActivity = () => {

        db.collection('PersonaActivities')
        .add({
            ID: uuid(),
            CompagnyID: client,
            PersonaID: personaId,
            ActivityID: selectedActivity,
            Type: 'main',
            Position: personaActivities.length + 1,
            Timestamp: timestamp
        })
        .then(() => {
            setSaved('flex')
            setShowAddContainer(!showAddContainer)
        })  
    }

    // Delete activity
    const deleteActivity = (e) => {
        const docid = e.target.dataset.docid
        db.collection('PersonaActivities').doc(docid).delete()
    }

    // Output handler
    const outputHandler = (e) => {
        const docid = e.target.dataset.docid
        const outputId = e.target.options[e.target.selectedIndex].value
        db.collection('PersonaActivities').doc(docid).update({
            OutputId: outputId
        })
    }

    // Delete event
    const deleteEvent = (e) => {
        const docid = e.target.dataset.docid
        db.collection('Events').doc(docid).delete()
    }

  return (
    <div style={{display: personaNavigation === 'activities' ? 'block' : 'none'}}>
        {/* <h2>Activiteiten</h2> */}

        {/* Main activity */}
        <div  className="persona-option-container">     
            <h3>Hoofdactiviteiten</h3>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}></th>
                        <th style={{backgroundColor: secundairyColor}}>HOOFDACTIVITEIT</th>
                        <th style={{backgroundColor: secundairyColor}}>OUTPUT</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                    </tr>
                </thead>
                 <DragDropContext onDragEnd={handleDragEndActivities}>
                    <Droppable droppableId="activities" direction="vertical">
                        {(provided, snapshot) => (
                        <tbody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        > 
                        {personaActivities && personaActivities.map((activity, index) => (
                        <Draggable
                        key={activity.ID}
                        draggableId={activity.ID}
                        index={index}
                    >
                        {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                                <td>
                                     <DragIndicatorOutlinedIcon />
                                 </td>
                                <td>
                                    <ActivityMeta id={activity.ActivityID} />
                                </td>
                                <td>
                                    {console.log(activity.OutputId)}
                                    <select name="" id="" value={activity.OutputId} data-docid={activity.docid} onChange={outputHandler}>
                                        <option value="">-- Selecteer output --</option>
                                        <SelectOutput activityId={activity.ActivityID}/>
                                    </select>
                                </td>
                                <td>
                                        <Tooltip content={'Activiteit verwijderen'} top='-60px' width={'30px'}>
                                            <img className='table-delete-icon' data-docid={activity.docid} onClick={deleteActivity} src={deleteIcon} alt="" />
                                        </Tooltip>
                                </td>
                            </tr>
                            )}
                            </Draggable>
                            ))}
                            {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            </table>
            <AddItemTableRow toolTipContent={'Activiteit toevoegen'} onClick={() => setShowAddContainer(!showAddContainer)}/>
            <div className='add-options-container' style={{display: showAddContainer ? 'flex' : 'none'}} onMouseLeave={() => setShowAddContainer(!showAddContainer)}>
                <select name="" id="" onChange={mainActivityHandler}>
                    <option value="">-- Selecteer hoofdactiviteit --</option>
                    {activities && activities.map(activity => (
                        <option key={activity.ID} value={activity.ID}>{activity.Activity}</option>
                    ))}
                </select>
                <button className='button-simple' onClick={addMainActivity}>Opslaan</button>
            </div>
        </div>

        {/* Events */}
        <div  className="persona-option-container">     
            <h3>Gebeurtenissen</h3>
            <table>
                <thead>
                    <tr>
                        <th style={{backgroundColor: secundairyColor}}>GEBEURTENIS</th>
                        <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                        <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                    </tr>
                </thead>
                <tbody>
                    {events && events.map(event => (
                        <tr key={event.ID}>
                            <td>{event.Title}</td>
                            <td>{event.Date.toDate().toLocaleDateString("nl-NL", options)}</td>
                            <td>
                                <Tooltip content={'Gebeurtenis verwijderen'} top='-60px' width={'30px'}>
                                    <img className='table-delete-icon' data-docid={event.docid} onClick={deleteEvent} src={deleteIcon} alt="" />
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
  )
}

export default Activities