import robotImage from "../../images/icons/robot-icon.png"
import { useContext } from "react";
import { ImpactAI } from "../../StateManagment/ImpactAI";
import { Auth } from '../../StateManagment/Auth';
import { usePageConfig } from "../../hooks/usePageConfig";
import Location from "../../hooks/Location";
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';

const PageHeader = ({heading, subHeading, description, AI}) => {
    // Context
    const [auth] = useContext(Auth)
    const {
        type: [type, setType],
        chatOpen: [chatOpen, setChatOpen],
        newMessage: [message, setMessage],
        sendMessageHandlerRef
    } = useContext(ImpactAI);

    // Hooks
    const pageName = Location()[2];
    const { getPageConfig } = usePageConfig();

    // Get type of page for ImpactAI
    const pageConfig = getPageConfig(pageName);
    const pageType = pageConfig && pageConfig.type 

    // Save new message
    const saveNewMessage = () => {
        setChatOpen(true)
        setMessage('Ja, zeker!')
        // setType(pageType)
        // if(message !== ""){
        //     console.log(message)
        //     sendMessageHandlerRef.current && sendMessageHandlerRef.current(); // Call the function
        // }
    };

  return (
    <div className="page-header-new">
        <div id='page-header-title-container'>
            {/* <Icon/> */}
            <h1>{heading}</h1>
            <p>{subHeading}</p>
        </div>
        <p id='page-header-description-text'>
            {description} 
        </p>
        {AI &&
            <div id='page-header-impact-ai-container' onClick={saveNewMessage}>
                <img src={robotImage} alt=""/>
                <p>Laat ImpactAI je helpen</p>
            </div>
         }
         {/* <div>
            <OndemandVideoOutlinedIcon/>
            <LocalLibraryOutlinedIcon/>
         </div> */}
       
       
    </div>
  )
}

export default PageHeader