import useSettings from "../../hooks/Settings";
import { useState, useContext, useRef, useEffect } from "react";
import DraggableItems from "../../components/common/DraggableItems";
import { db } from "../../firebase/config";
import { SavedIcon } from "../../StateManagment/SavedIcon";
import deleteIcon from '../../images/icons/delete-icon.png'
import OrderQuestionnaireSectionFields from "./OrderQuestionnaireSectionFields";
import copyIcon from '../../images/icons/copy-icon.png'
import editIcon from '../../images/icons/edit-icon.png'
import uuid from 'react-uuid'
import Tooltip from "../common/Tooltip";
import { timestamp } from "../../firebase/config";
import { client } from "../../hooks/Client";
import scaleIcon from '../../images/icons/scale-icon.png'
import multipleOneIcon from '../../images/icons/multiple-one-icon.png'
import multipleMultipleIcon from '../../images/icons/multiple-multiple-icon.png'
import matrixIcon from '../../images/icons/matrix-icon.png'
import textIcon from '../../images/icons/text-icon.png'
import QuestionnaireField from "./QuestionnaireField";
import MeetstandardIcon from '../../images/meetstandaard-icon.png'
import MeetstandardEffectMeta from '../../components/meetstandaard/MeetstandaardEffectMeta';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

const OrderQuestionnaireFieldsView = ({questionnaireFields}) => {
    // Context
    const [saved, setSaved] = useContext(SavedIcon)

    // States
    const [hoverIndex, setHoverIndex] = useState(null)
    const [placeholderIndex, setPlaceholderIndex] = useState(null);
    const [sectionID, setSectionID] = useState(null)
    const [editQuestion, setEditQuestion] = useState(null)
    const [dragArray, setDragArray] = useState(questionnaireFields || []);

    // Refs
    const sectionIdRef = useRef('field');
    const sectionRef = useRef('field')

    // Hooks
    const secundairyColor = useSettings().SecundairyColor

    // Update state when props change
    useEffect(() => {
        setDragArray(questionnaireFields);
    }, [questionnaireFields]);

    // Handle drag-and-drop logic
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(dragArray);
        const [movedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, movedItem);

        setDragArray(items);

        // Update Firestore with new positions
        items.forEach((item, idx) => {
            db.collection('QuestionnaireFields')
                .doc(item.docid)
                .update({ Position: idx + 1 });
        });

        setSaved(true);
    };


    // Handlers
    const titleHandler = (e) => {
        const docid = e.target.dataset.docid
        const value = e.target.value

        db.collection('QuestionnaireFields').doc(docid).update({
            Question: value
        })
        .then(() => {
            setSaved(true)
        })
        .catch(err => {
            console.log(err)
        })
    }

    const editHandler = (e) => {

        const questionaireId = e.target.dataset.id

        if(editQuestion !== questionaireId) {
            setEditQuestion(questionaireId)
        } else {
            setEditQuestion(null)
        }

    }

    const deleteHandler = (e) => {

        const docid = e.target.dataset.docid

        db.collection('QuestionnaireFields')
        .doc(docid)
        .update({
            QuestionnaireID: '',
        })

    }

    const duplicateHandler = (e) => {

        const questionaireId = e.target.dataset.questionaireid
        const type = e.target.dataset.type
        const question = e.target.dataset.question
        const explainer = e.target.dataset.explainer
        const reachStart = e.target.dataset.reachstart
        const reachStartLable = e.target.dataset.reachstartlable
        const reachEnd = e.target.dataset.reachend
        const reachEndLabel = e.target.dataset.reachendlabel
        const multiple = e.target.dataset.multiple
        const sectionID = e.target.dataset.sectionid
        const id = e.target.dataset.id
        const MSIID = e.target.dataset.msiid
        const standard = e.target.dataset.standard

        const fieldID  = uuid()

        db.collection('QuestionnaireFields')
        .doc()
        .set({
            Compagny: client,
            CompagnyID: client,
            ID: fieldID,
            Timestamp: timestamp,
            QuestionnaireID: questionaireId,
            Type: type,
            Question: ('Kopie van ' + question),
            Explainer: explainer,
            ReachStart: Number(reachStart),
            ReachStartLable: reachStartLable,
            ReachEnd: Number(reachEnd),
            ReachEndLabel: reachEndLabel,
            Position: questionnaireFields.length + 1,
            Multiple: multiple,
            SectionID: sectionID,
            LiveReportHidden: false,
            MSIId: MSIID,
            Standard: standard
        })
        .then(() => {

            duplicateOptions(id, fieldID, question)
            duplicateMatrixRows(id, fieldID, question)
            duplicateMatrixColumns(id, fieldID, question)

        })
    }

    const duplicateOptions = (id, fieldID, question) => {

        db.collection('MultipleQuestionOptions')
        .where('CompagnyID', '==', client)
        .where('Field', '==', id)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MultipleQuestionOptions')
                .doc()
                .set({
                    Option: doc.data().Option,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('option duplicated')
                })
            })
        })
    }

    const duplicateMatrixRows = (fieldID, question) => {

        db.collection('MatrixQuestionRows')
        .where('Compagny', '==', client)
        .where('Field', '==', fieldID)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MatrixQuestionRows')
                .doc()
                .set({
                    Title: doc.data().Title,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('matrix rows duplicated')
                })
            })
        })
    }

    const duplicateMatrixColumns = (fieldID, question) => {

        db.collection('MatrixQuestionColumns')
        .where('Compagny', '==', client)
        .where('Field', '==', fieldID)
        .get()
        .then((snapshot) => {
            snapshot.docs.forEach(doc => {
                db.collection('MatrixQuestionColumns')
                .doc()
                .set({
                    Title: doc.data().Title,
                    Question: question,
                    Field: fieldID,
                    Compagny: client,
                    CompagnyID: client,
                    Timestamp: timestamp,
                    Position: doc.data().Position,
                    ID: uuid(),
                })
                .then(() => {
                    console.log('matrix columns duplicated')
                })
            })
        })
    }


    // Section border
    const borderType = (type) => {

        if (type === 'section') {
            return {borderLeft: '5px solid rgb(255, 203, 15)', paddingLeft: '5px', paddingRight: '5px', marginBottom: '20px', marginTop: '20px'}
        }
    }

    // Type icon
    const type = (type) => {
        if (type === 'scale') {
            return scaleIcon
        } else if (type === 'paragraph') {
            return textIcon
        } else if (type === 'multiple-one') {
            return multipleOneIcon
        } else if (type === 'multiple-multiple') {
            return multipleMultipleIcon
        } else if (type === 'matrix') {
            return matrixIcon
        } else {
            return editIcon
        }
    }

    
    return (
        <div id='order-questionniare-field-container'>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="questionnairefields" direction="vertical">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {questionnaireFields && questionnaireFields.map((field, index) => (
                                <Draggable key={field.ID} draggableId={field.ID} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div style={borderType(field.Type)} className="order-questionnaire-field-item-container">
                                                {placeholderIndex === index && (
                                                    <div className="placeholder-div" style={{ backgroundColor: secundairyColor }}></div>
                                                )}
                                                {field.Type === 'section' ? (
                                                    <div>
                                                        <DraggableItems
                                                            item={field}
                                                            input={
                                                                <input 
                                                                    id='order-questionnaire-section-title-input' 
                                                                    type="text" 
                                                                    data-docid={field.docid} 
                                                                    data-drag={'no-drag'} 
                                                                    defaultValue={field.Question} 
                                                                    onChange={titleHandler}
                                                                />
                                                            }
                                                            parentIndex={index}
                                                            collection={'QuestionnaireFields'}
                                                            itemArray={questionnaireFields}
                                                            setHoverIndex={setHoverIndex}
                                                            hoverIndex={hoverIndex}
                                                            title={field.Question}
                                                            type={field.Type}
                                                            id={field.ID}
                                                            setSectionID={setSectionID}
                                                            sectionID={sectionID}
                                                            sectionIdRef={sectionIdRef}
                                                            sectionRef={sectionRef}
                                                            setPlaceholderIndex={setPlaceholderIndex}
                                                            sectionDocid={field.docid}
                                                        />
                                                        <OrderQuestionnaireSectionFields section={field.ID} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {field.SectionID === '' && (
                                                            <div id='order-fields-item-container' style={{ backgroundColor: editQuestion === field.ID ? 'white' : 'transparent' }}>
                                                                <div id='section-order-field-container'>
                                                                    <DraggableItems 
                                                                        item={field}
                                                                        input={
                                                                            field.Standard === 'true' ? (
                                                                                <p id='add-inicator-meetstandaard-effect-title-container'>
                                                                                    Gestandaardiseerd effect: {<MeetstandardEffectMeta effectId={field.MSIId} />}
                                                                                </p>
                                                                            ) : (
                                                                                <input type="text" data-docid={field.docid} defaultValue={field.Question} onChange={titleHandler} />
                                                                            )
                                                                        }
                                                                        parentIndex={index}
                                                                        collection={'QuestionnaireFields'}
                                                                        itemArray={questionnaireFields}
                                                                        setHoverIndex={setHoverIndex}
                                                                        hoverIndex={hoverIndex}
                                                                        title={field.Question}
                                                                        setSectionID={setSectionID}
                                                                        sectionID={sectionID}
                                                                        sectionIdRef={sectionIdRef}
                                                                        sectionRef={sectionRef}
                                                                        setPlaceholderIndex={setPlaceholderIndex}
                                                                    />
                                                                    {field.Standard === 'true' ? (
                                                                        <>
                                                                            <Tooltip content="Dit is een gestandaardiseerde vraag en kan niet worden aangepast." width={'30px'} data-index={index}>
                                                                                <img id='remove-field-from-section-icon' src={MeetstandardIcon} alt="" data-index={index} />
                                                                            </Tooltip>
                                                                            <Tooltip content="Verwijderen" width={'30px'} data-index={index}>
                                                                                <img 
                                                                                    id='remove-field-from-section-icon'
                                                                                    src={deleteIcon} 
                                                                                    alt="" 
                                                                                    data-id={field.ID}
                                                                                    data-docid={field.docid}
                                                                                    data-index={index} 
                                                                                    data-questionaireid={field.QuestionnaireID}
                                                                                    onClick={deleteHandler}
                                                                                />
                                                                            </Tooltip>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <Tooltip content="Vraagtype aanpassen" width={'30px'} data-index={index}>
                                                                                <img 
                                                                                    id='remove-field-from-section-icon'
                                                                                    src={type(field.Type)} 
                                                                                    alt="" 
                                                                                    data-id={field.ID}
                                                                                    data-index={index} 
                                                                                    onClick={editHandler}
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip content="Kopieren" width={'30px'} data-index={index}>
                                                                                <img 
                                                                                    id='remove-field-from-section-icon'
                                                                                    src={copyIcon} 
                                                                                    alt="" 
                                                                                    data-id={field.ID}
                                                                                    data-index={index} 
                                                                                    data-questionaireid={field.QuestionnaireID}
                                                                                    data-type={field.Type}
                                                                                    data-question={field.Question}
                                                                                    data-explainer={field.Explainer}
                                                                                    data-reachstart={field.ReachStart}
                                                                                    data-reachstartlable={field.ReachStartLable}
                                                                                    data-reachend={field.ReachEnd}
                                                                                    data-reachendlabel={field.ReachEndLabel}
                                                                                    data-position={field.Position}
                                                                                    data-multiple={field.Multiple}
                                                                                    data-sectionid={field.SectionID}
                                                                                    data-color={field.Color}
                                                                                    data-title={field.Title}
                                                                                    data-msiid={field.MSIId}
                                                                                    data-standard={field.Standard}
                                                                                    onClick={duplicateHandler}
                                                                                />
                                                                            </Tooltip>
                                                                            <Tooltip content="Verwijderen" width={'30px'} data-index={index}>
                                                                                <img 
                                                                                    id='remove-field-from-section-icon'
                                                                                    src={deleteIcon} 
                                                                                    alt="" 
                                                                                    data-id={field.ID}
                                                                                    data-docid={field.docid}
                                                                                    data-index={index} 
                                                                                    data-questionaireid={field.QuestionnaireID}
                                                                                    onClick={deleteHandler}
                                                                                />
                                                                            </Tooltip>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {editQuestion === field.ID && (
                                                                    <QuestionnaireField field={field} index={index} position={questionnaireFields.length} />
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
    
}

export default OrderQuestionnaireFieldsView