import React from 'react'
import Tooltip from '../../components/common/Tooltip'
import plusButton from '../../images/icons/plus-icon.png'
import deleteIcon from '../../images/icons/delete-icon.png'
import { useFirestoreGeneralOrderBy } from '../../firebase/useFirestore'
import { db, timestamp } from '../../firebase/config'
import { v4 as uuid } from 'uuid'
import { client } from '../../hooks/Client'
import { SavedIcon } from "../../StateManagment/SavedIcon";
import Location from "../../hooks/Location"
import { useContext, useState, useEffect } from "react";
import useSettings from "../../hooks/Settings";
import settingsIcon from "../../images/icons/settings-icon.png";
import { useHistory } from "react-router-dom";
import { Auth } from '../../StateManagment/Auth'
import UserImageMeta from '../../components/user/UserImageMeta'
import UserNameMeta from '../../components/user/UserNameMeta'
import AddItemTableRow from "../../components/common/AddItemTableRow.jsx";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';

const Reports = ({personaNavigation}) => {
   // Context
   const [saved, setSaved] = useContext(SavedIcon)
    const [auth] = useContext(Auth)

    // State
    const [dragArray, setDragArray] = useState([]);

   // Hooks
   const secundairyColor = useSettings().SecundairyColor
   const personaId = Location()[3]
   const history = useHistory()
   const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

   // Firestore
   const reports = useFirestoreGeneralOrderBy('PersonaReports', 'PersonaID', personaId ? personaId : '', 'Position', 'desc')

   // Whenever Firestore data changes, update dragArray state
    useEffect(() => {
        setDragArray(reports);
    }, [reports]);

    // DRAG & DROP: reorder items and update Firestore
    const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(dragArray);
    const [movedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, movedItem);

    // Update local state to reflect the reorder immediately
    setDragArray(items);

    // Now update Firestore with new positions
    items.forEach((dragArray, idx) => {
        db.collection('PersonaReports')
        .doc(dragArray.docid) // assuming .docid is stored for each doc
        .update({ Position: idx + 1 });
    });

    // Show "saved" icon
        setSaved('flex');
    };

    // Add new report
   const addReport = async () => {

    const reportId = uuid()

       await db.collection('PersonaReports')
       .add({
           ID: reportId,
           PersonaID: personaId,
           Title: '',
           Description: '',
           Tags: [],
           CompagnyID: client,
           Compagny: client,
           Position: reports.length + 1,
           Timestamp: timestamp,
           User: auth.ID,
           Body: '',
           Position: reports.length + 1
       })   

   }

    // Delete report
   const deleteReport = (e) => {

       const docid = e.target.dataset.docid

       db.collection('PersonaReports')
       .doc(docid)
       .delete()
       .then(() => {
           setSaved('flex')
       })

   }

 return (
   <div style={{display: personaNavigation === 'report' ? 'block' : 'none'}}>
       {/* <h2>Verslaglegging</h2> */}
       <div  className="persona-option-container">
           <div className='table-container'>
                <table>
                    <thead>
                        <tr>
                            <th style={{backgroundColor: secundairyColor}}></th>
                            <th style={{backgroundColor: secundairyColor}}>TITEL</th>
                            <th style={{backgroundColor: secundairyColor}}>DATUM</th>
                            <th style={{backgroundColor: secundairyColor}}>AUTEUR</th>
                            <th style={{backgroundColor: secundairyColor}}>INHOUD</th>
                            <th style={{backgroundColor: secundairyColor}}>AANPASSEN</th>
                            <th style={{backgroundColor: secundairyColor}}>VERWIJDEREN</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="reports" direction="vertical">
                            {(provided, snapshot) => (
                            <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            > 
                            {reports && reports.map((report, index) => (
                           <Draggable
                           key={report.ID}
                           draggableId={report.ID}
                           index={index}
                       >
                           {(provided, snapshot) => (
                           <tr
                               ref={provided.innerRef}
                               {...provided.draggableProps}
                               {...provided.dragHandleProps}
                           >
                                <td>
                                    <DragIndicatorOutlinedIcon />
                                </td>
                                <td>
                                        <p>{report.Title}</p>
                                    </td>
                                    <td>
                                        {report.Timestamp.toDate().toLocaleDateString("nl-NL", options)}
                                    </td>
                                    <td>
                                        <div id='report-user-meta-container'>
                                            <UserImageMeta userID={report.User} />
                                            <UserNameMeta userID={report.User} />
                                        </div>
                                    </td>
                                    <td>
                                        <p dangerouslySetInnerHTML={{
                                            __html: report.Body && report.Body.length > 50 
                                                ? report.Body.substring(0, 50) + ' [...]' 
                                                : report.Body
                                            }}>
                                        </p>
                                    </td>
                                    <td>
                                            <Tooltip content={'Rapportage aanpassen'} top='-60px' width={'30px'}>
                                                <EditOutlinedIcon src={settingsIcon} className="table-delete-icon" onClick={() => history.push(`/${client}/personareportdetail/${personaId}/${report.ID}`)}/>
                                            </Tooltip>
                                    </td>
                                    <td>
                                            <Tooltip content={'Rapportage verwijderen'} top='-60px' width={'30px'}>
                                                <img className='table-delete-icon' data-docid={report.docid} onClick={deleteReport} src={deleteIcon} alt="" />
                                            </Tooltip>
                                    </td>
                                    </tr>
                                    )}
                                </Draggable>
                                ))}
                                {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                    </DragDropContext>
                </table>
                <AddItemTableRow toolTipContent={'Rapportage toevoegen'} onClick={addReport}/>
           </div>
       </div>
   </div>
 )
}

export default Reports