import { useFirestoreNoCompagnyGeneralOrderBy } from '../../firebase/useFirestore'

const ProjectOutputProgress = ({projectId}) => {

    // Hooks
    const options = { day: 'numeric' ,month: 'numeric', year: 'numeric'};

    // Firestore
    const results = useFirestoreNoCompagnyGeneralOrderBy('Results', 'CompagnyID', projectId ? projectId : '', 'Timestamp', 'desc')

    // Last result
    const lastResult = results[results.length - 1]
    const lastResultDate = lastResult?.Timestamp.toDate().toLocaleDateString("nl-NL", options)
    const daysAgo = Math.floor((new Date() - lastResult?.Timestamp.toDate()) / (1000 * 60 * 60 * 24))

  return (
    <div>
        <p style={{color: results.length === 0 ? 'red' : 'green'}}>Aantal: {results.length}</p>
        {results.length > 0 ? <p style={{color: daysAgo > 60 ? 'red' : 'green'}}>Laatste: {lastResultDate}</p> : '-'}
    </div>
  )
}

export default ProjectOutputProgress