import { useFirestoreGeneralOrderBy } from '../../../firebase/useFirestore'
import ReportLayoutItems from './ReportLayoutItems'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const ReportLayoutElements = ({layoutId, setSidebarMenuItem, setItemDocid, itemDocid, setElementDocid, setItemId, elementId, setSelectedElementId, reportId}) => {

    // Firestore
    const elements = useFirestoreGeneralOrderBy('ReportLayoutElements', 'LayoutID', layoutId, 'Position', 'asc');

  return (
    <div id='layout-elements-container'>
        {elements && elements.map((element) => (
            <div 
            key={element.ID} 
            className='layout-elements-element-container'
            style={{backgroundColor: element.Color}}
            >
                <ReportLayoutItems 
                elementId={element.ID} 
                setSidebarMenuItem={setSidebarMenuItem} 
                setItemDocid={setItemDocid} 
                itemDocid={itemDocid} 
                setItemId={setItemId} 
                setSelectedElementId={setSelectedElementId}
                />
                <div id='report-layout-options-container'>
                    <div onClick={() => {setElementDocid(element.docid); 
                    setSidebarMenuItem('items'); 
                    setSelectedElementId(element.ID)}}
                    className="edit-layout-button">
                    <EditOutlinedIcon />
                    </div>
                </div>
            </div>
            
        ))}
    </div>
  )
}

export default ReportLayoutElements